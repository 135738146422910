import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { User } from '../../services/userService';
import Footer from '../Footer';
import styles from './LoginForm.scss';
import TDLogo from '../common/TDLogo';
import { useForm } from '../../utils/useForm';
import FilteringInput from '../input/FilteringInput';

export default function ResetPassword({ done }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [model, setModel] = useState({
        username: localStorage.getItem('username') || location.state?.username
    });

    const [error, setError] = useState('');
    const {fieldErrors, setFieldError} = useForm(validate);
    const [hasSubmit, setHasSubmit] = useState(false);
    const [working, setWorking] = useState(false);

    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError(true);
        } else {
            if (model.newpassword1 != model.newpassword2) {
                setError('td.fx.security.error.passwords.do.not.match');
            } else if (model.password == model.newpassword1) {
                setError('td.fx.security.error.reused.password');
            } else {
                setError(false);
            }
        }
    }

    function doSubmit(e) {
        setHasSubmit(true);
        // don't do default stuff
        e.preventDefault();
        e.stopPropagation();

        if (error) {
            return false;
        }
        setWorking(true);

        User.login(model, (err) => {
            switch (err) {
                case 'reset.success':
                case 'nodata':
                case 'invalid':
                case 'email':
                case 'disabled':
                case 'connection':
                case 'locked' :
                case 'justlocked' :
                    setError('td.fx.login.error.'+err);
                    break;
                case 'reset': 
                case 'notemail':
                case 'notuserid':
                case 'notfirstname':
                case 'notlastname':
                case 'notname':
                case 'reused':
                    navigate('/login/resetPassword', { state: { username: username }, replace: true });
                    break;
                case 'questions':
                    navigate('/login/questions');
                    break;
                default:
                    navigate('/login/error', { state: { errorCode: err }, replace: true });
                    break;
            }
            setWorking(false);
            done();
        });
    }

    function handleChange(name, val) {
        model[name] = val;
        setModel({...model});
        validate();
    }
    
    return (
        <Row className={styles.fullHeight + ' g-0'}>
            <Col xs={12} sm={6} md={5} lg={4} className={`g-0 ${styles.loginWrapper}`}>
                <Container style={{ padding: '4em', boxSizing: 'border-box' }}>
                    <TDLogo/>
                    <p className="display-6" style={{ marginTop: '2em'}}>Welcome to TDFX</p>
                    <p>{t('td.fx.login.error.reset')}</p>
                    
                    <Form disabled={working} noValidate onSubmit={doSubmit}>
                        <fieldset disabled={working}>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label>{t('td.fx.login.username.label')}</Form.Label>
                                <FilteringInput required
                                    name="username"
                                    value={model.username}
                                    hideError={!hasSubmit}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.validation.'}
                                    update={val => handleChange('username', val)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>{t('td.fx.login.password.label')}</Form.Label>
                                <FilteringInput required
                                    name="password"
                                    value={model.password || ''}
                                    hideError={!hasSubmit}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.fx.login.error.password.'}
                                    maxLength={25}
                                    update={val => handleChange('password', val)}
                                    regex={/^.{6,25}$/}
                                    password
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="newpassword1">
                                <Form.Label>{t('td.fx.security.new.password')}</Form.Label>
                                <FilteringInput required
                                    name="newpassword1"
                                    value={model.newpassword1 || ''}
                                    hideError={!hasSubmit}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.fx.login.error.password.'}
                                    maxLength={25}
                                    update={val => handleChange('newpassword1',val)}
                                    regex={/^.{6,25}$/}
                                    password
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="newpassword2">
                                <Form.Label>{t('td.fx.security.confirm.password')}</Form.Label>
                                <FilteringInput required
                                    name="newpassword2"
                                    value={model.newpassword2 || ''}
                                    hideError={!hasSubmit}
                                    setFieldError={setFieldError}
                                    errorPrefix={'td.fx.login.error.password.'}
                                    maxLength={25}
                                    update={val => handleChange('newpassword2',val)}
                                    regex={/^.{6,25}$/}
                                    password
                                />
                            </Form.Group>

                            {/** we want to ignore if error = true, the field errors will show */}
                            <Form.Label className={'td-error'} hidden={!error || error == true || !hasSubmit}>{ t(`${error}`) }</Form.Label>
                            
                            <div className="d-grid gap-2">
                                <Button as="a" href="/" className="btn td-btn-secondary-clear btn">{t('td.fx.signing.cancel')}</Button>
                                <Button className="btn td-btn-primary-light" type="submit">{t('td.fx.signing.continue')}</Button>
                            </div>
                        </fieldset>
                    </Form>
                </Container>
                <Footer login={true}/>
            </Col>
            <Col xs={12} sm={6} md={7} lg={8} className={`g-0 ${styles.loginBackground}`}>
            </Col>
        </Row>
    )
}