// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EqyaUuyW9nhq9TjN4tNg{position:relative;top:34px}.zODC5MithCAnUTwgrGng{background-color:#f9f9f9;padding:1em;max-width:1450px;width:100%}@media(max-width: 767px){.zODC5MithCAnUTwgrGng{width:auto}}`, "",{"version":3,"sources":["webpack://./src/components/common/Blotter/DrawDown.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,QAAA,CAGJ,sBACI,wBAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CAIJ,yBACI,sBACI,UAAA,CAAA","sourcesContent":[".nolabel {\n    position: relative;\n    top: 34px;\n}\n\n.DrawDown {\n    background-color: #f9f9f9;\n    padding: 1em;\n    max-width: 1450px;\n    width: 100%;\n}\n\n// Mobile\n@media (max-width: 767px) {\n    .DrawDown {\n        width: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nolabel": `EqyaUuyW9nhq9TjN4tNg`,
	"DrawDown": `zODC5MithCAnUTwgrGng`
};
export default ___CSS_LOADER_EXPORT___;
