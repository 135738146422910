import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import styles from './support.scss';

export default function SpotForwardTrading() {
    const { t } = useTranslation();
        return (
            <div>
                <h4>{t('td.fx.support.spotForwardTrading.title.spotForwardTrading2')}</h4>
                <Trans i18nKey={'td.fx.support.spotForwardTrading.explanation.spotForwardTrading.01'}>
                    <p><Link to={'currencies'} className="link-underline">x</Link>x</p>
                </Trans>
                <p>{t('')}</p>
                <p>{t('td.fx.support.spotForwardTrading.explanation.spotForwardTrading.02')}</p>
                <Trans i18nKey={'td.fx.support.spotForwardTrading.explanation.spotForwardTrading.03'}>
                    <p><Link to={'/rfq'} className="link-underline">x</Link>x</p>
                </Trans>


                <h5>{t('td.fx.support.spotForwardTrading.title.step01')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step01.01')}</p>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step01.02')} </p>
                <Trans i18nKey="td.fx.support.spotForwardTrading.explanation.step01.03" 
                >
                    <p><a href="mailto:tdfx@tdsecurities.com">e</a>f</p>
                </Trans>

                <h5>{t('td.fx.support.spotForwardTrading.title.step02')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step02.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig1')} className={`${styles["support-img-max"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
                </figure>
                <h5>{t('td.fx.support.spotForwardTrading.title.step03')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step03.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig2')} className={`${styles["support-img-min"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
                </figure>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step03.02')}</p>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step03.03')}</p>
                <ul>
                <li>{t('td.fx.support.spotForwardTrading.explanation.step03.04')}</li>
                <li>{t('td.fx.support.spotForwardTrading.explanation.step03.05')}</li>
                <li>{t('td.fx.support.spotForwardTrading.explanation.step03.06')}</li>
                </ul>
                <Table>
                    <thead>
                        <tr>
                            <th>{t('td.fx.support.spotForwardTrading.valueDateCode.title')}</th>
                            <th>{t('td.fx.support.spotForwardTrading.valueDateCode.desc')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.todayCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.todayName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.tomorrowCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.tomorrowName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.spotCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.spotName01')}<br/>
                            {t('td.fx.support.spotForwardTrading.valueDateCode.spotName02')}
                            </td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.spot1Code')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.spot1Name')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.1weekCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.1weekName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.2weekCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.2weekName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.3weekCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.3weekName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.1monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.1monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.2monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.2monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.3monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.3monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.4monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.4monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.5monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.5monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.6monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.6monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.7monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.7monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.8monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.8monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.9monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.9monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.10monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.10monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.11monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.11monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.1yearCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.1yearName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.18monthCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.18monthName')}</td>
                        </tr>
                        <tr>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.2yearCode')}</td>
                            <td>{t('td.fx.support.spotForwardTrading.valueDateCode.2yearName')}</td>
                        </tr>
                    </tbody>
                </Table>
                
                <h5>{t('td.fx.support.spotForwardTrading.title.step04')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step04.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig3')} width="110%"/>
                    <figcaption>{t('td.fx.support.figureCaption03')}</figcaption>
                </figure>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step04.02')}</p>
                <h5>{t('td.fx.support.spotForwardTrading.title.step05')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step05.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig4')} className={`${styles["support-img-max"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption04')}</figcaption>
                </figure>
                <h5>{t('td.fx.support.spotForwardTrading.title.step06')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step06.01')}</p>
                <h5>{t('td.fx.support.spotForwardTrading.title.step07')}</h5>
                <Trans i18nKey={'td.fx.support.spotForwardTrading.explanation.step07.01'}>
                    <p><Link to={'inverse'} className="link-underline">x</Link>x</p>
                </Trans>
                
                <h5>{t('td.fx.support.spotForwardTrading.title.step08')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step08.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig5')} />
                    <figcaption>{t('td.fx.support.figureCaption05')}</figcaption>
                </figure>
                <h5>{t('td.fx.support.spotForwardTrading.title.step09')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step09.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig6')} className={`${styles["support-img-min"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption06')}</figcaption>
                </figure>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step09.02')}</p>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step09.03')}</p>
                <h5>{t('td.fx.support.spotForwardTrading.title.step10')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step10.01')}</p>
                <figure>
                    <img src={t('td.fx.support.SpotFig7')} className={`${styles["support-img-min"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption07')}</figcaption>
                </figure>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step10.02')}</p>
                <p>{t('td.fx.support.spotForwardTrading.explanation.step10.03')}</p>
            </div>
        );
}