import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../utils/useForm';
import FilteringInput from '../../input/FilteringInput';


export default function SearchForm({ filter, setFilter, setError, clients, hasSubmit }) {
    const { t } = useTranslation();
    const {fieldErrors, setFieldError} = useForm(validate);

    const productTypes = [
        { value: 'SP', name: t('td.fx.search.label.SP') }, 
        { value: 'OU', name: t('td.fx.search.label.OU') }, 
        { value: 'FO', name: t('td.fx.search.label.FO') },
        { value: 'SN', name: t('td.fx.search.label.SN') },
        { value: 'SF', name: t('td.fx.search.label.SF') },
        { value: 'UF', name: t('td.fx.search.label.UF') },
        { value: 'UN', name: t('td.fx.search.label.UN') }
    ]

    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError(true);
        } else {
            setError(false);
        }
    }

    function handleChange(name, val) {
        filter[name] = val;
        setFilter({...filter});
        validate();
    }

    if (clients) {
        return (
            <>
                <Row>
                    <Col xs={12} sm={12}>
                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>{t('td.fx.search.label.clientid')}</Form.Label>
                            <Form.Select 
                                name="clientId" 
                                value={filter.clientId || ''}
                                onChange={e => handleChange('clientId', e.target.value)}
                            >
                                <option value={''}></option>
                                { clients.map(c => 
                                    <option key={c.clientId} value={c.clientId}>{c.clientName}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <Form.Group className="mb-3" controlId="product">
                            <Form.Label>{t('td.fx.search.label.product')}</Form.Label>
                            <Form.Select 
                                name="product" 
                                value={filter.product || ''}
                                onChange={e => handleChange('product', e.target.value)}
                            >
                                <option value={''}></option>
                                { productTypes.map(p => 
                                    <option key={p.value} value={p.value}>{p.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <Form.Group className="mb-3" controlId="dealId">
                            <Form.Label>{t('td.fx.search.label.dealId')}</Form.Label>
                            <FilteringInput 
                                name="dealId"
                                value={filter.dealId || ''}
                                hideError={!hasSubmit}
                                setFieldError={setFieldError}
                                errorPrefix={'td.validation.'}
                                update={val => handleChange('dealId', val)}
                                regex={/^([a-zA-Z0-9]{6}[-]?\d{7}|\d{8}[-]\d{5})$/}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }
}