import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styles from './FunctionalButtons.scss';

import {Service as Confirmation} from '../../services/confirmationService';
import { Print } from '../../services/printService';

import utils from '../../utils';
const {CONFIRMATION_DELAY} = utils.constants;
import { useTranslation } from 'react-i18next';


// 
// settle/drawdown button
// print/pdf
// murex confirmation


function doPrint(data) {
    // FIXME - second parameter which should be a function to retrieve the drawdowns if this is a Window Forward
    // will probably crash without that if we call for a WF...
    Print.generateDealDetailsPDF(data);
}

function doConfirmation(data, drawdown) {
    let theData = data;
    if (drawdown) {
        data.product = 'DR';
        // we also need to add the parent
    }
    Confirmation.open(theData);
}

// note expander is it's own column
// no print for child row
// we don't want to show the confirmation until the deal is 5 minutes old
// re-render will be triggered by data grid - so we externalize the 5 minutes
function FunctionalButtons(props) {
    const {t} = useTranslation();
    const {node, data, drawdown} = props;
    const [expanded, setExpanded] = useState(node?.expanded);

    useEffect(() => {
        // can we just look at node.expanded here?
        const expandListener = (event) => setExpanded(event?.node?.expanded);
        node?.addEventListener('expandedChanged', expandListener);
  
        return () => {
            node?.removeEventListener('expandedChanged', expandListener);
        };
    }, []);

    const hideConfirm = ((Date.now() - data?.creationTime) < CONFIRMATION_DELAY);


    // recommended way of causing a re-render
    const [, forceUpdate] = useReducer(() => ({}));

    // if we need to, set a timer to update the confirmation link
    useEffect(() => {
        if (hideConfirm) {
            setTimeout(() => {
                // trigger an update
                forceUpdate();
            }, (CONFIRMATION_DELAY - (Date.now() - data?.creationTime)));
        }
    }, []);
    
    
    const onClick = useCallback(() => node?.setExpanded(!node?.expanded), [node]);


    // console.log('functional buttons props', props);

    // first column - expander - we are either normal, unsettled, dd or fully dd.
    let className = `${styles.actionIcon} td-icon icon-small td-icon-colour-primary ${expanded ? 'td-icon-collapse' : 'td-icon-expand'  }`

    // if we're not a master (expandable) row, then add a hidden span to fix alignment
    return (
        <div className={styles.actionContainer}>
            {node?.master ? 
                <span className={className} onClick={onClick} ></span>
                : <span className={className} style={{visibility: 'hidden'}}></span>
            }
            {drawdown ? null: 
                <span className={`${styles.actionIcon} td-icon icon-small td-icon-colour-primary td-icon-pdf`} title={t('td.fx.ticket.viewprint.tooltip')} onClick={() => doPrint(data)}></span>
            }
            <span 
                style={hideConfirm ? {visibility: 'hidden'} : null} title={t('td.fx.ticket.confirmation.tooltip')} className={`${styles.actionIcon} td-icon icon-small td-icon-colour-primary td-icon-manageBusiness`} 
                onClick={() => doConfirmation(data, drawdown)}
            ></span>
        </div>
    );
}
export default FunctionalButtons;


