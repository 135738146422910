import React from 'react';
import { NavLink  } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import TDLogo from '../common/TDLogo';
import { useTranslation } from 'react-i18next';

export default function Hamburger({ isOpen, list, toggle, permissions }) {
    const { t } = useTranslation();
    function getMenuTab(l) {
        return (
            <li key={l.key} className="td-nav-mobile-menu-item td-item-toggle">
              <Nav.Link as={NavLink} to={'/' + l.key} key={l.key} onClick={() => toggle(false)}>{t(l.name)}</Nav.Link>
            </li>
        );
    }

    function NavList() {
        return list.filter(m => m.showOnMobile == true).map(l => {
            if (!l.access) {
                return getMenuTab(l);
            } else {
                // Check permissions on this tab
                let match = false;
                const keys = Object.keys(permissions);
                for (let p of l.access) {
                    if (keys.includes(p)) {
                        match = l;
                        break;
                    }
                }
        
                if (match) {
                    return getMenuTab(match);
                }
            }
        });
    }

    if (!isOpen) {
        return null;
    }

    // FIXME - should these all be className?
    return (
        <div class="td-nav-mobile">
            <div class="td-nav-mobile-menu td-nav-mobile-menu-primary">
                <div class="td-nav-mobile-menu-top">
                    <div class="td-nav-mobile-menu-header">
                        <div class="td-logo"><TDLogo iconOnly={true} small={true}/></div>
                        <button type="button" class="td-mobile-menu-close" onClick={() => toggle(false)}>
                            <span className="visually-hidden">Close menu</span><span class="td-icon-button-delete td-icon-button-delete-sm"  aria-hidden="true"></span>
                        </button>
                    </div>
                    <nav>
                        <ul class="td-nav-mobile-menu-list">
                            <NavList/>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="td-nav-mobile-overlay" style={{ opacity: '1'}}></div>
        </div>
    )
};