import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function EvictionWarning({evicted, closeWarning}) {
    const { t } = useTranslation();

    return (
        <Modal show={(evicted)} centered className="td-modal" >
            <Modal.Header >
                <Modal.Title>
                    {t('tdfx.user.error.another.signon.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="td-modal-body-shown">
                <p> {t('tdfx.user.error.another.signon.message')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn td-btn-primary-light" onClick={closeWarning} >
                     {t('td.fx.timeout.warning.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { EvictionWarning };
