import i18next from 'i18next';

import { Observer } from '../utils';

function DrawdownService() {
    // somewhere for our drawdown screens to cache the current
    // working model
    this.setCurrent = function(deal) {
        this.current = deal;
        this.publish();
    };

    this.clear = function() {
        this.current = null;
        this.publish();
    };

    // FIXME - seems weird that we don't actually do any of the drawdowns here
    

    // FIXME - do we need this?
    // we want observers to be fired - but some only for new deals, not quotes
    // we will publish state changes, and observers can look at it?
    Observer.mixin(this);
}
export const Service = new  DrawdownService();
