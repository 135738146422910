// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kJQxoWLHLx9QI7S3tG2v{width:100%;height:auto}.ZwPDhDhNiPDoCkQ2gslO{width:140%;height:auto}.VHIwmMs8z7kFOoVIWj7d{width:70%;height:auto}`, "",{"version":3,"sources":["webpack://./src/components/Support/infolink/support.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CAGJ,sBACI,SAAA,CACA,WAAA","sourcesContent":[".support-img {\n    width: 100%;\n    height: auto;\n}\n\n.support-img-max {\n    width: 140%;\n    height: auto;\n}\n\n.support-img-min {\n    width: 70%;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support-img": `kJQxoWLHLx9QI7S3tG2v`,
	"support-img-max": `ZwPDhDhNiPDoCkQ2gslO`,
	"support-img-min": `VHIwmMs8z7kFOoVIWj7d`
};
export default ___CSS_LOADER_EXPORT___;
