import i18next from '../i18n';
import { format } from 'date-fns';
import { frCA, enCA } from 'date-fns/locale';

// FIXME - this seems confusing that we have it in a lot of files...
import { Format } from '../services/formatService';

/*
class NumberParser {
    constructor(locale) {
        const format = new Intl.NumberFormat(locale);
        const parts = format.formatToParts(12345.6);
        const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
        this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal").value}]`);
        this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
        this._index = d => index.get(d);
    }
    parse(string) {
      return (string = string.trim()
        .replace(this._group, "")
        .replace(this._decimal, ".")
        .replace(this._numeral, this._index)) ? +string : NaN;
    }
}
*/



// TODO: why is this date one day behind?
// FIXME - should we actually use this?
function formatDate(input) {
    if (!input) return '';
    return format(new Date(input), 'dd-MMM-yyyy', { locale: i18next.language === 'fr' ? frCA : enCA });
}


// return formatted date as string in format: 01-Jan-2023
function formatDateTime (date) {
    let ret = "";

    if (date) {

        let dateValue = date;

        if (typeof date === "string" || date instanceof String) {
            dateValue = new Date(date);
        }

        ret = new Intl.DateTimeFormat('default', { day:   '2-digit',
                                                   month: 'short',
                                                   year:  'numeric'}
                                     ).format(dateValue);
    }
    return ret;
}

function formateServerDate(date) {
    if (!date) return '';
    return format(date, 'yyyy-MM-dd');
}

// tricky MDN rounding that doesn't mess up floating point
function round(value, exp) {
    if (typeof exp === 'undefined' || +exp === 0)
      return Math.round(value);
  
    value = +value;
    exp = +exp;
  
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
      return 0;
  
    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
  
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
  }

const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
});

const rateFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,      
    maximumFractionDigits: 7,
});

function formatAmount(data) {
    return Format.formatAmount(data);
    // const rounded = round(data, 2);
    // return formatter.format(rounded);
}

function formatRate(data) {
    return rateFormatter.format(data);
}

export default {
    formatAmount,
    formatDate,
    formatDateTime,
    formateServerDate,
    formatRate
};