import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './support.scss';

export default function LoginNavigation() {
    const { t, i18n } = useTranslation();

    return (
        <Trans i18nKey="userMessagesUnread">
            <div>
                <h4>{t('Login and Navigation')}</h4>
                <p>{t('td.fx.support.loginNavigation.explanation.loginNavigation')}</p>

                <h5>{t('td.fx.support.loginNavigation.title.login')}</h5>
                <p>{t('td.fx.support.loginNavigation.explanation.login01')}</p>
                <figure>
                    <img src={t('td.fx.support.LoginFig1')}/>
                    <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
                </figure>

                <h5>{t('td.fx.support.loginNavigation.title.firstTimeUsers')}</h5>
                <p>{t('td.fx.support.loginNavigation.explanation.firstTimeUsers01')}</p>
                <p>{t('td.fx.support.loginNavigation.explanation.firstTimeUsers02')}</p>
                <p>{t('td.fx.support.loginNavigation.explanation.firstTimeUsers03')}</p>

                <h5>{t('td.fx.support.loginNavigation.title.navigationBar')}</h5>
                <p>{t('td.fx.support.loginNavigation.explanation.navigationBar01')}</p>
                <ol>
                    <li>{t('td.fx.support.loginNavigation.list.navigationBar01')}</li>
                    <li>{t('td.fx.support.loginNavigation.list.navigationBar02')}</li>
                    <li>{t('td.fx.support.loginNavigation.list.navigationBar03')}</li>
                    <li>{t('td.fx.support.loginNavigation.list.navigationBar07')}</li>
                </ol>
                <figure>
                    <img src={t('td.fx.support.LoginFig2')} className={`${styles["support-img-max"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
                </figure>
            <h5>{t('td.fx.support.loginNavigation.title.contacInformation')}</h5>
                <p>{t('td.fx.support.loginNavigation.explanation.contactInformation')}</p>
                <figure>
                    <img src={t('td.fx.support.LoginFig3')}className={`${styles["support-img-max"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption03')}</figcaption>
                </figure>
            </div>
        </Trans>
    );
}
