// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tQWxtnpkOePnTJfCmOlk{margin-top:8px !important}.IDVWLlohduvPVPyEtEvl{margin-right:10px}@media(max-width: 1199px){.cKXsBat4H83RRpCpJDVb{margin-bottom:25px}}.TC5RTOlVCF6327SGvh2m{padding-top:45px;padding-bottom:30px}.EGOE0yC8fE1KKtNCNuHa{padding-bottom:30px}.PDKyCkE2RIGFUV5wvOxq{float:right}.do3qZbbcsWiPMNUVLAUz{width:180px}.U7UCmNhzdaVYI8jYbhg_{padding-top:40px}.KKrJJtrUtwCtXB8vCXVI{padding-top:70px}.ce6gZ7cbqHNCMddkBHFa{color:green;font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/components/TradeSettlement/forms/History.scss"],"names":[],"mappings":"AAAA,sBACI,yBAAA,CAGJ,sBACI,iBAAA,CAKA,0BAFJ,sBAGQ,kBAAA,CAAA,CAKR,sBACI,gBAAA,CACA,mBAAA,CAGJ,sBACI,mBAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,WAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,WAAA,CACA,gBAAA","sourcesContent":[".radio-group2 {\n    margin-top: 8px !important;\n}\n\n.radio-group1 {\n    margin-right: 10px;\n}\n\n.form-group-name-and-label {\n    // Tablet\n    @media (max-width: 1199px) {\n        margin-bottom: 25px;\n    }\n\n}\n\n.history-leading-row {\n    padding-top: 45px;\n    padding-bottom: 30px;\n}\n\n.history-row {\n    padding-bottom: 30px;\n}\n\n.history-close-button {\n    float: right;\n}\n\n.ar-button {\n    width: 180px;\n}\n\n.wire-table-container {\n    padding-top: 40px;\n}\n\n.wire-table {\n    padding-top: 70px;\n}\n\n.bank-account-successful-submit {\n    color: green;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio-group2": `tQWxtnpkOePnTJfCmOlk`,
	"radio-group1": `IDVWLlohduvPVPyEtEvl`,
	"form-group-name-and-label": `cKXsBat4H83RRpCpJDVb`,
	"history-leading-row": `TC5RTOlVCF6327SGvh2m`,
	"history-row": `EGOE0yC8fE1KKtNCNuHa`,
	"history-close-button": `PDKyCkE2RIGFUV5wvOxq`,
	"ar-button": `do3qZbbcsWiPMNUVLAUz`,
	"wire-table-container": `U7UCmNhzdaVYI8jYbhg_`,
	"wire-table": `KKrJJtrUtwCtXB8vCXVI`,
	"bank-account-successful-submit": `ce6gZ7cbqHNCMddkBHFa`
};
export default ___CSS_LOADER_EXPORT___;
