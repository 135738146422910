import React from 'react';

import Image from 'react-bootstrap/Image';
import logoImg from '../../assets/TD.Logo.Big.jpg';
import logoText from '../../assets/TD.Securities.Text.big.jpg';

const iconHeight = {
    height: '40px'
};
const iconHeightSmall = {
    height: '32px'
};

const logoHeight = {
    height: '18px', paddingLeft: '1em' 
};

const logoHeightSmall = {
    height: '12px', paddingLeft: '1em' 
};

export default function TDLogo({ iconOnly, small }) {
    return (
        <>
            <Image src={logoImg} style={small? iconHeightSmall : iconHeight} alt="TD Canada Trust"/> 
            { !iconOnly && 
                <Image src={logoText} style={small? logoHeightSmall: logoHeight} alt="TD Securities"/>
            }
        </>
    )
}