import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './support.scss';

export default function WindowForwardTrading() {
    const { t, i18n } = useTranslation();
        return (
            <div>
                <h4>{t('td.fx.support.windowForwardTrading.title.windowForwardTrading')}</h4>
                <p>{t('td.fx.support.windowForwardTrading.explanation.windowForwardTrading01')}</p>
                <Trans i18nKey={'td.fx.support.windowForwardTrading.explanation.windowForwardTrading02'}>
                    <p><Link to={'/rfq'} className="link-underline">x</Link>x</p>
                </Trans>

                <h5>{t('td.fx.support.windowForwardTrading.title.step01')}</h5>
                <p>{t('td.fx.support.windowForwardTrading.explanation.step01.01')}</p>
                <p>{t('td.fx.support.windowForwardTrading.explanation.step01.02')}</p>
                <Trans i18nKey={'td.fx.support.windowForwardTrading.explanation.step01.03'} 
                >
                    <p><a href="mailto:tdfx@tdsecurities.com">e</a>f</p>
                </Trans>


                <h5>{t('td.fx.support.windowForwardTrading.title.step02')}</h5>
                <p></p>
                <figure>
                    <img src={t('td.fx.support.WindowForwardFig1')} className={`${styles["support-img"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
                </figure>
                <h5>{t('td.fx.support.windowForwardTrading.title.step03')}</h5>
                <p>{t('td.fx.support.windowForwardTrading.explanation.step03.01')}</p>
                <Trans i18nKey={'td.fx.support.windowForwardTrading.explanation.step03.02'}>
                    <p><Link to={'spot'} className="link-underline">x</Link>x</p>
                </Trans>


                <h5>{t('td.fx.support.windowForwardTrading.title.step04')}</h5>
                <Trans i18nKey={'td.fx.support.windowForwardTrading.explanation.step04.01'}>
                    <p><Link to={'inverse'} className="link-underline">x</Link>x</p>
                </Trans>

                <figure>
                <img src={t('td.fx.support.WindowForwardFig2')}/>
                    <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
                </figure>
                <h5>{t('td.fx.support.windowForwardTrading.title.step05')}</h5>
                <Trans i18nKey={'td.fx.support.windowForwardTrading.explanation.step05.01'}>
                    <p><Link to={'spot'} className="link-underline">x</Link>x</p>
                </Trans>

                <p>{t('td.fx.support.windowForwardTrading.explanation.step05.02')}</p>
                <p>{t('td.fx.support.windowForwardTrading.explanation.step05.03')}</p>
            </div>
        ); 
}