import i18next from 'i18next';

// FIXME - not sure service is the right name for this class...
function formatService() {
    const addSeparator = function (input, thousands) {
        let len = input.length - 3;
        for (var i = len; i > 0; i -= 3) {
            input = input.substr(0, i) + thousands + input.substr(i);
        }
        return input;
    };

    const doFormatAmount = function (input, thousands, decimal) {
        if (!input) {
            return "";
        }
        // pad the number if we need to
        const precision = 2;
        input = Number(input).toFixed(precision).toString();
        let mantissa = input.match(/\d+/);
        if (!mantissa) {
            mantissa = "";
        } else {
            mantissa = mantissa.toString();
        }

        const sFormattedNumber = addSeparator(mantissa, thousands);
        input = input.replace(".", decimal);
        return input.replace(mantissa, sFormattedNumber);
    };

    this.formatAmount = function (inputValue) {
        // we display 0 as ''
        // FIXME - this should probably be a parameter
        if (!inputValue) {
            return '';
        }

        if (typeof inputValue != "string") {
            inputValue = String(inputValue);
        }

        // we display 0 as ''
        // FIXME - this should probably be a parameter
        if (!inputValue.length || inputValue === '0') {
            return '';
        }

        const thousands = i18next.t("td.fx.currency.separator.thousands");
        const decimal = i18next.t("td.fx.currency.separator.decimal");

        return doFormatAmount(inputValue, thousands, decimal);
    };

    this.parseAmount = function (inputValue) {
        let val = '' + inputValue;
        const thousands = i18next.t('td.fx.currency.separator.thousands');
        const decimal = i18next.t('td.fx.currency.separator.decimal');
        // const sepRE = new RegExp('[' + thousands + ']', 'g');
        val =  val.replaceAll(thousands, '');
        // const decRE = new RegExp('[' + decimal + ']', 'g');
        val =  val.replaceAll(decimal, '.');

        // now we're in format 123456.00 
        // do we want to add the .00 if it's not there?
        return val;
    };

    // return formatted date as string in format: 01-Jan-2023
    this.displayDateFormat = function (date) {
        let ret = "";


        if (date) {

            let dateValue = date;

            if (typeof date === "string" || date instanceof String) {
                dateValue = new Date(date + 'T00:00:00');
            }

            const formattedDate = new Intl.DateTimeFormat('default', { day: '2-digit',
                                                             month: 'short',
                                                             year:  'numeric'}
                                                ).format(dateValue);

            const [month, day, year] = formattedDate.split(' ');

            ret = `${day.replace(/,$/,'')}-${month}-${year}`;
        }

        return ret;
    }
}

const Format = new formatService();
export { Format };