import React, { useState, Fragment, useEffect } from 'react';

import FilteringInput from './FilteringInput';

const MAX_LENGTH = 35;

// FIXME - not allowed to start with non alpha/number character
const pattern=/^[a-zA-Z0-9][ a-zA-Z0-9\+\/\-\?\:\(\)\.\,\'àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ]*$/;
export default function WireMemoInput({name, value, update, setFieldError, errorPrefix, className}) {
    return (
        <FilteringInput
            name={name} 
            value={value} 
            update={update} 
            setFieldError={setFieldError} 
            filterAccents={true}
            errorPrefix={errorPrefix} 
            regex={pattern}
            maxLength={MAX_LENGTH}
            className={className}
            placeholder={'td.fx.ticket.wire.prompt'}
        />
    );
}

