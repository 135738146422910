import React from 'react';
import { useTranslation } from 'react-i18next';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function TimeoutWarning({ sessionTimeoutWarning, closeTimeoutWarning }) {
    const { t } = useTranslation();

    return (
        <Modal show={sessionTimeoutWarning} centered className="td-modal" onClose={closeTimeoutWarning} >
            <Modal.Header >
                <Modal.Title>
                    {t('td.fx.timeout.warning.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="td-modal-body-shown">
                <p> {t('td.fx.timeout.warning')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn td-btn-primary-light" onClick={closeTimeoutWarning} >
                     {t('td.fx.timeout.warning.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { TimeoutWarning };
