import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, NavLink, useLocation } from 'react-router-dom';

import { Col, Nav, Row, Tab, Container } from 'react-bootstrap';

import { User } from '../../services/userService';
import CommonMessages from './infolink/common';
import TradableCurrencies from './infolink/tradeableCurrencies';
import InverseQuotes from './infolink/inverseQuotes';
import SpotFowardTrading from './infolink/spotForwardTrading';
import LoginNavigation from './infolink/loginNavigation';
import WindowForwardTrading from './infolink/windowForwardTrading';
import WindowForwardDrawdownTrading from './infolink/windowForwardDrawdownTrading';
import SwapTrading from './infolink/swapTrading';
import ForwardTrade from './infolink/forwardTrade';
import SpotTrade from './infolink/spotTrade';
import Reports from './infolink/reports';

export default function Support() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { branchData } = User?.selectedCompany;
  const { pathname } = useLocation();
  const [key, setKey] = useState();

  useEffect(() => {
    const paths = pathname.split('/');
    let keyName = pathname == '/support' ? 'login' : paths[paths.length-1];
    setKey(keyName);

    // Scroll to top of the page on change
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
  },[pathname]);

  const tabs = [
    { key: 'login', title: t('td.fx.welcome.lbl.login.and.screen.layout'), element: <LoginNavigation /> },
    { key: 'errors', title: t('td.fx.welcome.lbl.common.messages'), element: <CommonMessages /> },
    { key: 'currencies', title: t('td.fx.welcome.lbl.list.tradable.currencies'), element: <TradableCurrencies /> },
    { key: 'inverse', title: t('td.fx.welcome.lbl.inverse.quotes'), element: <InverseQuotes /> },
    { key: 'spot', title: t('td.fx.welcome.lbl.spot.trading'), element: <SpotFowardTrading /> },
    { key: 'windowforward', title: t('td.fx.welcome.lbl.fo.trading'), element: <WindowForwardTrading /> },
    { key: 'drawdown', title: t('td.fx.welcome.lbl.fo.drawdown.processing'), element: <WindowForwardDrawdownTrading /> },
    { key: 'swap', title: t('td.fx.welcome.lbl.swap.trading'), element: <SwapTrading /> },
    { key: 'forwardtrade', title: t('td.fx.welcome.lbl.forward.trade.settle'), element: <ForwardTrade /> },
    { key: 'spotwebeTreasury', title: t('td.fx.welcome.lbl.spot.web.etreasury'), element: <SpotTrade /> },
    { key: 'reports', title: t('td.fx.welcome.lbl.reports'), element: <Reports /> }
  ];

  function getNavItem(item) {
    return (
      <Nav.Item key={item.key}>
        <Nav.Link eventKey={item.key} as={NavLink} to={'/support/'+item.key}>{item.title}</Nav.Link>
      </Nav.Item>
    );
  }

  function getTabPane(item) {
    return (
      <Tab.Pane key={item.key} eventKey={item.key}>{item.element}</Tab.Pane>
    );
  }

  const getRoutes = () => tabs.map(l => {
    return <Route key={l.key} exact path={'/support/' + l.key} element={l.component}/>;
  });

  return (
    <div className="main-container">
      <Tab.Container id="support" activeKey={key} onSelect={(k) => setKey(k)}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {tabs.map(item => {
                return getNavItem(item);
              })}
            </Nav>
          </Col>
          <Col sm={6}>
            <Tab.Content>
              {tabs.map(item => {
                return getTabPane(item);
              })}
            </Tab.Content>
          </Col>
          <Col sm={3}>
            <h3>{t('td.fx.welcome.lbl.contactus')}</h3>
            <hr className="td-thin-divider-line-1"/>
            <div id='contact-us'>
              <Row>
                <Col sm={7}><strong>{t('td.fx.welcome.lbl.tdfx.client.services')}</strong></Col>
                <Col style={{ textAlign: 'right'}} sm={5}><a href={`tel:${t('td.fx.welcome.lbl.tdfx.client.services.phone')}`}>{t('td.fx.welcome.lbl.tdfx.client.services.phone')}</a></Col>
              </Row>
              {branchData && 
                <Row>
                  <Col sm={7}><strong>{t('td.fx.welcome.lbl.tdfx.branch.phonenumber')}</strong></Col>
                  <Col style={{ textAlign: 'right'}} sm={5}><a href={`tel:${branchData.phoneNumber}`}>{branchData.phoneNumber}</a></Col>
                </Row>
              }
              <Row>
                <Col sm={7}>{t('td.fx.welcome.lbl.web.business.banking')}</Col>
                <Col style={{ textAlign: 'right'}} sm={5}><a href={`tel:${t('td.fx.welcome.lbl.web.business.banking.phone')}`}>{t('td.fx.welcome.lbl.web.business.banking.phone')}</a></Col>
              </Row>
              <Row>
                <Col sm={7}>{t('td.fx.welcome.lbl.easyline')}</Col>
                <Col style={{ textAlign: 'right'}} sm={5}><a href={`tel:${t('td.fx.welcome.lbl.easyline.phone')}`}>{t('td.fx.welcome.lbl.easyline.phone')}</a></Col>
              </Row>
              <Row>
                <Col sm={7}>{t('td.fx.welcome.lbl.td.direct.investing')}</Col>
                <Col style={{ textAlign: 'right'}} sm={5}><a href={`tel:${t('td.fx.welcome.lbl.td.direct.investing.phone')}`}>{t('td.fx.welcome.lbl.td.direct.investing.phone')}</a></Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Tab.Container>
      <Routes>
        { getRoutes() }
      </Routes>
    </div>
  );
}
