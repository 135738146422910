// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ymQCUCNnz1Fsi6fnaAdc{padding:2em 4em !important;box-sizing:border-box}@media(max-width: 767px){.ymQCUCNnz1Fsi6fnaAdc{padding:2em 2.5em !important;box-sizing:border-box}ul.QUwl_BcgUpCVf5Q4VWgk li{margin:.5em 0}}ul.QUwl_BcgUpCVf5Q4VWgk{padding-left:0 !important;list-style-type:none;line-height:1.25rem;font-size:.8125rem;color:#616161}ul.QUwl_BcgUpCVf5Q4VWgk a{color:#616161;margin-bottom:16px;white-space:nowrap}ul.L4ts2Z1burhv0zJTOQ8O{text-align:center}ul.L4ts2Z1burhv0zJTOQ8O li{display:inline-block;margin-right:5em}ul.L4ts2Z1burhv0zJTOQ8O li:last-of-type{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AACA,sBACI,0BAAA,CACA,qBAAA,CAIJ,yBACI,sBACI,4BAAA,CACA,qBAAA,CAIA,2BACI,aAAA,CAAA,CAKZ,wBACI,yBAAA,CACA,oBAAA,CACA,mBAAA,CACA,kBAAA,CACA,aAAA,CAEA,0BACI,aAAA,CACA,kBAAA,CACA,kBAAA,CAIR,wBACI,iBAAA,CACA,2BACI,oBAAA,CACA,gBAAA,CAGJ,wCACI,cAAA","sourcesContent":["\n.footerContainer {\n    padding: 2em 4em !important;\n    box-sizing: border-box;\n}\n\n// Mobile\n@media (max-width: 767px) { \n    .footerContainer {\n        padding: 2em 2.5em !important;\n        box-sizing: border-box;\n    }\n    \n    ul.footerList {\n        li {\n            margin: 0.5em 0;\n        }\n    }\n}\n\nul.footerList {\n    padding-left: 0 !important;\n    list-style-type: none; /* Remove bullets */\n    line-height: 1.25rem;\n    font-size: 0.8125rem;\n    color: #616161;\n\n    a {\n        color: #616161;\n        margin-bottom: 16px;\n        white-space: nowrap;\n    }\n}\n\nul.horizontal {\n    text-align: center;\n    li{\n        display: inline-block;\n        margin-right: 5em; /* add spacing between items */\n    }\n\n    li:last-of-type {\n        margin-right: 0;\n    }\n}\n       "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": `ymQCUCNnz1Fsi6fnaAdc`,
	"footerList": `QUwl_BcgUpCVf5Q4VWgk`,
	"horizontal": `L4ts2Z1burhv0zJTOQ8O`
};
export default ___CSS_LOADER_EXPORT___;
