import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { User } from '../../services/userService';
import { useForm } from '../../utils/useForm';
import { OnePortal } from '../../services/onePortalService';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './OnePortalModal.scss';
import AlreadyRegistered from './AlreadyRegistered';
import SetupCredentials from './SetupCredentials';


// at the top level we check if we should open for this user
export default function OnePortalModal() {
    const [open, setOpen] = useState(false);
    // listen to the op service
    useEffect(() => {
        let done = false;
        let sub = OnePortal.subscribe(() => {
            if (!done) {
                setOpen(OnePortal.shouldOpen());
            }
        });
        return () => {
            done = true;
            OnePortal.unsubscribe(sub);
        }
    }, []);

    return (
        <OnePortalModalIpml startOpen={open} />
    );
}

// not sure we really need to do this?
function OnePortalModalIpml({startOpen}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(startOpen);
    const [showSetup, setShowSetup] = useState(false);
    const [hasSubmit, setHasSubmit] = useState(false);
    // they have an mbun, but logged in with old credentials
    // could happen if they forgot, or if they haven't finished setup
    const [legacyLogin, setLegacyLogin] = useState(false);

    // we probably don't need this to run every time?
    // FIXME change to be a init function for model?
    const names = User.user.fullName.replaceAll(/".*"/g,'').split(' ');
    let firstName = names[0];
    let lastName = names[1];

    if (names.length > 2) {
        // try to split them in the middle I guess?
        const mid = names.length/2;
        firstName = names.slice(0, mid).join(' ');
        lastName = names.slice(mid).join(' ');
    }

    const [model, setModel] = useState({
        firstName: firstName, 
        lastName: lastName,
        isUSCustomer: User.isUSCustomer(),
        email: User.user.sfEmail,
        status: User.user.sfStatus,
        countryCode: User.isUSCustomer() ? 'US' : 'CA'
    });

    const [error, setError] = useState('fields');
    const { fieldErrors, setFieldError } = useForm(validate);

    const [success, setSuccess] = useState(false);
    // we sure do have a lot of flags
    const [working, setWorking] = useState(false);

    // listen to the op service
    useEffect(() => {
        let done = false;
        let sub = OnePortal.subscribe((data) => {
            console.log('got one portal publish');

            if (!done) {
                setLegacyLogin(OnePortal.legacyLogin);
                // we only want to set to open once per user
                // we don't want to set it everytime the user changes
                // we don't draw this until we have a user - so we know at creation time
                if (OnePortal.reOpen){
                    OnePortal.reOpen = false;
                    setOpen(true);
                }
                setSuccess(OnePortal.success);
                setWorking(false);
            }
        });
        return () => {
            done = true;
            OnePortal.unsubscribe(sub);
        }
    }, []);

    useEffect(() => {
        OnePortal.getMigrate();
    }, []);

    useEffect(() => {
        setOpen(startOpen);
    }, [startOpen])

    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError('field');
        } else {
            if (model.phoneNumber != model.confirmPhoneNumber) {
                    setError('phones');
            } else {
                // all fields are manditory, but we don't enforce it at the field level because those errors would look ugly
                if (!model.phoneNumber) {
                    setError('fields');
                } else {
                    setError(false);
                }
            }
        }
    }

    const submit = (e) => {
        setHasSubmit(true);
        // don't do default stuff
        e.preventDefault();
        e.stopPropagation();

        if (error) {
            // there's an error
            return false;
        }

        // strip out non-digits
        model.telephoneNumber = formatPhoneNumberIntl(model.phoneNumber).replace(/\D/g, '');

        setWorking(true);
        OnePortal.register(model);
    };

    const onFormChange = () => {
        setHasSubmit(false);
    }

    const handleChange = (name, value) => {
        model[name] = value;
        setModel({ ...model });
        validate();
    };

    function resendEmail() {
        setWorking(true);
        OnePortal.resendEmail();
    }

    function getModalBody(partialSetup) {
        let firstButton, otherButtons, body = null;
        let header = <h2>{t('td.fx.op.important.information')}</h2>;
        
        if (!legacyLogin) {
            // is this the right button if we had an error or if we're done?
            let label = t(`${!showSetup ? 'td.fx.not.now' : 'td.fx.signing.cancel'}`);
            if (!model.email) {
                label = t('td.fx.signing.cancel');
            }
            firstButton = (
                <Button disabled={working} onClick={() => setOpen(false)} className="btn td-btn-secondary-clear">{label}</Button>
            );
            if (!showSetup) {
                // check name before email
                if (!model.firstName || !model.lastName) {
                    body = (
                        <>
                            <p>{t('td.fx.op.important.information.body')}</p>
                            <h4 className="td-error">{t(User.deskSpecificText('td.fx.op.important.information.bad.name'))}</h4>
                        </>
                    );
                } else if (model.email) {
                    otherButtons = (
                        <Button disabled={working} onClick={() => setShowSetup(true)} className="btn td-btn-primary-light">{t('td.fx.setup')}</Button>
                    );
                    body = <p>{t('td.fx.op.important.information.body')} {t('td.fx.op.important.information.body.setup')}</p>;
                } else if (model.status == "MULTIPLE") {
                    body = (
                        <>
                            <p>{t('td.fx.op.important.information.body')}</p>
                            <h4 className="td-error">{t('td.fx.op.important.information.multiple.email')}</h4>
                        </>
                    );
                } else if (model.status == "EMAIL_ALREADY_REGISTERED") {
                    body = (
                        <>
                            <p>{t('td.fx.op.important.information.body')}</p>
                            <h4 className="td-error">{t(User.deskSpecificText('td.fx.op.important.information.registered.email'))}</h4>
                        </>
                    );
                } else {
                    body = (
                        <>
                            <p>{t('td.fx.op.important.information.body')}</p>
                            <h4 className="td-error">{t(User.deskSpecificText('td.fx.op.important.information.no.email'))}</h4>
                        </>
                    );
                }
            }
        }


        return (
            <>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Body className="td-modal-body-shown">
                    {body}
                </Modal.Body>
                <Modal.Footer>
                    {firstButton}
                    {otherButtons}
                </Modal.Footer>
            </>
        )
    }

    
    if (legacyLogin) {
        return <AlreadyRegistered success={success} open={open} setOpen={setOpen} working={working} resendEmail={resendEmail} />
    } else {
        if (showSetup) {
            return <SetupCredentials open={open} setOpen={setOpen} model={model} handleChange={handleChange} submit={submit} hasSubmit={hasSubmit} error={error} onFormChange={onFormChange}/>
        }
    }
    

    // modal size should be smallish, but if the press setup we might need it to be wider...
    return (
        <Modal show={open} centered className="td-modal modal-xl">
            {getModalBody(!OnePortal.finishedSetup)}
        </Modal>
    );
}
