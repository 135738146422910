import i18next from 'i18next';

import { Observer } from '../utils';

function SettlementService() {
    // somewhere for our settlement screens to cache the current
    // working model
    this.setCurrent = function(deal) {
        this.current = deal;
    };

    this.clear = function() {
        this.current = null;
    };

    

    // FIXME - do we need this?
    // we want observers to be fired - but some only for new deals, not quotes
    // we will publish state changes, and observers can look at it?
    Observer.mixin(this);
}
export const Service = new  SettlementService();
