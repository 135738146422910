import { t } from 'i18next';
import React from 'react';
import { Table } from 'react-bootstrap';

export default function InverseQuotes() {
        return (
        <div>
            <h4>{t('td.fx.support.inverseQuotes.title.inverseQuotes')}</h4>
            <p>{t('td.fx.support.inverseQuotes.explanation.inverseQuotes01')}</p>
            <h5>{t('td.fx.support.inverseQuotes.title.multiplierCurrencies')}</h5>
            <p>{t('td.fx.support.inverseQuotes.explanation.multiplierCurrencies')}</p>
            <Table responsive>
                <thead>
                    <tr>
                        <th>{t('td.fx.currency.title.currencyCode')}</th>
                        <th>{t('td.fx.currency.title.currencyName')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.AUD')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.AUD')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.EUR')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.EUR')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.GBP')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.GBP')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.NZD')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.NZD')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.USD')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.USD')}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <h5>{t('td.fx.support.inverseQuotes.title.divisorCurrencies')}</h5>
            <p>{t('td.fx.support.inverseQuotes.explanation.divisorCurrencies01')}</p>
            <p>{t('td.fx.support.inverseQuotes.explanation.divisorCurrencies02')}</p>
            <Table responsive>
                <thead>
                    <tr>
                        <th>{t('td.fx.currency.title.currencyCode')}</th>
                        <th>{t('td.fx.currency.title.currencyName')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.AED')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.AED')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.CHF')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.CHF')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.CNH')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.CNH')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.CZK')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.CZK')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.DKK')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.DKK')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.HKD')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.HKD')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.HUF')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.HUF')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.ILS')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.ILS')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.INR')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.INR')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.JPY')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.JPY')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.MXN')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.MXN')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.NOK')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.NOK')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.PLN')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.PLN')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.SAR')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.SAR')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.SEK')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.SEK')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.SGD')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.SGD')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.THB')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.THB')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.TRY')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.TRY')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {t('td.fx.currency.code.ZAR')}
                        </td>
                        <td>
                            {t('td.fx.currency.name.ZAR')}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>);
}