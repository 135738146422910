import { useTranslation } from 'react-i18next';

const currencies = [ { text: 'AED', value: 'AED' },
                     { text: 'AUD', value: 'AUD' },
                     { text: 'CAD', value: 'CAD' },
                     { text: 'CHF', value: 'CHF' },
                     { text: 'CNH', value: 'CNH' },
                     { text: 'CZK', value: 'CZK' },
                     { text: 'DKK', value: 'DKK' },
                     { text: 'EUR', value: 'EUR' },
                     { text: 'GBP', value: 'GBP' },
                     { text: 'HKD', value: 'HKD' },
                     { text: 'HUF', value: 'HUF' },
                     { text: 'ILS', value: 'ILS' },
                     { text: 'INR', value: 'INR' },
                     { text: 'JPY', value: 'JPY' },
                     { text: 'MXN', value: 'MXN' },
                     { text: 'NOK', value: 'NOK' },
                     { text: 'NZD', value: 'NZD' },
                     { text: 'PLN', value: 'PLN' },
                     { text: 'SAR', value: 'SAR' },
                     { text: 'SEK', value: 'SEK' },
                     { text: 'SGD', value: 'SGD' },
                     { text: 'THB', value: 'THB' },
                     { text: 'TRY', value: 'TRY' },
                     { text: 'USD', value: 'USD' },
                     { text: 'ZAR', value: 'ZAR' }
];

const getGoodsAndServicesOptions = () => { 
    const { t } = useTranslation();
    return [
        { text: t('td.fx.tradeSettlement.wirePayment.responses.goods'), value: 'Goods'},
        { text: t('td.fx.tradeSettlement.wirePayment.responses.services'), value: 'Services'}
    ];
};

const getYesNoOptions = () => {
    const { t } = useTranslation();
    return [ 
        { text: t('td.fx.tradeSettlement.wirePayment.responses.yes'), value: 'Yes' },
        { text: t('td.fx.tradeSettlement.wirePayment.responses.no'), value: 'No' }
    ];
}

const getYesNoRemoveOptions = () => {
    const { t } = useTranslation();
    return [
        { text: t('td.fx.tradeSettlement.wirePayment.responses.yes'), value: 'Yes' },
        { text: t('td.fx.tradeSettlement.wirePayment.responses.no'), value: 'No' },
        { text: t('td.fx.tradeSettlement.wirePayment.responses.remove'), value: 'Remove' }
    ];
}

export { 
    currencies,
    getGoodsAndServicesOptions,
    getYesNoOptions,
    getYesNoRemoveOptions
}