import { useCallback } from "react";
import DateInput from "./DateInput";

import { Hours } from '../../services/hoursService';


export default function ValueDateInput({name, value, update, setFieldError, errorPrefix, className, min, max, cur1, cur2, placeHolder, clearOnError, disabled}) {
    // return a string to build the error or false
    // what about min/max?
    const validateDate = useCallback((val) => {
            // check if the date is a currency holiday for either of the currencies
            if (!Hours.HolidayCalendar.isBusinessDate(val, cur1, cur2)) {
                return 'invalid.holiday.date';
            } else {
                return false;
            }
        }, [cur1, cur2]);

    return (
        <DateInput
            className={className}
            name={name}
            value={value}
            update={update}
            min={min}
            max={max}
            validate={validateDate}
            setFieldError={setFieldError}
            errorPrefix={errorPrefix}
            placeHolder={placeHolder}
            clearOnError={clearOnError}
            disabled={disabled}
        />
    );
}

