import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { TradeBlotter, MobileTradeView } from '../common';
import { rfqHelper } from '.';
import { KeepAlive } from '../../services/keepAliveService';
import { Search } from '../../services/searchService';
import { User } from '../../services/userService';
import { Hours as HoursService } from '../../services/hoursService';

import { Service as DealService, useDealService } from '../../services/dealService';

// sub-screens
import Spot from './Spot';
import Swap from './Swap';
import WindowForward from './WindowForward';

import styles from './RequestForQuote.scss';
export default function RequestForQuote(props) {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 64em)' });

    // make sure we have our order data
    useEffect(() => {
        // blotters listen for search updates so we don't need to subscribe
        Search.load();
    }, []);


    function fixCurrency(currency, accounts, currencyDefault) {
        // check we have a currency, and if we do that it's still one that's available in our accounts for this company
        if (currency && accounts.find(acc => acc.ccy == currency)) {
            // all good
            return currency;
        } else {
            // if the default is in the list, set that, otherwise set the first currency in the list
            if (accounts.find(acc => acc.ccy == currencyDefault)) {
                return currencyDefault;
            } else {
                // not a lot we can do if there are no accounts at all
                return accounts[0]?.ccy;
            }
        }
    }

    const [company, setCompany] = useState(User.selectedCompany);
    const [product, setProduct] = useState(rfqHelper.getProductDefault());
    const products = rfqHelper.getProductTypes(User.user.permissions);
    const canQuote = !!User.user.permissions.TRADE && HoursService.isBranchOpen();

    function getInitialModel() {
        return {
            buyCurrency: fixCurrency(false, company.buyAccounts, company.currencyDefault),
            sellCurrency: fixCurrency(false, company.sellAccounts, company.currencyDefault)
        };
    }
    const [model, setModel] = useState(() => ( getInitialModel() ));

    useDealService(model, setModel, clear, setHasSubmitted);

    function getQuote() {
        if (!error) {
            // FIXME - don't love ...model here
            DealService.getQuote({ company: company, product: product, ...model});
        }
        setHasSubmitted(true);
        // also keep alive
        KeepAlive.touch();
    }

    function clear() {
        KeepAlive.touch();
        setHasSubmitted(false);
        // if we can get to this button, we know we aren't in the middle of qoting etc. So no need to touch deal service
        // reset the model - but not company/product
        setModel(getInitialModel());
        // update model should trigger validation and set the right error value
    }

    const handleCompanyChange = (code) => {
        KeepAlive.touch();
        const selected = User.user.companies.find(company => company.code == code);
        if (selected) {
            User.setSelectedCompany(selected);
    
            // this call and getCurrencies are both itterating through the same collection...
            // this call uses the deafult currency of the new company, so pass it
            const buyCurrency = fixCurrency(model.buyCurrency, selected.buyAccounts, selected.currencyDefault);
            const sellCurrency = fixCurrency(model.sellCurrency, selected.sellAccounts, selected.currencyDefault);
    
            setCompany(selected);

            // currency values are used by the sub-screen not at this level
            // makes sense for them both to be in the model together
            model.buyCurrency = buyCurrency;
            model.sellCurrency = sellCurrency;
            setModel({ ...model  });
        }
        Search.update();
    };


    // we need this to fire the first time so our method is called
    useEffect(() => {
        if (!company) {
            company = User.selectedCompany;
        }
        handleCompanyChange(company.code);
    }, []);

    // FIXME - add swap
    const renderType = () => {
        if (!company) {
            return null;
        }

        if (product == 'FWDOPT') {
            return <WindowForward model={model} setModel={(m) => setModel({ ...m})} company={company} setError={setError} />
        } else if (product == 'SWAP') {
            return <Swap model={model} setModel={(m) => setModel({ ...m})} company={company} setError={setError} />
        } else {
            return <Spot model={model} setModel={(m) => setModel({ ...m})} permissions={User.user.permissions} company={company} setError={setError} />
        }
    };

    const renderTradeView = () => {
        if (isTabletOrMobile) {
            return <MobileTradeView tabs={[{type:'TODAY', label: 'td.fx.blotters.allOrders'}, {type: 'FO', label: 'td.fx.blotters.fwdOptions'}]}/>;
        }
        return <TradeBlotter grids={[{type:'TODAY', label: 'td.fx.blotters.allOrders'}, {type: 'FO', label: 'td.fx.blotters.fwdOptions'}]} delta={ 725 } />;
    }

    // has the user tried to quote with the current data
    // we only display form level errors after they do this
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [error, setError] = useState();

    function updateProduct(value) {
        setProduct(value);
        // reset everything when we change product
        clear();
        // re-loading the model should trigger a re-render of the contained elements
        // and a re-render, but won't trigger  a re-validate unless some data changed.
        // clear already does this, and 
        // setModel({ ...model  });
    }

    return (
        <div className="main-container">
            <Form>
                <Row className="gy-3">
                    <Col xs={12} sm={6} md={6} lg={5} xl={4}>
                        <Form.Group>
                            <Form.Label>{t('td.fx.ticket.lbl.Company')}</Form.Label>
                            <Form.Select 
                                className={'form-control'}
                                disabled={DealService.isWorking()}
                                id="company" name="company"
                                onChange={(e) => handleCompanyChange(e.target.value)}
                                value={company.code}   
                            >
                            { User.user.companies.map(company => 
                                <option key={company.code} value={company.code}>{company.name}</option>
                            )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={5} xl={4}>
                        <Form.Group>
                            <Form.Label>{t('td.fx.ticket.lbl.Product')}</Form.Label>
                            <Form.Select disabled={DealService.isWorking() || !canQuote}
                                className={'form-control'}
                                id="product" name="product"
                                onChange={(e) => updateProduct(e.target.value)}
                                value={product}
                            >
                            { products.map(p => 
                                <option key={p.value} value={p.value}>{p.text}</option>
                            )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <fieldset disabled={DealService.isWorking() || !canQuote}>
                    <hr className="td-thin-divider-line-1" style={{ padding: '2em 0'}}/>
                    { renderType() }
                    <Row className="g-3">
                        <Col xs={12} sm={12} md={6} lg={4}>
                            <Row>
                                <Col sm={12}>
                                    <Form.Label className={'td-error'} hidden={!error || !hasSubmitted}>{ error && t('td.fx.ticket.' + error) }</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={6} lg={5}>
                                    <div className="d-grid gap-2">
                                        <Button className="btn td-btn-secondary-clear" onClick={clear}>{t('td.fx.ticket.clear')}</Button>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={5}>
                                    <div className="d-grid gap-2">
                                        <Button className={`btn td-btn-primary-light ${styles["rfq-btn"]}`} onClick={getQuote}>{t('td.fx.ticket.REQUESTQUOTE')}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </fieldset>
            </Form>
            { renderTradeView() }
        </div>
    )
}


