import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './support.scss';

export default function SwapTrading() {
    const { t } = useTranslation();
    return (
        <div>
            <h4>{t('td.fx.support.swapTrading.title.swapTrading')}</h4>
            <p>{t('td.fx.support.swapTrading.explanation.swapTrading01')}</p>
            <Trans i18nKey={'td.fx.support.swapTrading.explanation.swapTrading02'}>
                <p><Link to={'/rfq'} className="link-underline">x</Link>x</p>
            </Trans>

            <h5>{t('td.fx.support.swapTrading.title.step01')}</h5>
            <p>{t('td.fx.support.swapTrading.explanation.step01.01')}</p>
            <p>{t('td.fx.support.swapTrading.explanation.step01.02')}</p>
            <p>{t('td.fx.support.swapTrading.explanation.step01.03')}</p>
            <h5>{t('td.fx.support.swapTrading.title.step02')}</h5>
            <p>{t('td.fx.support.swapTrading.explanation.step02.01')}</p>
            <figure>
                <img src={t('td.fx.support.SwapFig1')} className={`${styles["support-img-max"]}`}/>
                <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
            </figure>
            <Trans i18nKey={'td.fx.support.swapTrading.explanation.step02.02'}
            >
                <p>a <Link to={'spot'} className="link-underline">b</Link>c</p>
            </Trans>
            <p>{t('td.fx.support.swapTrading.explanation.step02.03')}</p>
            <h5>{t('td.fx.support.swapTrading.title.step03')}</h5>
            <p>{t('td.fx.support.swapTrading.explanation.step03.01')}</p>
            <p>{t('td.fx.support.swapTrading.explanation.step03.02')}</p>
            <p>{t('td.fx.support.swapTrading.explanation.step03.03')}</p>
            <figure>
                <img src={t('td.fx.support.SwapFig2')} className={`${styles["support-img-max"]}`}/>
                <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
            </figure>
            <p>{t('td.fx.support.swapTrading.explanation.step03.04')}</p>
            <h5>{t('td.fx.support.swapTrading.title.step04')}</h5>
            <Trans i18nKey={'td.fx.support.swapTrading.explanation.step04.01'}
            >
                <p><Link to={'inverse'} className="link-underline">b</Link>c</p>
            </Trans>

            <p>{t('td.fx.support.swapTrading.explanation.step04.02')}</p>
            <figure>
                <img src={t('td.fx.support.SwapFig3')} />
                <figcaption>{t('td.fx.support.figureCaption03')}</figcaption>
            </figure>
            <h5>{t('td.fx.support.swapTrading.title.step05')}</h5>
            <Trans i18nKey={'td.fx.support.swapTrading.explanation.step05.01'}
            >
                <p><Link to={'spot'} className="link-underline">b</Link>c</p>
            </Trans>
        </div>
    );
}