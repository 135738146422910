import React, { useState, Fragment, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


import { useTranslation } from 'react-i18next';
import WireDetails from '../../RequestForQuote/WireDetails';

// FIXME - we might not need all of these?
import { useForm } from '../../../utils/useForm';
import { useModel } from '../../RequestForQuote/useModel';

import { User } from '../../../services/userService';
import { Service as DealService } from '../../../services/dealService';
import { Service as SettlementService } from '../../../services/settlementService';

const TODAY = new Date();

function Settlement({ deal, row, close }) {
    const { t } = useTranslation();

    // model is set from the deal
    const [model, setModel] = useState({});

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [error, setError] = useState();

    // if we call useAccounts, it's going to trigger effects when the company changes
    // and because we don't have a company in state, that would be bad.

    // FIXME - copied form useAccounts - FIXME
    function handleAccountChange(name, ssid, accounts) {
        const account = accounts.filter(acc => acc.ssid == ssid)[0];
        model[name] = account;
        updateModel(model);
    };

    // FIXME - when the expander closes, we should probably clear this?
    // pretty sure we can't tell the difference here - needs to be where the open/close happens
    function setModelFromDeal(d) {
        // if this is happenning because scrolled away/back
        //    we want to keep the data from the service
        // but if we are updating to the same deal because the deal in the blotter update
        //   we want to make sure we get the updates - should be just outstanding balances:
        if (SettlementService.current && SettlementService.current.dealId == d.dealId) {
            let working = SettlementService.current;
            updateModel(working);
        } else {
            SettlementService.current = {
                dealId: d.dealId,
                buyCurrency: d.buyCurrency,
                sellCurrency: d.sellCurrency
            };
            updateModel(SettlementService.current);
        }
    }

    const company = User.user.companies.find(c => c.code == deal.clientId);

    useEffect(() => {
        // clear the model and update
        setModelFromDeal(deal);
    }, [deal]);


    // FIXME - extract this/use the hook from DealService

    // listen for deal state
    // we only copy this because we need to trigger a re-render
    const [dealState, setDealState] = useState(DealService.state);
    useEffect(() => {
        let done = false;
        let sub = DealService.subscribe(() => {
            if (!done) {
                setDealState(DealService.state);
            }
        });
        return (() => {
            done = true;
            DealService.unsubscribe(sub);
        });
    }, []);

    useEffect(() => {
        // FIXME - does it actually make sense to separate this behaviour from the useEffect above
        // not sure it does
        if (DealService.isInitialState()) {
            // FIXME - we don't really want this all the time
            // - if they canceled or rejected a trade, we want to leave the data alone
            // - so we need to understand the previous state
            if (model.done) {
                //clear();
            } else {
                // still clear the submit state
                setHasSubmitted(false);
            }
        } else if (DealService.isTradeSettled()) {
            // clear the DealService
            DealService.newTrade();
            setModel({ done: true, ...model });
            close();
        }
    }, [dealState]);


    const { handleChange } = useModel(model, updateModel);
    const { fieldErrors: errors, setFieldError } = useForm(validate);

    function validate(fields) {
        fields = fields || errors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError('error.fields');
        } else {
            if (!model.buyAccount || !model.sellAccount) {
                setError('lbl.selectOption');
            } else {
                setError(false);
            }
        }
    }

    function updateModel(m) {
        setModel({ ...m });
        validate();
    }

    function doSettlement() {
        if (!error) {
            DealService.settle({ company: company, product: deal.product, ...model });
            // cancel();
        }
        setHasSubmitted(true);
    }

    function cancel() {
        // clear out anything we need to clear in our helpers
        close();
    }

    // takes us a render cycle to load the copmany/model
    if (!company) {
        // FIXME - we should probably display something if this is really is for a company we don't know about?
        // actually better to not allow row expansion in this case
        return null;
    }
    return (
        <Form disabled={DealService.isWorking()} className="main-container">
            <h4 style={{ marginTop: '1em'}}>{t('fx.blotter.settlement.applySettlementDetails')}</h4>
            <fieldset style={{ paddingBottom: '1.5em' }}>
            <Row>
                <Col sm={3} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t('td.fx.ticket.lbl.to')}
                            {model.buyAccount?.wire &&
                                <WireDetails wire={model.buyAccount.wire} />
                            }
                        </Form.Label>
                        <Form.Select
                            name="buyAccount"
                            value={model.buyAccount?.ssid || ''}
                            placeholder={t('td.fx.ticket.lbl.selectOption')}
                            onChange={(e) => handleAccountChange('buyAccount', e.target.value, company.buyAccounts)}
                        >
                            <option hidden>{t('td.fx.ticket.lbl.selectOption')}</option>
                            {company?.buyAccounts?.filter(a => a.ccy == model.buyCurrency).map(a =>
                                <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={3} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('td.fx.ticket.lbl.from')}</Form.Label>
                        <Form.Select
                            name="sellAccount"
                            value={model.sellAccount?.ssid || ''}
                            placeholder={t('td.fx.ticket.lbl.selectOption')}
                            onChange={(e) => handleAccountChange('sellAccount', e.target.value, company.sellAccounts)}
                        >
                            <option hidden>{t('td.fx.ticket.lbl.selectOption')}</option>
                            {company?.sellAccounts?.filter(a => a.ccy == model.sellCurrency).map(a =>
                                <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={3} xs={12}>
                    <Form.Group className={`${model.buyAccount?.wire ? 'mb-3' : null}`}>
                        <Form.Label>{t('td.fx.ticket.lblMemo')}</Form.Label>
                        <Form.Control
                            name="tradeMemo"
                            value={model.tradeMemo || ''}
                            placeholder={t('td.fx.ticket.memo.prompt')}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                {model.buyAccount?.wire &&
                <Col sm={3} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('td.fx.ticket.lblWirePaymentDetails')}</Form.Label>
                        <Form.Control
                            name="wireMemo"
                            disabled={true}
                            value={deal.wireMemo || ''}
                            placeholder={t('td.fx.ticket.wire.prompt')}
                            maxLength="40"
                        />
                    </Form.Group>
                </Col>
                }
            </Row>
                </fieldset>
            <Row>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col sm={12}>
                            <Form.Label className={'td-error'} hidden={!error || !hasSubmitted}>{error && t('td.fx.ticket.' + error)}</Form.Label>
                        </Col>
                    </Row>
                    <Row className="gy-4">
                        <Col sm={6}>
                            <div className="d-grid gap-2">
                                <Button className="btn td-btn-secondary-clear" onClick={cancel}>{t('fx.blotter.settlement.cancelSettlement')}</Button>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="d-grid gap-2">
                                <Button className="btn td-btn-primary-light" onClick={doSettlement}>{t('fx.blotter.settlement.acceptSettlement')}</Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default Settlement;