import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter }  from 'react-router-dom';

import App from './components/App';

import './i18n';
import './index.scss';

import {ThemeProvider} from '@com-td-aacs/aacs-ui-lib';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
<BrowserRouter basename="/tdfx">
    <ThemeProvider>
        <App/>
    </ThemeProvider>
</BrowserRouter>
);

