import React, { useState, Fragment, useCallback, useRef, useMemo } from 'react';
// FIXME - extract these styles so we can drop react-table
import './DataGridAG.scss';

// import { VariableSizeList as List } from 'react-window';

// import Draggable from 'react-draggable';

// do we need to set a license?
import { AgGridReact } from 'ag-grid-react';
import {LicenseManager} from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-053668}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{The_Toronto-Dominion_Bank}_is_granted_a_{Multiple_Applications}_Developer_License_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_July_2025}____[v3]_[01]_MTc1MzY1NzIwMDAwMA==3d3e57dd3cc3749f105e5441bc90030a');

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import moment from 'moment';


import Expander from '../Expander/Expander';
import { useTranslation } from 'react-i18next';



// we want to get the expander here so we can use that to figure out which details to build
function DetailsRenderer({api, node, data, expander}) {
    return expander?.getChildRowContent(data, () =>{}, () => api?.setRowNodeExpanded(node?.parent, false), () =>{}, 0);
}


function getRowId(params) {
    return params.data.dealId + params.data.product;
}

// if we expand one row, close all the others
// note that we're calling all the rows, not just the visble ones
function onRowGroupOpened({api, node, expanded}) {
    if (expanded) {
        // close all the other nodes - there will only be one, so just call setExpanded
        api.forEachNode((n, index) => {
            if (n.expanded && (n != node)) {
                api.setRowNodeExpanded(n, false);
            }
        });
    }
}

function DataGrid({ theGrid, height, filtered,  onFilteredChange, sorting, setSorting, className, compact, columns, setColumns,
    canRowExpand, expander,  dataId = 'id', dataId2= '', data = [], dataLastUpdate = 0, rowCount = true, flashRows = true, statusBar, hideNoRows, ...props }) {

    const {t} = useTranslation();
    const getLocaleText = useCallback((params) => {
        return t(params.key);
    });

    const onGridReady = useCallback(({api}) => {
        if (theGrid) {
            theGrid.ref.current = {
                resetFilters: () => api?.setFilterModel(null), 
                loading: (isLoading) => {
                    // console.log('grid.current showLoadingOverlay', isLoading);
                    // api.showLoadingOverlay(isLoading);
                },
                exportCsv: () => {
                    // remove the first column of the export since it is empty
                    const visibleCols = api.getColumnDefs().filter(c => !c.hide);
                    const columnKeys = visibleCols.slice(1, visibleCols.length).map(c => c.field);
                    
                    api.exportDataAsCsv({ fileName:`TDFX-${moment().format('MM-DD-YYYY')}.csv`, columnKeys: columnKeys });
                },
                getVisibleData: () => {
                    let theData = [];
                    api.forEachNodeAfterFilterAndSort((node, index) => {
                        theData.push(node.data);
                    });
                    return theData;
                }
            };
            // how do we decide if we should be showing the loading overlay or not?
            // FIXME - this is clearly incorrect
            if (!data.length) {
                // api.showLoadingOverlay(true);
            }
        }
    });

    const theStatusBar = useMemo(() => {
        return {
          statusPanels: [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }
          ],
        };
      }, []);

    // console.log('Data grid render data length:', data?.length);
    // console.log('data[0]', data[0]);
    return (
        <div className={"ag-theme-alpine " + (className || '')} style={ {width: "100%", height: height} }>
            <AgGridReact
                masterDetail={true}
                isRowMaster={canRowExpand}
                detailCellRenderer={DetailsRenderer}
                detailCellRendererParams={{expander, refreshStrategy: 'everything'}} 
                detailRowAutoHeight={ true }
                rowData={ data }
                columnDefs={ columns }
                suppressCellFocus={ true }
                getLocaleText={getLocaleText}
                onGridReady={onGridReady}
                getRowId={getRowId}
                embedFullWidthRows={true}
                onRowGroupOpened={onRowGroupOpened}
                enableCellChangeFlash={flashRows}
                statusBar={statusBar && theStatusBar}
                suppressNoRowsOverlay={hideNoRows}
            />
        </div>
    );
}

export default DataGrid;
