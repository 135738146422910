import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Tab, Tabs, Image, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { uniqBy, sortBy, isEmpty } from 'lodash';

import { Search } from '../../../services/searchService';
import utilities from '../../../utils/';
import TradeDetails from './TradeDetails';
import SearchForm from './SearchForm';
import RFQModal from '../../RequestForQuote/RFQModal';

import spinner from '../../../assets/td-emerald-loading-spinner-small.png';

function filterForToday(data) {
    // FIXME - this is in GMT, which means it changes date faster than we'd expect
    // const TODAY_STRING = new Date().toJSON().slice(0, 10);
    let now = new Date();
    now.setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset());
    const TODAY_STRING = now.toJSON().slice(0, 10);
    // trades with a trade date that starts with 
    // easier to check the string than create a date everytime
    // FIXME - we may need to check for tomorrow too?
    return data.filter((trade) => { return TODAY_STRING.startsWith(trade.tradeDate) });
}

function filterForFO(data) {
    // every FO that has an end date > yesterday AND has an availble balance
    // FIXME - we want FOs that the user finished today?
    return data.filter((trade) => { return (trade.remainingBuyAmount || trade.remainingSellAmount) } );
}

function filteredData(data, type) {
    let theData = data;
    switch(type) {
        case 'TODAY':
            theData = filterForToday(data);
            break;
        case 'FO':
            theData = filterForFO(data);
            break;
    };
    return theData;
}

function filterForMobile(data, searchFilters) {
    if (Object.keys(searchFilters).length == 0) {
        // if no filters are selected, return the first 50
        return data.filter((t, index) => { 
            return index < 50;
        });    
    }

    return data.filter((trade) => { 
        if (searchFilters.dealId?.length > 0) {
            return trade.dealId == searchFilters.dealId;
        } else {
            return (isEmpty(searchFilters.clientId) || (searchFilters.clientId?.length > 0 && searchFilters.clientId == trade.clientId)) 
                && (isEmpty(searchFilters.product) || (searchFilters.product?.length > 0 && trade.product.startsWith(searchFilters.product)));
        }
    });
}

export default function MobileTradeView({ tabs }) {
    const { t } = useTranslation();
    let theTabs = tabs || [{type: 'ALL'}];
    const formatAmount = utilities.format.formatAmount;

    const [data, setData] = useState(Search.getTrades());
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentDeal, setCurrentDeal] = useState();
    const [clientList, setClientList] = useState();
    const [hasSubmit, setHasSubmit] = useState(false);

    useEffect(() => {
        let done = false;
        let sub = Search.subscribe((dealId) => {
            if (!done) {
                setData([...Search.getTrades()]);
                setLoading(false);
            }
        });

        // make sure someone has loaded search
        Search.load();
        return (() => {
            done = true;
            Search.unsubscribe(sub);
        });
    },[]);

    useEffect(() => {
        const clients = sortBy(uniqBy(data, 'clientId'), 'clientId');
        setClientList(clients);

        if (!currentDeal) {
            theTabs.length > 1 ? setDefaultDeal(0) : setDefaultDeal(null, true);
        }
        
    },[data]);

    useEffect(() => {
        if (hasSubmit) {
            setDefaultDeal(null, filter);
        }
    },[hasSubmit, filter]);

    function doSubmit(e) {
        setHasSubmit(true);
        // don't do default stuff
        e.preventDefault();
        e.stopPropagation();

        if (error) {
            // there's an error
            return false;
        }

        if (Object.keys(filter).length == 0) {
            setError("Please input at least one search criteria.")
            return false;
        } else {
            setError(false);
        }        
    }

    const clear = () => {
        setFilter({});
        setHasSubmit(false);
        setCurrentDeal(false);
    }

    const onFormChange = () => {
        setHasSubmit(false);
        setCurrentDeal(false);
    }

    const onTabChange = (dealId) => {
        const trade = data.find(d => d.dealId == dealId);
        setCurrentDeal(trade);
    };

    const setDefaultDeal = (key, search = false) => {
        let theData = filteredData(data, key == 0 ? 'TODAY' : 'FO');
        if (search) {
            theData = filterForMobile(data, filter);
        }

        setCurrentDeal(theData[0]);
    };

    const TradeList = ({ type, filter }) => {
        const theData =  type ? filteredData(data, type) : filterForMobile(data, filter);

        return (
            <Form.Group style={{ margin: type ? '1em' : 'none', marginBottom: type ? 'none' : '1em' }}>
                <Form.Label>{theData.length} {t('td.blotter.mobile.results')}</Form.Label>
                <Form.Select onChange={(e) => onTabChange(e.target.value)} value={currentDeal?.dealId}>
                    { theData.map((trade, index) => 
                        trade.buySell === 'BUY' ?
                            <option key={index} value={trade.dealId}>{trade.tradeDate} - { trade.buySell } { formatAmount(trade.buyAmount) } { trade.buyCurrency } / { trade.sellCurrency } @ { trade.dealtRate }</option>
                        : 
                            <option key={index} value={trade.dealId}>{trade.tradeDate} - { trade.buySell } { formatAmount(trade.sellAmount) } { trade.sellCurrency } / { trade.buyCurrency } @ { trade.dealtRate }</option>
                    )}
                </Form.Select>
            </Form.Group>
        );
    }

    if (theTabs.length > 1) {
        return (
            <>
            <Tabs defaultActiveKey={0} style={{ marginTop: '3em' }} onSelect={(k) => setDefaultDeal(k)}> {
                theTabs.map((g, index) => {
                    return (
                        <Tab title={t(g.label || 'trades')} eventKey={index} key={index}>
                            { loading && <Image src={spinner}/> }
                            { !loading && <TradeList type={g.type} /> }
                            { !loading && currentDeal && <TradeDetails deal={currentDeal} /> }
                        </Tab>
                    );
                }) }
            </Tabs>
            <RFQModal/>
            </>
        );
    } else {
        return (
            <>
                <Form noValidate onSubmit={doSubmit} onChange={onFormChange}>
                    <SearchForm filter={filter} setFilter={setFilter} setError={setError} clients={clientList} hasSubmit={hasSubmit}/>
                    <Row>
                        <Col sm={12}>
                            <Form.Label className={'td-error'} hidden={!error || !hasSubmit}>{ error }</Form.Label>
                        </Col>
                        <Col xs={6} sm={6} md={6}>
                            <div className="d-grid gap-2">
                                <Button className="btn td-btn-secondary-clear" onClick={clear}>{t('td.fx.ticket.clear')}</Button>
                            </div>
                        </Col>
                        <Col xs={6} sm={6} md={6}>
                            <div className="d-grid gap-2">
                                <Button className="btn td-btn-primary-light" type="submit">{t('td.fx.search.label.search')}</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <hr className="td-thin-divider-line-1" style={{ padding: '2em 0'}}/>
                { ((!hasSubmit && Object.keys(filter).length == 0) || hasSubmit) && <TradeList filter={filter}/> }
                { currentDeal && <TradeDetails deal={currentDeal} /> }
            </>
        );
    }
}