import moment from 'moment';

function defaultFilter({ id, value }, row) {
    if (row[id] === undefined) { return false; }
    const cellValue = row[id].replace(/\//g,'').toLowerCase();
    const filterValue = value.replace(/\//g,'').toLowerCase();
    return cellValue.startsWith(filterValue);
}

function includesFilter({ id, value }, row) {
    if (row[id] === undefined || row[id] === null) { return false; }
    const cellValue = row[id].toLowerCase();
    const filterValue = value.toLowerCase();
    return cellValue.includes(filterValue);
}

function numericFilter(filter, row, column) {
    if (row[filter.id] === undefined) { return false; }

    const cellValue = row[filter.id].toString();
    const filterValue = filter.value.replace(/,/g,'');
    return isNaN(filterValue) || isNaN(cellValue) ? false : cellValue.startsWith(filterValue);
}

function dateTimeFilter({ id, value }, row) {
    if (!moment(row[id]).isValid()) { return false; }
    return moment(row[id]).format('LTS MM-DD-YYYY').startsWith(value);
}

function dateFilter({ id, value }, row) {
    if (!moment(row[id]).isValid()) { return false; }
    return moment(row[id]).format('MM-DD-YYYY').startsWith(value);
}

export default {
    defaultFilter,
    includesFilter,
    numericFilter,
    dateTimeFilter,
    dateFilter
};
