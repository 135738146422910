import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import utilities from '../../../utils/';
import { User } from '../../../services/userService';
import { Service as Confirmation } from '../../../services/confirmationService';
import FunctionalButtons from '../../RequestForQuote/FunctionalButtons';
import styles from './TradeDetails.scss';
import Settlement from './../Blotter/Settlement';
import DrawDown from './../Blotter/DrawDown';


export default function TradeDetails({ deal }) {
    const { t } = useTranslation();
    const formatAmount = utilities.format.formatAmount;
    const props = { data: deal };
    const [isDrawdownVisible, setIsDrawdownVisible] = useState(false);
    const isWindowForward = deal?.product.startsWith('FO');
    const canSettle = deal?.status == 'PO';
    
    useEffect(() => {
        setIsDrawdownVisible(false);
        // console.log(deal);
    },[deal]);

    function canSettleOrDrawdown(data) {
        // If a user can settle, they can drawdown.
        return ((User.canSettle() && canSettle) || (User.canDrawdown() && isWindowForward && canSettle));
    }

    function doConfirmation(data) {
        let theData = data;
        theData.product = 'DR';
        Confirmation.open(theData);
    }

    return (
        <>
            <div style={{ textAlign: 'right', paddingRight: '1em' }}>
                { canSettleOrDrawdown(deal) &&
                    <span className={`${styles['drawdown-icon']} td-icon icon-small td-icon-colour-primary td-icon-${ isDrawdownVisible ? 'collapse' : 'expand'}`} onClick={() => setIsDrawdownVisible(!isDrawdownVisible)}></span>
                }
                <FunctionalButtons {...props} />
            </div>
            
            { canSettleOrDrawdown(deal) && isDrawdownVisible && <>
                { isWindowForward ? <DrawDown deal={deal} close={setIsDrawdownVisible} /> : <Settlement deal={deal} row={deal} close={setIsDrawdownVisible} />}
                <div style={{ margin: '1em' }}>
                { deal.drawDowns?.map((dd, i) =>
                    <Row className={`${styles.drawdowns}`}>
                        <Col xs={1}>
                            <span style={{ cursor: 'pointer' }}onClick={() => doConfirmation(dd)}
                                className={`td-icon icon-small td-icon-colour-primary td-icon-manageBusiness`} 
                            ></span> 
                        </Col>
                        <Col>
                            <Row>
                                <Col><strong>{t('fx.blotter.header.deal.id')}</strong></Col>
                                <Col className={`${styles['align-right']}`}>{dd.dealId}</Col>
                            </Row>
                            <Row>
                                <Col><strong>{t('fx.blotter.header.value.date')}</strong></Col>
                                <Col className={`${styles['align-right']}`}>{dd.valueDate}</Col>
                            </Row>
                            <Row>
                                <Col><strong>{t('td.blotter.mobile.buy')}</strong></Col>
                                <Col className={`${styles['align-right']}`}>
                                    {formatAmount(dd.buyAmount)} {dd.buyCurrency}<br/>
                                    {dd.buyAccount}
                                </Col>
                            </Row>
                            <Row>
                                <Col><strong>{t('td.blotter.mobile.sell')}</strong></Col>
                                <Col className={`${styles['align-right']}`}>
                                    {formatAmount(dd.sellAmount)} {dd.sellCurrency}<br/>
                                    {dd.sellAccount}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )} 
                </div>
            </>}
            
            <div className={`${styles['trade-details-container']}`}>
                <div style={{ margin: '1em' }}>
                    <Row style={{ paddingTop: '1em' }}>
                        <Col><strong>{t('fx.blotter.header.deal.id')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.dealId }</Col>
                    </Row>
                    <Row>
                        <Col><strong>{t('fx.blotter.header.status')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.status }</Col>
                    </Row>
                    <Row>
                        <Col><strong>{t('fx.blotter.header.trade.date')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.tradeDate }</Col>
                    </Row>
                    { deal.valueDate && 
                        <Row>
                            <Col><strong>{t('fx.blotter.header.value.date')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ deal.valueDate }</Col>
                        </Row>
                    }
                    { deal.optionStartDate && 
                        <Row>
                            <Col><strong>{t('fx.blotter.header.start.date')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ deal.optionStartDate }</Col>
                        </Row>
                    }
                    { deal.endDate && 
                        <Row>
                            <Col><strong>{t('fx.blotter.header.end.date')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ deal.endDate }</Col>
                        </Row>
                    }
                    <Row>
                        <Col><strong>{t('fx.blotter.header.product')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.productDesc }</Col>
                    </Row>
                    <Row>
                        <Col><strong>{t('fx.blotter.header.amount')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ formatAmount(deal.buyAmount) } { deal.buyCurrency }</Col>
                    </Row>
                    { deal.remainingBuyAmount && 
                        <Row>
                            <Col><strong>{t('fx.blotter.header.remaining.buy.amount')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ formatAmount(deal.remainingBuyAmount) } { deal.buyCurrency }</Col>
                        </Row>
                    }
                    <Row>
                        <Col><strong>{t('fx.blotter.header.sell.amount')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ formatAmount(deal.sellAmount) } { deal.sellCurrency }</Col>
                    </Row>
                    { deal.remainingSellAmount && 
                        <Row>
                            <Col><strong>{t('fx.blotter.header.remaining.sell.amount')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ formatAmount(deal.remainingSellAmount) } { deal.sellCurrency }</Col>
                        </Row>
                    }
                    <Row>
                        <Col><strong>{t('fx.blotter.header.dealt.rate')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.dealtRate }</Col>
                    </Row>
                    <Row>
                        <Col><strong>{t('fx.blotter.header.client.name')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.clientName }</Col>
                    </Row>
                    <Row>
                        <Col><strong>{t('fx.blotter.header.user.name')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.userId }</Col>
                    </Row>
                    { deal.productDesc !== "Window Forward" && <>
                        <Row>
                            <Col><strong>{t('fx.blotter.header.buy.account')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ deal.buyAccount }</Col>
                        </Row>
                        <Row>
                            <Col><strong>{t('fx.blotter.header.sell.account')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ deal.sellAccount }</Col>
                        </Row>
                    </>}
                    <Row>
                        <Col><strong>{t('fx.blotter.header.trade.memo')}</strong></Col>
                        <Col className={`${styles['align-right']}`}>{ deal.tradeMemo }</Col>
                    </Row>
                    { deal.wireMemo && 
                        <Row>
                            <Col><strong>{t('fx.blotter.header.wire.memo')}</strong></Col>
                            <Col className={`${styles['align-right']}`}>{ deal.wireMemo }</Col>
                        </Row>
                    }
                </div>
            </div>
        </>
    );

}