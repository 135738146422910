// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wv5Vb3i083Rt5xc_hneA{padding-top:45px}.mwVswqT7k1jynsdQ7X1s{min-height:550px}`, "",{"version":3,"sources":["webpack://./src/components/TradeSettlement/forms/OnePortalRequired.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CAEJ,sBACI,gBAAA","sourcesContent":[".wire-payment-title {\n    padding-top: 45px;\n}\n.container {\n    min-height: 550px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wire-payment-title": `wv5Vb3i083Rt5xc_hneA`,
	"container": `mwVswqT7k1jynsdQ7X1s`
};
export default ___CSS_LOADER_EXPORT___;
