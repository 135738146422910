import React from 'react';

import { ErrorBoundary } from '../common';

import LoginForm from './LoginForm';
import InternalLogin from './InternalLogin';
import { Navigate, useLocation } from 'react-router-dom';

function Login({done, internal}) {
    // magic to deal with old !# links
    const location = useLocation();
    function getLoginType() {
        if (location.hash.indexOf('#/internal') == 0) {
            let searchIndex = location.hash.indexOf('?');
            let search = searchIndex == -1 ? '' : location.hash.substring(searchIndex);
            return (<Navigate to={`/login/internal${search}`} replace/>);
        } else {
            if (internal) {
                return <InternalLogin done={done}/>
            }
            return <LoginForm done={done} />
        }
    }

    return (
        <ErrorBoundary>
            {getLoginType()}
        </ErrorBoundary>
    );
}

export default Login;
