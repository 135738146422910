import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styles from './CardLink.scss';

import { useTranslation } from 'react-i18next';


//images
import FooterImg from '../../assets/footerImage.jpg';
import ForeignCurrencyPaymentsImg from '../../assets/incomingForeignCurrencyWirePaymentstoTDS.jpg';
import Vanderblit from '../../assets/1Vanderbilt_1130_370_284.jpg';
import WiresImg from '../../assets/incomingWirestoTDCanadaTrust.jpg';
import WirePaymentChange from '../../assets/wirePaymentFeeChanging.jpg';

const imagesArr = {FootImg: FooterImg, ForeignCurrencyPaymentsImg: ForeignCurrencyPaymentsImg, Vanderblit: Vanderblit, WiresImg: WiresImg, WirePaymentChange: WirePaymentChange};

export default function CardLink({heading, blurb, href, cardImg}) {
    const {t} = useTranslation();

    return (
        <Card className="text-center">
            <Card.Body>
                { cardImg && 
                    <img className={`${styles["card-image"]}`}
                         src={imagesArr[cardImg]}/>}
                <h3>{heading}</h3>
                { blurb && 
                    <p style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        lineClamp: 4,
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                    }}>{blurb}</p> }
                <Button as="a" className="btn-sm td-btn-secondary-clear" href={href} target="_blank">
                    { t('td.fx.welcome.learn.more') }
                </Button>
            </Card.Body>
        </Card>
    );
}