import React, { useState, Fragment, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import styles from './ValidatingSelect.scss';

function noOp(x) {
    return x;
}

export default function ValidatingSelect({ errorPrefix, hideError, name, onChange, optionList, required = false, setFieldError, value, index,  optionValue = 'value', optionText = 'text', disabled, defaultSelect = true}) {
    const { t } = useTranslation();
    const [error, setError] = useState(false);

    // whenever value changes we need to update our display value and our error
    // this feels like the wrong way to do this?
    // value has already been through parse
    // this validates on creation as well
    useEffect(() => {
        let err = inputValidation(value);
        // console.log(`name: ${name}, index=${index}`);
        if (err != error) {
            setError(err);
            if (index != undefined) {
                const indexedName = name + index;
                setFieldError(indexedName, err);
            } else {
                setFieldError(name, err);
            }
        }
    }, [value, required]);

    function inputValidation(val) {
        val = val || '';
        if (val === '') {
            return required && 'required';
        } else {
            return false;
        }
    }

    function translateError() {
        // error is either a string, or an object
        if (error?.key) {
            return t(errorPrefix + error.key, error);
        } else {
            return error? t(errorPrefix + error) : '';
        }
    }

    return (
        <Fragment>
            <Form.Group>
                <Form.Control as="select"
                              id={name}
                              disabled={disabled}
                              isInvalid={!hideError && error}
                              name={name}
                              onChange={(e) => onChange(e)}
                              value={value || ''}
                              className={`form-select ${styles["form-select-override"]}`}
                              >
                    {(defaultSelect === true) ?<option value="">{t('td.fx.tradeSettlement.select')}</option> : <></>}
                    { optionList && optionList.length > 0 ? optionList.map((option, index) => <option key={index} value={option[optionValue]}>{option[optionText]}</option> ) : null}
                </Form.Control>
                <Form.Control.Feedback hidden={hideError || !error} type="invalid"className="td-error"><span className="td-error-symbol" aria-hidden="true"></span>{translateError()}</Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    );
}