import { useEffect } from "react";
import utilities from '../../utils';

const { INVALID_INVERSE_QUOTE_CURRENCIES } = utilities.constants;
const USDCAD = ['USD', 'CAD'];

// keep the account the same if it's in the new list
// otherwise pick the first account, unless it's a wire account
export function fixAccountSelection(currentValue, list) {
    if (!currentValue || !list.includes(currentValue)) {
        if (list[0] && !list[0].wire) {
            return list[0];
        } else {
            return null;
        }
    }
    return currentValue;
};

function updateAccount(model, key, newAC) {
    let didUpdate = false;
    if (newAC !== model[key]) {
        model[key] = newAC;
        didUpdate = true;
    }
    return didUpdate;
}


export function useAccounts({model, company, updateModel, isSwap}) {
    // for far leg buy we use sell currency, but still need buy accounts
    function testAndUpdateAccount(key, buy, far) {
        const accountsKey = buy ? 'buyAccounts' : 'sellAccounts';
        const currencyKey = (buy ? !far : far)? 'buyCurrency' : 'sellCurrency';
        if (!model[key]) {
            let newAC = fixAccountSelection(model[key], company[accountsKey].filter(a => a.ccy == model[currencyKey]));
            if (updateAccount(model, key, newAC)) {
                updateModel({...model});
            }
        }
    }
    

    // separate buy/sell currency because they don't change at the same time
    useEffect (() => {
        if (isSwap) {
            // duplication with sellCurrency?
            let newNearAC = fixAccountSelection(model.nearBuyAccount, company.buyAccounts.filter(a => a.ccy == model.buyCurrency));
            let changedNear = updateAccount(model, 'nearBuyAccount', newNearAC);
            let newFarAC = fixAccountSelection(model.farSellAccount, company.sellAccounts.filter(a => a.ccy == model.buyCurrency));
            let changedFar = updateAccount(model, 'farSellAccount', newFarAC);
            if (changedNear || changedFar) {
                updateModel({...model});
            }
        } else {
            let newAC = fixAccountSelection(model.buyAccount, company.buyAccounts.filter(a => a.ccy == model.buyCurrency));
            if (updateAccount(model, 'buyAccount', newAC)) {
                updateModel({...model});
            }
        }
    }, [company, model.buyCurrency]);


    useEffect(() => {
        if (isSwap) {
            let newNearAC = fixAccountSelection(model.nearSellAccount, company.sellAccounts.filter(a => a.ccy == model.sellCurrency));
            let changedNear = updateAccount(model, 'nearSellAccount', newNearAC);
            let newFarAC = fixAccountSelection(model.farBuyAccount, company.buyAccounts.filter(a => a.ccy == model.sellCurrency));
            let changedFar = updateAccount(model, 'farBuyAccount', newFarAC);
            if (changedNear || changedFar) {
                updateModel({...model});
            }
        } else {
            let newAC = fixAccountSelection(model.sellAccount, company.sellAccounts.filter(a => a.ccy == model.sellCurrency));
            if (updateAccount(model, 'sellAccount', newAC)) {
                updateModel({...model});
            }
        }
    }, [company, model.sellCurrency]);


    // note that swap and !swap use different elements for accounts, so safe to have all these functions.
    // remember also that swap only has one currency pair - the currencies for the far leg are reversed
    // note also that hooks must not be inside conditionals.
    useEffect(() => {
        if (!isSwap) {
            testAndUpdateAccount('buyAccount', true);
        }
    }, [model.buyAccount]);

    useEffect(() => {
        if (!isSwap) {
            testAndUpdateAccount('sellAccount', false);
        }
    }, [model.sellAccount]);

    // swap
    useEffect(() => {
        if (isSwap) {
            testAndUpdateAccount('nearBuyAccount', true, false);
        }
    }, [model.nearBuyAccount]);

    useEffect(() => {
        if (isSwap) {
            testAndUpdateAccount('nearSellAccount', false, false);
        }
    }, [model.nearSellAccount]);

    useEffect(() => {
        if (isSwap) {
            testAndUpdateAccount('farBuyAccount', true, true);
        }
    }, [model.farBuyAccount]);

    useEffect(() => {
        if (isSwap) {
            testAndUpdateAccount('farSellAccount', false, true);
        }
    }, [model.farSellAccount]);

    function handleCurrencyChange(e) {
        // update the model
        model[e.target.name] = e.target.value;
        // check and see if we also need to fix something that currency changes
        model.inverseQuote = !(USDCAD.includes(model.buyCurrency) && USDCAD.includes(model.sellCurrency)) && !(INVALID_INVERSE_QUOTE_CURRENCIES.includes(model.buyCurrency) || INVALID_INVERSE_QUOTE_CURRENCIES.includes(model.sellCurrency));
        updateModel({...model});
    }


    // we want to set the account not just the ssid - or maybe we don't?
    // assumes ssid's are unique across currencies - which they are
    function handleAccountChange(name, ssid, accounts) {
        const account = accounts.filter(acc => acc.ssid == ssid)[0];
        model[name] = account;
        updateModel({...model});
    };

    // FIXME - this is starting to seem too big 
    // also might be cleaner to return object with named values vs positional array
    return [handleCurrencyChange, handleAccountChange, INVALID_INVERSE_QUOTE_CURRENCIES.includes(model.buyCurrency) || INVALID_INVERSE_QUOTE_CURRENCIES.includes(model.sellCurrency)];
}

export function getCurrencies(accounts) {
    const temp = [...new Set(accounts.map(a => a.ccy))].sort();
    const options = temp.map(ccy => ( { text: ccy, value: ccy } ));
    return options;
}

// do any of our accounts for this currency have a wire settlement?
// needed to decide if we show wire memo for trade only users
export function hasWire(accounts, currency) {
    let wire = accounts?.some(account => (account.ccy == currency && account.wire));
    return wire;
}
