// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.irEcADbGo6yPz9dDM4iQ{width:285px;height:auto;margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/components/Welcome/CardLink.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":[".card-image {\n    width: 285px;\n    height: auto;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-image": `irEcADbGo6yPz9dDM4iQ`
};
export default ___CSS_LOADER_EXPORT___;
