
const CONFIRMATION_WAIT_MINUTES = 5;
import i18next from '../i18n';

function confirmationService() {
    this.allow = function(data) {
        if (!data) {
            return false;
        }
        // FIXME - add this complexity
        // note that the old function returned a style, which seems like an ugly way to do it
        /*
        var shouldShowConfirmation = !userService.isCorporateInstitutional();
        var isSettledOrFO = (data.product && data.product.startsWith('FO')) || data.status == 'OK';
        var minutesSinceConfirmation = Math.round((Date.now() - new Date(data.timestamp).getTime()) / 1000 / 60 );
        return '' + ((minutesSinceConfirmation < constants.showDealConfirmationPdfAfter || !isSettledOrFO || !shouldShowConfirmation) ? 'hidden' : 'btn-lg confirmation-icon');
        */

        var minutesSinceConfirmation = Math.round((Date.now() - new Date(data.timestamp).getTime()) / 1000 / 60 );
        return (minutesSinceConfirmation >= CONFIRMATION_WAIT_MINUTES);
    };

    this.open = function (data) {
        if (!data) {
            return;
        }
        var minutesSinceConfirmation = Math.round((Date.now() - new Date(data.timestamp).getTime()) / 1000 / 60 );
        if (minutesSinceConfirmation >= CONFIRMATION_WAIT_MINUTES) {
           
            var form = window.document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', 'deal/confirmation');

            form.setAttribute('target', '_blank');

            var addField = function (name, value) {
                var hiddenField = window.document.createElement('input'); 
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', name);
                hiddenField.setAttribute('value', value);
                form.appendChild(hiddenField);
            };

            addField('dealId', data.dealId);
            addField('client', data.clientId);

            let locale = i18next.language == 'fr' ? 'fr' : 'en';
            addField('locale', locale);
            let product = data.product.substring(0, 2);
            addField('product', product);
            if (product == 'DR') {
                addField('parent', data.parent);
            }
            
            document.body.appendChild(form);
            form.submit();
            form.parentNode.removeChild(form);
            
        }
    };
}

export const Service = new confirmationService();

