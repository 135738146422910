import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { TradeBlotter, MobileTradeView } from '../common';

export default function Reports(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 64em)' });

    function getView() {
        if (isTabletOrMobile) {
            return <MobileTradeView search={true}/>;
        } else {
            return (
                <>
                   <TradeBlotter/>
                </>
            );
        }
    }

    return (
        <div className="main-container">
            { getView() }
        </div>
    )
}
