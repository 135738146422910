function KeepAliveService() {
    this.lastTouch = new Date();

    // we only expect one subscription
    // FIXME - we should probably use observer mixin here
    let listener;
    this.subscribe = function (cb) {
        listener = cb;
    };

    // FIXME - why would anyone pass in a callback...
    this.touch = function (cb) {
        // debounce this? Currently we only call this function on major events - but it would
        // be better to let this service handle those decisions
        // but we also don't want to assume everything is ok - something might happen to 
        // kill the session on the server, and we should catch that as soon as possible
        const self = this;
        let err = false;
        fetch('/tdfx/api/keepAlive', { method: 'POST', body: { keepAlive: true } })
            .then((res) => {
                if (res && res.ok) {
                    self.lastTouch = new Date();
                } else {
                    // we only care about access errors
                    if (res.status === 401 || res.status === 403) {
                        err = { status: res.status };
                    }
                }
                if (cb) {
                    cb(err);
                }
                if (err && listener) {
                    // session is dead on the server, but we still have a user object
                    // listener should deal with this
                    // make sure we kill out timer
                    self.stop();
                    listener({ logout: true, reason: 'EXPIRED' });
                }
            })
            .catch((error) => {
                // couldn't reach the server.
                // don't die, but don't touch either
                console.log(error);
                if (cb) {
                    cb();
                }
            });
    };

    // intial values
    // server timeout in ms
    // it may take some time to send the touch request, so we need to fail early
    // 30 minutes less 15 seconds
    this.BEST_PRACTICE_TIMEOUT = 30 * 60 * 1000 - 1500;
    this.TESTING_TIMEOUT = 30 * 1000;
    
    this.TIMEOUT = this.BEST_PRACTICE_TIMEOUT;
    // this.TIMEOUT = this.TESTING_TIMEOUT;
    this.MOST_OF_TIMEOUT = 5 * (this.TIMEOUT / 6);

    // update timeout values to match what's set on the server
    this.setSessionTimeout = function (timeoutSeconds) {
        if (timeoutSeconds) {
            this.TIMEOUT = timeoutSeconds * 1000;
            this.MOST_OF_TIMEOUT = 5 * (this.TIMEOUT / 6);
        }
    };

    function noOp() {
        // nope
    }

    this.watchTimeout = function (warning = noOp, expired = noOp, timeoutSeconds) {
        const self = this;
        this.setSessionTimeout(timeoutSeconds);
        const doWatchTimeout = function () {
            const now = new Date();
            const delta = now.getTime() - self.lastTouch.getTime();
            if (delta > self.TIMEOUT) {
                expired();
            } else if (delta > self.MOST_OF_TIMEOUT) {
                warning();
                self.timeoutTimer = setTimeout(doWatchTimeout, self.TIMEOUT - delta);
            } else {
                self.timeoutTimer = setTimeout(doWatchTimeout, self.MOST_OF_TIMEOUT - delta);
            }
        };

        this.timeoutTimer = setTimeout(doWatchTimeout, this.MOST_OF_TIMEOUT);

        // now that we care about timing out, turn on the keep alive calls
        this.enabled = true;
    };

    this.stop = function () {
        if (this.timeoutTimer) {
            clearTimeout(this.timeoutTimer);
        }
    };
}

const KeepAlive = new KeepAliveService();
export { KeepAlive };
