import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';

// FIXME file name uses different spelling
export default function TradableCurrencies() {
    const { t, i18n } = useTranslation();

    return (
        <Trans i18nKey="userMessagesUnread">
            <div>
                <Trans i18nKey="td.fx.support.tradableCurrencies.title" 
                >
                    <h4>a</h4><p><a href="mailto:tdfx@tdsecurities.com">e</a>f</p>
                </Trans>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>{t('td.fx.currency.title.currencyCode')}</th>
                            <th>{t('td.fx.currency.title.currencyName')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.AED')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.AED')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.AUD')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.AUD')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.CAD')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.CAD')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.CHF')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.CHF')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.CNH')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.CNH')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.CNY')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.CNY')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.CZK')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.CZK')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.DKK')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.DKK')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.EUR')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.EUR')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.GBP')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.GBP')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.HKD')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.HKD')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.HUF')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.HUF')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.ILS')}
                                </td>
                            <td>
                                {t('td.fx.currency.name.ILS')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.INR')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.INR')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.JPY')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.JPY')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.MXN')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.MXN')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.NOK')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.NOK')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.NZD')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.NZD')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.PLN')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.PLN')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.SAR')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.SAR')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.SEK')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.SEK')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.SGD')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.SGD')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.THB')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.THB')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.TRY')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.TRY')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.USD')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.USD')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('td.fx.currency.code.ZAR')}
                            </td>
                            <td>
                                {t('td.fx.currency.name.ZAR')}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Trans>
    );
}