import { useMemo } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getValueDateTypes, getEndDateTenors, getMinEndDate, getMaxEndDate, getDateFromTenor, getMinDate } from './valueDateHelper';
import { AmountInput, ValueDateInput } from '../input';
import { useTranslation } from 'react-i18next';
import { addBusinessDays, isAfter } from 'date-fns';
import { useForm } from '../../utils/useForm';
import { getCurrencies, useAccounts } from './useAccounts';
import { useModel } from './useModel';
import styles from './Spot.scss';
import TradeMemoInput from '../input/TradeMemoInput';
import { User } from '../../services/userService';
import { Hours } from '../../services/hoursService';



function checkDates(data) {
    // confirm end is after start - though this should be enforced by the GUI already
    let startDate = data.optionStartDateType ? 
        getDateFromTenor(data.optionStartDateType, data.buyCurrency, data.sellCurrency) : 
        data.optionStartDateDate;

    let endDate = data.optionEndDateType ? 
        getDateFromTenor(data.optionEndDateType, data.buyCurrency, data.sellCurrency) : 
        data.optionEndDateDate;

    if (endDate <= startDate) {
        return 'fo.error.start.end';
    }

    // if start date <= 1yr in the future
    // then window size <= 120 days
    // else if start date >= 1yr
    // then window size <= 31 days
    let today = Hours.getServerDate();
    let oneYear = new Date(today.getTime());
    oneYear.setFullYear(oneYear.getFullYear() + 1);

    let startDatePlus31 = new Date(startDate.getTime());
    startDatePlus31.setDate(startDate.getDate() + 31);

    let startDatePlus120 = new Date(startDate.getTime());
    startDatePlus120.setDate(startDatePlus120.getDate() + 120);

    let isStartOneYear = startDate > oneYear;

    if (isStartOneYear) {
        if (startDatePlus31 < endDate) {
            return 'fo.error.far.window.too.big';
        }
    } else {
        if (startDatePlus120 < endDate) {
            return 'fo.error.window.too.big';
        }
    }
    return false;
}

function WindowForward({ model, setModel, company, setError }) {
    const { t } = useTranslation();
    const {handleChange, handleConnectedChange} = useModel(model, updateModel);
    const {fieldErrors: errors, setFieldError} = useForm(validate);
    // FIXME - this smells
    const [handleCurrencyChange, unused, inverseQuoteNotAvailable] = useAccounts({company, model, updateModel});

    // FIXME - should we memo these too?
    const startDateData = getValueDateTypes('TDY', false, model.buyCurrency, model.sellCurrency, false, company);
    const startTenors = startDateData.types.map(tenor => {
        return { text: tenor, value: tenor };
    });

    const MIN_DATE = useMemo(() => getMinDate(1));

    const minEndDate = useMemo(() => getMinEndDate(model, model.optionStartDateDate, model.optionEndDateType), [model]);
    const maxEndDate = useMemo(() => getMaxEndDate(), []);

    function validate(fields) {
        fields = fields || errors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError('error.fields');
        } else {
            // check for specific window forward issues
            if (model.buyCurrency == model.sellCurrency) {
                setError('error.cur.buySell');
            } else if (!(model.buyAmount || model.sellAmount)) {
                setError('error.no.amount');
            } else if (!model.optionStartDateType && !model.optionStartDateDate) {
                setError('fo.error.missing.start.date');
            } else if (!model.optionEndDateType && !model.optionEndDateDate) {
                setError('fo.error.missing.end.date');
            } else {
                let dateError = checkDates(model);
                setError(dateError);
            }
        }
    }


    function updateModel(m) {
        setModel(m);
        validate();
    }

    return (
        <>
            <div className={'rfq-container'}>
                <div>
                    <Row className="g-2">
                        <Col xs={4} sm={4} md={5}>
                            <Form.Group className={`${styles["dropdown-min"]}`}>
                                <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.buys')}</Form.Label>
                                <Form.Select 
                                    className={'form-control'}
                                    id="buyCurrency"
                                    name="buyCurrency" 
                                    value={model.buyCurrency}
                                    onChange={handleCurrencyChange}
                                >
                                { getCurrencies(company.buyAccounts).map(ccy => 
                                    <option key={ccy.value} value={ccy.value}>{ccy.text}</option>
                                )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={8} sm={8} md={7}>
                            <Form.Group>
                                <AmountInput 
                                    minValue={1}
                                    name={'buyAmount'} 
                                    value={model.buyAmount} 
                                    update={(val) => handleConnectedChange('buyAmount', val, 'sellAmount')} 
                                    setFieldError={setFieldError} 
                                    errorPrefix={'td.validation.'}
                                    className={styles.nolabel}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className="g-2">
                        <Col xs={4} sm={4} md={5}>
                            <Form.Group className={`${styles["dropdown-min"]}`}>
                                <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.sells')}</Form.Label>
                                <Form.Select 
                                    className={'form-control'}
                                    id="sellCurrency"
                                    name="sellCurrency" 
                                    value={model.sellCurrency}
                                    onChange={handleCurrencyChange}
                                >
                                { getCurrencies(company.sellAccounts).map(ccy => 
                                    <option key={ccy.value} value={ccy.value}>{ccy.text}</option>
                                )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={8} sm={8} md={7}>
                            <Form.Group>
                                <AmountInput 
                                    minValue={1}
                                    name={'sellAmount'} 
                                    value={model.sellAmount} 
                                    update={(val) => handleConnectedChange('sellAmount', val, 'buyAmount')} 
                                    setFieldError={setFieldError} 
                                    errorPrefix={'td.validation.'}
                                    className={styles.nolabel}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className="g-2">
                        <Col xs={4} sm={4} md={5}>
                            <Form.Group>
                                <Form.Label className="no-wrap">{t('td.fx.ticket.drawdown.start.date')}</Form.Label>
                                <Form.Select 
                                    className={'form-control'}
                                    id="optionStartDateType"
                                    name="optionStartDateType" 
                                    value={model.optionStartDateType || ''}
                                    placeholder={''}
                                    onChange={(e) => handleConnectedChange('optionStartDateType', e.target.value, 'optionStartDateDate')}
                                >
                                    <option></option>
                                { startTenors.map(tenor => 
                                    <option key={tenor.value} value={tenor.value}>{t(tenor.text)}</option>
                                )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={8} sm={8} md={7}>
                            <Form.Group>
                                <ValueDateInput 
                                    className={styles.nolabel}
                                    min={MIN_DATE}
                                    max={maxEndDate}
                                    name={'optionStartDateDate'} 
                                    cur1={model.buyCurrency}
                                    cur2={model.sellCurrency}
                                    value={model.optionStartDateDate || ''}
                                    update={(date) => handleConnectedChange('optionStartDateDate', date, 'optionStartDateType')}
                                    setFieldError={setFieldError} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className="g-2">
                        <Col xs={4} sm={4} md={5}>
                            <Form.Group>
                                <Form.Label className="no-wrap">{t('td.fx.ticket.drawdown.end.date')}</Form.Label>
                                <Form.Select 
                                    className={'form-control'}
                                    id="optionEndDateType" 
                                    name="optionEndDateType" 
                                    value={model.optionEndDateType || ''}
                                    placeholder={''}
                                    onChange={(e) => handleConnectedChange('optionEndDateType', e.target.value, 'optionEndDateDate')}
                                >
                                    <option></option>
                                { getEndDateTenors(model, company, 'optionStartDate').map(tenor => 
                                    <option key={tenor.value} value={tenor.value}>{t(tenor.text)}</option>
                                )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={8} sm={8} md={7}>
                            <Form.Group>
                                <ValueDateInput 
                                    className={styles.nolabel}
                                    min={minEndDate}
                                    max={maxEndDate}
                                    name={'optionEndDateDate'} 
                                    cur1={model.buyCurrency}
                                    cur2={model.sellCurrency}
                                    value={model.optionEndDateDate || ''}
                                    update={(date) => handleConnectedChange('optionEndDateDate', date, 'optionEndDateType')}
                                    setFieldError={setFieldError} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div>
                <Form.Group>
                    <Form.Label>{t('td.fx.ticket.lblMemo')}</Form.Label>
                    <TradeMemoInput
                            name={'tradeMemo'} 
                            value={model.tradeMemo || ''}
                            placeholder={t('td.fx.ticket.memo.prompt')}
                            update={(val) => handleChange(val, 'tradeMemo')}
                            setFieldError={setFieldError} 
                            errorPrefix={'td.validation.'}
                    />
                </Form.Group>
                </div>
            </div>
            <Row key={2}>
                <Form.Group className="col col-sm-12" style={{ margin: '1em 0'}}>
                    <Form.Check 
                        id="inverseQuote"
                        name="inverseQuote"
                        checked={!!model.inverseQuote}
                        onChange={(e) => handleChange({target: {name: e.target.name, value: e.target.checked}})}
                        label={t('td.fx.ticket.chbxInverseQuote')}
                        disabled={inverseQuoteNotAvailable}
                    />
                </Form.Group>
            </Row>
        </>
    )
}

export default WindowForward;