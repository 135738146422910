import React, { useState, Fragment, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';

function noOp(x) {
    return x;
}

// parse - takes input value and converts as requried
// format - takes internal value and format
// validate - works on internal value

// valid should return 'error_key' for invalid fields, and a falsey value for valid values
// error will be display on the field using errorPrefix + error_key
export default function ValidatingInput({name, value, update, inputValidation, format, parse, setFieldError, errorPrefix, className, blur, placeholder, maxLength, hideError, password, disabled}) {
    inputValidation = inputValidation || noOp;
    format = format || noOp;
    parse = parse || noOp;

    const { t } = useTranslation();


    const [error, setError] = useState(false);

    // controls how we format the incoming value
    const [focus, setFocus] = useState(false);

    // whenever value changes we need to update our display value and our error
    // this feels like the wrong way to do this?
    // value has already been through parse
    // this validates on creation as well
    useEffect(() => {
        let err = inputValidation(value);
        if (err != error) {
            setError(err);
            setFieldError(name, err);
        }
    }, [value, inputValidation]);

    const displayValue = error ? value : (focus? value: format(value));


    // on change we check validity
    // but we can't update our parent onChange, because we lose focus
    function onChange(val) {
        let parsedValue = parse(val);

        let err = inputValidation(parsedValue);
        setError(err);
        setFieldError(name, err);
        if (err) {
            update(val);
        } else {
            // when we update we get re-rendered, 
            // so formatting of the display value will occur.
            // we may not want that?
            // for amount we want to convert M, K etc. 
            // and strip extra commas
            update(parsedValue);
        }
    }

    // just update display value
    function onBlur(val) {
        blur && blur();
        setFocus(false);
    }

    function onFocus() {
        setFocus(true);
    }

    function translateError() {
        // error is either a string, or an object
        if (error?.key) {
            return t(errorPrefix + error.key, error);
        } else {
            return error? t(errorPrefix + error) : '';
        }
    }

    // FIXME - we may need to translate the error with params
    // probably means we need to pass in a method to translate?
    return (
        <Fragment>
            <Form.Group className={className}>
                <Form.Control isInvalid={!hideError && error}
                    disabled={disabled}
                    id={name}
                    name={name}
                    value={displayValue || ''}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={(e) => onBlur(e.target.value)}
                    onFocus={(e) => onFocus()}
                    placeholder={placeholder ? t(placeholder) : null}
                    maxLength={maxLength}
                    type={password ? 'password': 'text'}
                />
                <Form.Control.Feedback hidden={hideError || !error} type="invalid" className="td-error"><span className="td-error-symbol" aria-hidden="true"></span>{translateError()}</Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    );
}
