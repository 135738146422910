// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TPIz6U0hDnSgRplVtPRa{display:block !important;cursor:pointer}.TPIz6U0hDnSgRplVtPRa i.icon{color:#1b2829}.t97CoGS_JZSmbz3vGznQ{display:inline-block;position:relative;content:"";margin-left:5px;width:20px;height:26px;background-repeat:no-repeat;background-position:50% 50%;cursor:pointer}.nn3eDXLpKn23lRXQFmoj{display:inline-block;position:relative;content:"";margin-left:5px;width:20px;height:26px;background-repeat:no-repeat;background-position:50% 50%;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/common/Expander/Expander.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CAIA,cAAA,CAHA,6BACI,aAAA,CAOR,sBACI,oBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,2BAAA,CACA,2BAAA,CACA,cAAA,CAEJ,sBACI,oBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,2BAAA,CACA,2BAAA,CACA,cAAA","sourcesContent":[".Expander {\n    display: block !important;\n    :global i.icon {\n        color: #1b2829;\n    }\n    cursor: pointer;\n}\n\n// add settlement open/closed too\n\n.ExpanderOpen {\n    display: inline-block;\n    position: relative;\n    content: \"\";\n    margin-left: 5px;\n    width: 20px;\n    height: 26px;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n    cursor: pointer; \n}\n.ExpanderClosed {\n    display: inline-block;\n    position: relative;\n    content: \"\";\n    margin-left: 5px;\n    width: 20px;\n    height: 26px;\n    background-repeat: no-repeat;\n    background-position: 50% 50%;\n    cursor: pointer; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Expander": `TPIz6U0hDnSgRplVtPRa`,
	"ExpanderOpen": `t97CoGS_JZSmbz3vGznQ`,
	"ExpanderClosed": `nn3eDXLpKn23lRXQFmoj`
};
export default ___CSS_LOADER_EXPORT___;
