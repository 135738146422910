import i18next from '../../i18n';


// FIXME - not sure there's value in having this in a separate file?


function getProductTypes(permissions) {
    const products = [];
    let settleOnly = permissions.SETTLE && !permissions.TRADE;

    if (settleOnly || permissions.TRADE) {
        if (settleOnly || permissions.SPOT) {
            if (settleOnly || permissions.FWD) {
                products.push({ text: i18next.t('td.panel.label.spotforward'), value: 'FWD' });
            } else {
                products.push({ text: i18next.t('td.panel.label.spot'), value: 'FWD' });
            }
        }
        if (settleOnly || permissions.FWDOPT) {
            products.push({ text: i18next.t('td.panel.label.forwardOption'), value: 'FWDOPT' });
        }
        if (settleOnly || permissions.SWAP) {
            products.push({ text: i18next.t('td.panel.label.swap'), value: 'SWAP' });
        }
    }
    return products;
}

function getProductDefault() {
    return 'FWD';
}

export default {
    getProductTypes,
    getProductDefault
};