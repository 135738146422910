import React from 'react';

import utilities from '../../utils/';
const filters = utilities.filters; 
const { numericSort  }= utilities.sort;


import FunctionalButtons from './FunctionalButtons';

function formatAmount({value}) {
    return utilities.format.formatAmount(value);
}

function formatRate({value}) {
    return utilities.format.formatRate(value);
}

const COL_WIDTH = {
    XS: 30,
    SM: 75,
    MD: 150,
    LG: 200,
    XL: 350
};

const defaultDef = {
    width: COL_WIDTH.XS,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter', 
    floatingFilterComponentParams: {suppressFilterButton: true}, 
    floatingFilter: true, 
    suppressMovable: true, 
    suppressMenu: true,
    wrapHeaderText: true,
    autoHeaderHeight: true
};


function getColumns(t, type) {
    const columns = [        
    {
        width: 120,
        filter: false,
        resizable: false,
        cellRenderer: (props) => <FunctionalButtons {...props} />
    },{
        headerName: 'fx.blotter.header.deal.id',
        field: 'dealId',
        width: 160,
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        field: 'creationTime',
        hide: true,
        resizable: false,
        // filterable: false,
        sort: 'desc'
    }, {
        headerName: 'fx.blotter.header.status',
        field: 'status',
        resizable: false,
        width: 80,
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.defaultFilter
    }, {
        headerName: 'fx.blotter.header.trade.date',
        field: 'tradeDate',
        resizable: false,
        width: 120,
        cellDataType: 'dateString',
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.value.date',
        field: 'valueDate',
        resizable: false,
        hide: type === 'FO',
        width: 120,
        cellDataType: 'dateString',
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.start.date',
        field: 'optionStartDate',
        resizable: false,
        hide: type === 'ALL',
        width: 120,
        cellDataType: 'dateString',
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.end.date',
        field: 'endDate',
        hide: type === 'ALL',
        resizable: false,
        width: 105,
        cellDataType: 'dateString',
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.product',
        field: 'productDesc', 
        resizable: false,
        width: 120,
        cellStyle: {textOverflow: 'ellipsis', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.buy.currency',
        field: 'buyCurrency',
        resizable: false,
        width: 95,
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.defaultFilter
    }, {
        headerName: 'fx.blotter.header.amount',
        field: 'buyAmount',
        width: 150,
        cellDataType: 'number',
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // filterMethod: filters.numericFilter,
        // sortMethod: numericSort,
        valueFormatter: formatAmount
    }, {
        headerName: 'fx.blotter.header.remaining.buy.amount',
        field: 'remainingBuyAmount',
        hide: type === 'ALL',
        width: 150,
        cellDataType: 'number',
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // filterMethod: filters.numericFilter,
        // sortMethod: numericSort,
        valueFormatter: formatAmount
    }, {
        headerName: 'fx.blotter.header.sell.currency',
        field: 'sellCurrency',
        resizable: false,
        width: 105,
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.defaultFilter
    }, {
        headerName: 'fx.blotter.header.sell.amount',
        field: 'sellAmount',
        width: 150,
        cellDataType: 'number',
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // filterMethod: filters.numericFilter,
        // sortMethod: numericSort,
        valueFormatter: formatAmount
    }, {
        headerName: 'fx.blotter.header.remaining.sell.amount',
        field: 'remainingSellAmount',
        hide: type === 'ALL',
        width: 150,
        cellDataType: 'number',
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // filterMethod: filters.numericFilter,
        // sortMethod: numericSort,
        valueFormatter: formatAmount
    }, {
        headerName: 'fx.blotter.header.dealt.rate',
        field: 'dealtRate',
        width: 95,
        cellDataType: 'number',
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // filterMethod: filters.numericFilter,
        // sortMethod: numericSort
        valueFormatter: formatRate

    }, {
        headerName: 'fx.blotter.header.client.name',
        field: 'clientId',
        width: 95,
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.user.name',
        field: 'userId',
        width: 95,
        cellStyle: {display: 'flex', justifyContent: 'center'},
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.buy.account',
        field: 'buyAccount',
        width: 120,
        // filterMethod: filters.includesFilter
    },  {
        headerName: 'fx.blotter.header.sell.account',
        field: 'sellAccount',
        width: 120,
        // filterMethod: filters.includesFilter
    }, {
        headerName: 'fx.blotter.header.trade.memo',
        field: 'tradeMemo',
        width: 120,
        // filterMethod: filters.includesFilter
    },
    {
        headerName: 'fx.blotter.header.wire.memo',
        field: 'wireMemo',
        width: 120,
        // filterMethod: filters.includesFilter
    },
    ];

    for (let index = 0; index < columns.length; index++) {
        // columns[index].id = index;
        columns[index].headerName = t(columns[index].headerName);

        columns[index].tooltipField = columns[index].field;
        for (const key in defaultDef) {
            // because the defaults can be false...
            if (columns[index][key] === undefined) {
                columns[index][key] = defaultDef[key];
            }

            //set numerical filter for numerical fields
            if (columns[index]["cellDataType"] === "number") {

                columns[index]["filterParams"] = {

                    // Override the existing textMatching behaviour for numeric values.
                    // All of our numeric values in the grids are currency values.
                    textMatcher: ({ filterOption, value, filterText }) => {

                        if (value.length > 0 && value.indexOf('.') === -1) {
                            // the numeric values come in without .00 if it is a whole number so we'll manually append it for the text matching function
                            // i.e. entering "12.00" in the filter will now match row values with just "12"
                            value = value + ".00"; 
                        }

                        if (filterText == null) {
                            return false;
                        }

                        switch (filterOption) {
                            case 'contains': //this is the default filterOption, so place changes in contains
                                const index2 = value.lastIndexOf(filterText);
                                return value.indexOf(filterText) === 0 || (index2 >= 0 && index2 === (value.length - filterText.length)); //update this so that we return startWith all the time by default
                            case 'notContains':
                                return value.indexOf(filterText) < 0; //default behaviour
                            case 'equals':
                                return value === filterText; //default behaviour
                            case 'notEqual':
                                return value != filterText; //default behaviour
                            case 'startsWith':
                                return value.indexOf(filterText) === 0; //default behaviour
                            case 'endsWith':
                                const index = value.lastIndexOf(filterText); //default behaviour
                                return index >= 0 && index === (value.length - filterText.length);
                            default:
                                // should never happen
                                console.warn('invalid filter type ' + filter);
                                return false;
                        }
                    }
                };
            }

        }
    }

    return columns;
};

export default getColumns;