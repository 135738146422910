import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import english from "../src/assets/text/tdfx-en.json";
import englishUS from "../src/assets/text/tdfx-en_US.json";
import french from "../src/assets/text/tdfx-fr.json";

const SUPPORTED_LANGUAGES = ["en_CA","en_US", "fr"];
const href = window.location.href;
const language = href.includes('locale') ? href.split('?locale=')[1] : localStorage.getItem('locale');

const resources = {
  en_CA: {
    translation: english,
  },
  en_US: {
    translation: englishUS,
  },
  fr: {
    translation: french,
  }
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: SUPPORTED_LANGUAGES,
    lng: SUPPORTED_LANGUAGES.includes(language) ? language : 'en_CA',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources
  });

export default i18n;
