import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import WirePayment from './forms/wirePayment';
import History from './forms/history';
import OnePortalRequired from './forms/onePortalRequired';
import styles from './TradeSettlement.scss';;
import { OnePortal } from '../../services/onePortalService';

export default function TradeSettlement() {
    const { t } = useTranslation();

    const blocked = OnePortal.shouldOpen();
    

    return (
        <div className="main-container">
            <Tabs defaultActiveKey="wire">
                <Tab eventKey="wire" title={t('td.fx.tradeSettlement.paymentInstruction')} tabClassName={`${styles["tab-nav"]}`}>
                    {blocked ? <OnePortalRequired/> : <WirePayment/>}
                </Tab>
                <Tab eventKey="history" title={t('td.fx.tradeSettlement.bankAccounts')} tabClassName={`${styles["tab-nav"]}`}>
                {blocked ? <OnePortalRequired/> : <History/>}
                </Tab>                
            </Tabs>
            
            
        </div>
    )
}