import React, { useState, Fragment, useEffect } from 'react';

import ValidatingInput from './ValidatingInput';



/*
"td.validation.maxLength" : "The maximum length is {{max}} characters",
"td.validation.minLength" : "The minimum length is {{min}} characters",
"td.validation.pattern" : "Invalid characters",
*/

// current behaviour is to show the accent in the field, but strip it off internally. For now, don't do that
// new lines are filtered out as we type
export default function FilteringInput({filterAccents, filterNewLine = true, regex, required = false, maxLength,  ...props}) {
    function validate(val) {
        val = val || '';
        // if the amount is '', then it might be valid
        if (val === '') {
            return required && 'required';
        }

        // we have a max size
        if (val?.length > maxLength) {
            return {key: 'maxLength', value: {max: maxLength}};
        }
    
    
        // check the regex
        // regex is for valid strings only
        // if we don't match, then there's a problem
        let match = val.match(regex);
        let error = false;
        if (!match) {
            error = 'pattern';
        }
        if (!error) {
            error = props.validate ? props.validate(val) : error;
        }
        
        return error;
    }

    // we may need to expose some of these?
    function stripAccents(input) {
        // extract this?
        var inChrs  = 'àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ';
        var outChrs = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY'; 
        var charsRgx = new RegExp('[' + inChrs + ']', 'g');
        var translationMap = {};
        var lookup   = function (m) { 
            return translationMap[m] || m; 
        };

        // populate the translation map  
        for (var i = 0; i < inChrs.length; i++) {
            translationMap[inChrs[i]] = outChrs[i];
        }
        return input.replace(charsRgx, lookup);
    }


    function parseLatinValue(inputValue) {
        if (typeof inputValue != 'string' || inputValue.length < 1) {
            return inputValue;
        }
        // convert all accented characters to their non-accented equivalent
        var retVal = stripAccents(inputValue);
        return retVal;
    }

    function parseNewLinechars(inputValue) {
        if (typeof inputValue != 'string' || inputValue.length < 1) {
            return inputValue;
        }
        // Replace all new line chars with spaces.
        return inputValue.replace(/(\r\n|\n|\r)/gm, " ");
    }
    
    function parse(input) {
        let output = parseLatinValue(parseNewLinechars(input));
        return output;
    }

    return (
        <ValidatingInput 
            inputValidation={validate} 
            parse={parse}
            maxLength={maxLength}
            {...props}
        />
    );
}

