// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrclfMB0haPJvdIc2L2Q{padding-left:400px;padding-right:400px}ul .gZh1VGWoee74chYxZlUV .QsTtn61Svats2Aw86jKV{padding-left:300px !important}.ClPyQMVF4uvaF9oj4BJv{white-space:nowrap;width:300px !important}`, "",{"version":3,"sources":["webpack://./src/components/TradeSettlement/TradeSettlement.scss"],"names":[],"mappings":"AAAA,sBAEI,kBAAA,CACA,mBAAA,CAGJ,+CACI,6BAAA,CAGJ,sBACI,kBAAA,CACA,sBAAA","sourcesContent":[".tabContent {\n    // background-color: red !important;\n    padding-left: 400px;\n    padding-right: 400px;\n}\n\nul .nav .nav-tabs {\n    padding-left: 300px !important;\n}\n\n.tab-nav {\n    white-space: nowrap;\n    width: 300px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContent": `qrclfMB0haPJvdIc2L2Q`,
	"nav": `gZh1VGWoee74chYxZlUV`,
	"nav-tabs": `QsTtn61Svats2Aw86jKV`,
	"tab-nav": `ClPyQMVF4uvaF9oj4BJv`
};
export default ___CSS_LOADER_EXPORT___;
