// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uTUSEgVO5YxvVsY8V1EP{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/components/RequestForQuote/RequestForQuote.scss"],"names":[],"mappings":"AAAA,sBACI,kBAAA","sourcesContent":[".rfq-btn {\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rfq-btn": `uTUSEgVO5YxvVsY8V1EP`
};
export default ___CSS_LOADER_EXPORT___;
