import React, { useEffect, useState, useCallback } from 'react';
import { NavLink, useNavigate  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import { Hours as HoursService } from '../../services/hoursService';
import { User } from '../../services/userService';

import './_td_header-nav.css';
import './Header.scss';
import Hamburger from './Hamburger';
import ProfileMenu from './ProfileMenu';
import TDLogo from '../common/TDLogo';


function Hours() {
    const { t } = useTranslation();
    // we don't really want to keep state, just trigger a re-render, so just use an empty object
    const [changed, setChanged] = useState({});
    useEffect(() => {
        let done = false;
        const sub = HoursService.subscribe(() => {
            if (!done) {
                setChanged({});
            }
        });
        return (() => {
            done = true;
            HoursService.unsubscribe(sub);
        });
    }, []);
    let key = 'td.panel.welcome.trading.hours';

    if (HoursService.isClosed) {
        key = 'td.panel.welcome.trading.hours.closed';
    } else if (HoursService.isHoliday) {
        key = 'td.panel.welcome.holiday.trading.hours';
    }

    return (
        <span>{t(key, {start: HoursService.branchStart, stop: HoursService.branchStop})}</span>
    )
}

function logout(navigate) {
    // FIXME - make this better
    // check the user 
    let logoutUrl = User?.user?.logoutUrl;

    User.logout(false, false, true);
    let theUrl = `${window.location.origin}/tdfx/login`;
    if (logoutUrl) {
        theUrl = `${logoutUrl}/logout?appId=SDP&appLogoutUrl=${window.location.origin}/tdfx/login`;
    }
    window.location.replace(theUrl);
}

function NameDropdown() {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const closeDropDown = useCallback(() => {
        // remove our event listener
        setDropdownOpen(false);
        document.removeEventListener('click', closeDropDown, false);
    }, []);

    function toggleDropdown() {
        // add an event handler to listen for outside clicks
        if (!dropdownOpen) {
            setDropdownOpen(!dropdownOpen);
            setTimeout(() => {
                document.addEventListener('click', closeDropDown, false);
            }, 0);
        } else {
            closeDropDown();
        }
    }

    return (
        <li className={`td-not-dropdown td-dropdown-language td-dropdown-no-hover td-dropdown-active`}>
            <span>{User.user.fullName}</span>
        </li>
    );
}
function NameAndLogout() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    // we might not even need this as our event handler is going to catch it?

    
    // we know we have a user, but logout timing might mean it goes away before the screen does
    // FIXME make name a dropdown etc.
    if (User.user) {
        return (
          <>
            <div className="td-other-toggles">
              <ul>
                <NameDropdown/>
              </ul>
            </div>
            <Button className="btn-sm td-btn-secondary-clear" onClick={() => logout(navigate)}>
                {t('td.fx.logout')}
            </Button>
          </>
        );
    } else {
        return null;
    }
}


function getMenuTab(item, t) {
    return (
      <li key={item.key}>
        <Nav.Link as={NavLink} to={'/' + item.key} key={item.key}>{t(item.name)}</Nav.Link> 
      </li>
    );
}

function NavList({list}) {
    const { t } = useTranslation();
    return list.map(l => {
      if (!l.access) {
        return (getMenuTab(l, t));
      } else {
        // Check permissions on this tab
        let match = false;
        const permissions = Object.keys(User.user.permissions);
        for (let p of l.access) {
          if (permissions.includes(p)) {
            match = l;
            break;
          }
        }

        if (match) {
            return (getMenuTab(match, t));
        }
      }
    });
}

export default function Header ({ list }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);



  return (
    <>
      <div className={`td_rq_header-nav td-header-nav`}>
        {/* DESKTOP HEADER - START */}
        <header className="td-header-desktop">
          <div className="td-skip"><a href="#main">Skip to main content</a> </div>
          <div className="td-utility-toggle">
              <div className="td-container">
                  <div className="td-section-left">
                      <div className="td-segments">
                        <TDLogo small={true}/>
                      </div>
                  </div>
                  <div className="td-section-right">
                    <Hours/>
                    <NameAndLogout/>
                  </div>
              </div>
          </div>
          <div className="td-nav-primary">
            <div className="td-container">
              <div className="td-section-left">
                  <nav>
                      <ul>
                        <NavList list={list}/>
                      </ul>
                  </nav>
              </div>
            </div>
          </div>
        </header>
        {/* MOBILE HEADER - START */}
        <header className="td-header-mobile">
          <div className="td-container">
              <div className="td-section-left">
                  <button type="button" className="td-mobile-action-button td-mobile-menu-button" onClick={() => setHamburgerOpen(!hamburgerOpen)}>
                      <div className="td-mobile-menu-button-icon">
                          <span className="td-forscreenreader">Open menu</span>
                          <span className="icon-bar" aria-hidden="true"></span>
                          <span className="icon-bar" aria-hidden="true"></span>
                          <span className="icon-bar" aria-hidden="true"></span>
                      </div>
                      <div className="td-logo"><TDLogo iconOnly={true} small={true}/></div>
                  </button>
              </div>
              <div className="td-section-right">
                <button type="button" className="td-mobile-action-button td-mobile-menu-secondary-button td-login" onClick={() => setProfileOpen(!profileOpen)}>
                    <span className="td-icon td-icon-avatar"></span><span className="td-forscreenreader">Open menu</span>
                </button>
            </div>
          </div>
        </header>
        <Hamburger isOpen={hamburgerOpen} list={list} toggle={setHamburgerOpen} permissions={User.user.permissions}/>
        <ProfileMenu isOpen={profileOpen} toggle={setProfileOpen} name={User.user.fullName} logout={() => logout(navigate)}/>
      </div>
    </>
  );
}

