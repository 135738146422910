import React, { useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import TDLogo from '../common/TDLogo';
import styles from './LoginForm.scss'

function LoginForm({ done }) {
    const { t } = useTranslation();

    useEffect(() => {
        localStorage.setItem('internal', true);
    }, []);
 
    // FIXME - screen jumps around when we add/remove error state
    // add some conditional spacing
    return (
        <Row className={'g-0 ' + styles.fullHeight}>
            <Col xs={12} sm={6} md={5} lg={4} className={`g-0 ${styles.loginWrapper}`}>
                <Container style={{ padding: '4em', boxSizing: 'border-box' }}>
                    <TDLogo/>
                    <p className="display-6" style={{ marginTop: '2em'}}>{t('td.fx.title')}</p>
                    <div className="d-grid gap-2">
                        <Button as="a" href="/tdfx/ping" className="btn td-btn-primary-light">{t('td.fx.login.signin')}</Button>
                    </div>
                </Container>
                <Footer login={true}/>
            </Col>
            <Col xs={12} sm={6} md={7} lg={8} className={`g-0 ${styles.loginBackground}`}>
            </Col>
        </Row>
    );
}

export default LoginForm;