import { User } from './userService';
import { service as io } from './streamingService';

function SSIUpdaterService() {

    this.currencies =  [
        'AED', 'AUD', 'CAD', 'CHF', 'CNH', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'ILS', 'INR',
        'JPY', 'MXN', 'NOK', 'NZD', 'PLN', 'SAR', 'SEK', 'SGD', 'THB', 'TRY', 'USD', 'ZAR'
    ];

    // console.log("User itself!!", User);
    this.resetMe = function () {};

    // this.reset();
    this.getClients = function () {
        const clients = User.user.companies ? User.user.companies.filter(company => company.allowSsiUpdate) : []; 
        return clients;
    };


    this.submitWirePaymentInstruction = function (data, cb) {
        try {
            io.emit('submitWirePaymentInstruction', data, cb, 'processWirePaymentInstruction');
        } catch (err) {
            console.error(err);
            cb(false);
        }
    };

    this.submitBankAccountChanges = function (changes, cb) {
        let data = [];
        // convert what we need to the format we need
        changes.forEach(c => {
            let change = {action: c.action, transitNumber: c.transitNumber, accountNumber: c.accountNumber, name: c.name, currency: c.currency, code: c.client.code, isThirdparty: c.isThirdparty, isPayee: c.isPayee};
            if (change.action == 'remove') {
                change.accountNumber = c.account.number;
            }
            change.isCanadian = c.client?.deskCountry == 'Canada';
            data.push(change);
        });
        console.log('submitBankAccountChange: ', data);
        try {
            io.emit('submitBankAccountChanges', {changes: data}, cb, 'processBankAccountChanges');
        } catch (err) {
            console.error(err);
            cb(false);
        }
    };
}

export const SSIUpdater = new SSIUpdaterService();