import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './support.scss';

export default function ForwardTrade() {
    const { t, i18n } = useTranslation();

    return (
        <Trans i18nKey="userMessagesUnread">
            <div>
                <h4>{t('td.fx.support.spotForwardTrading.title.tradeOnly')}</h4>
                <p>{t('td.fx.support.spotForwardTrading.explanation.tradeOnly01')}</p>

                <Trans i18nKey={'td.fx.support.spotForwardTrading.explanation.tradeOnly02'}>
                    <p><Link to={'/rfq'} className="link-underline">x</Link>x</p>
                </Trans>

                <h5>{t('td.fx.support.spotForwardTrading.tradeOnly.title.step01')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.tradeOnly.explanation.step01.01')}</p>
                <p>{t('td.fx.support.spotForwardTrading.tradeOnly.explanation.step01.02')}</p>
                <Trans i18nKey={'td.fx.support.spotForwardTrading.tradeOnly.explanation.step01.03'}
                >
                    <p><a href="mailto:tdfx@tdsecurities.com">e</a>f</p>
                </Trans>



                <h5>{t('td.fx.support.spotForwardTrading.tradeOnly.title.step02')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.tradeOnly.explanation.step02.01')}</p>

                <figure>
                    <img src={t('td.fx.support.ForwardFig1')} className={`${styles["support-img-max"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
                </figure>
                
                <h5>{t('td.fx.support.spotForwardTrading.tradeOnly.title.step03')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.tradeOnly.explanation.step03.01')}</p>
                
                <figure>
                    <img src={t('td.fx.support.ForwardFig2')}/>
                    <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
                </figure>
                
                <p>{t('td.fx.support.spotForwardTrading.tradeOnly.explanation.step03.02')}</p>

                <figure >
                    <img src={t('td.fx.support.ForwardFig3')} width="60%"/>
                    <figcaption>{t('td.fx.support.figureCaption03')}</figcaption>
                </figure>

                <p>{t('td.fx.support.spotForwardTrading.tradeOnly.explanation.step03.03')}</p>
                
                <br/>
                <h4>{t('td.fx.support.spotForwardTrading.title.settleOnly')}</h4>
                <p>{t('td.fx.support.spotForwardTrading.explanation.settleOnly01')}</p>
                
                <Trans i18nKey={'td.fx.support.spotForwardTrading.explanation.settleOnly02'}>
                    <p><Link to={'/rfq'} className="link-underline">x</Link>x</p>
                </Trans>


                <h5>{t('td.fx.support.spotForwardTrading.settleOnly.title.step01')}</h5>

                <p>{t('td.fx.support.spotForwardTrading.settleOnly.explanation.step01.01')}</p>
                <figure >
                    <img src={t('td.fx.support.ForwardFig4')} width="60%"/>
                    <figcaption>{t('td.fx.support.figureCaption04')}</figcaption>
                </figure>
                <p>{t('td.fx.support.spotForwardTrading.settleOnly.explanation.step01.02')}</p>
                
                <h5>{t('td.fx.support.spotForwardTrading.settleOnly.title.step02')}</h5>
                <p>{t('td.fx.support.spotForwardTrading.settleOnly.explanation.step02.01')}</p>
                <figure >
                    <img src={t('td.fx.support.ForwardFig5')} className={`${styles["support-img"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption05')}</figcaption>
                </figure>
                <p>{t('td.fx.support.spotForwardTrading.settleOnly.explanation.step02.02')}</p>
            </div>
        </Trans>
        );
}


