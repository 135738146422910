import io from 'socket.io-client';

// FIXME - should use the keepalive service
function StreamingService() {
    this.connect = function (cb) {
        // FIXME - guard against being called twice?
        const self = this;
        const connectionListener = function (data) {
            if (data && data.data) {
                data = data.data;
                if (data.socketid) {
                    // are we adding the id?
                    if (data.action === 'add') {
                        self.remoteConnectionid = data.socketid;
                    } else {
                        // compare the id in the message with our connection id
                        if (data.socketid === self.remoteConnectionid) {
                            if (self.notifyOnConnection) {
                                self.notifyOnConnection();
                            }
                        } else {
                            // that's not great
                            if (self.notifyOnOtherConnection) {
                                self.notifyOnOtherConnection();
                            }
                            // clear out our local connection id
                            self.remoteConnectionid = undefined;
                        }
                    }
                }
            }
        };

        this.socket = io.connect({ path: '/tdfx/socket.io', transports: ['websocket'] });
        // console.log('clearing envelope id');
        this.id = false;

        this.socket.once('connect', cb);

        this.socket.on('error', function (err) {
            console.log('Error:', err);
        });

        this.socket.on('connectionid', connectionListener);

        this.socket.on('SESSION_ERROR', function (data) {
            // there was a problem with the session
            // server will disconnect the socket
            // we should kill ours too so we don't reconnect
            self.socket.disconnect();
            self.socket = false;
        });

    };

    this.disconnect = function () {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = false;
        }
    };

    this.setNotifyOnConnection = function(func) {
        this.notifyOnConnection = func;
    },

    this.setNotifyOnOtherConnection = function(func) {
        this.notifyOnOtherConnection = func;
    },

    // if we call this again for the same message before we get a response, bad things may happen
    this.emit = function (message, data, cb, responseMessage) {
        if (!this.socket) {
            cb?.({data:{data:[]}});
            return;
        }
        data = data || { id: message };
        data.id = data.id || message;
        data.clientLocalTime = (new Date()).toString();

        if (cb) {
            responseMessage = responseMessage || message;
            const s = this.socket;
            const handler = function (resData) {
                // console.log('streaming calling handler');
                cb(resData);
                if (!resData?.data?.more) {
                    s.removeListener(responseMessage, handler);
                }
            };
            // if we already have a handler for this message (e.g. because we got a disconnect before we cleaned up)
            // we remove it first
            // FIXME - is this safe for all events?
            this.socket.removeAllListeners([responseMessage]);
            this.socket.on(responseMessage, handler);
        }
        this.socket.emit(message, data);
        console.log(`Emitting ${message} - socket is ${this.socket.connected ? '' : 'NOT'} connected`);
    };

    this.subscribe = function (message, cb) {
        // we care if the socket exists, but maybe we don't care if the socket is connected
        // we could be in the middle of a reconnect, but setting up the subscription should still work
        if (cb && this.socket /* && this.socket.connected */) {
            if (!this.socket.connected) {
                console.log(`subscribing to ${message} while socket is not connected`);
            }
            this.socket.on(message, cb);
            return cb;
        }
        return false;
    };

    this.unsubscribe = function (message, listener) {
        // FIXME - check socket exists and is connected
        this.socket.removeListener(message, listener);
    };

    // socket doesn't seem to be connected if we test for reconnect
    // so look for connect instead, which we also get
    // though we probably don't want it on the first connection?
    this.onReconnect = function (cb) {
        return this.subscribe('connect', cb);
    };

    // we handle this in a bunch of different ways, which is weird.
    this.onDisconnect = function (cb) {
        return this.subscribe('disconnect', cb);
    };

    // utility to create listener and filter out messages
    this.createListener = function (message, func) {
        var listener = (envelope) => {
            if (envelope) {
                // the first message we get should be for us
                // check for global messages first
                var shouldProcess = false;
                // we sometimes get global messages, is it one of those?
                if (envelope.id === 'tdfx_global') {
                    shouldProcess = true;
                } else {
                    // we don't have an id, so this should be our first message
                    if (!this.id) {
                        // console.log('setting envelope id', envelope.id);
                        this.id = envelope.id;
                    }
                    if (this.id === envelope.id && envelope.data) {
                        // it's ours
                        shouldProcess = true;
                    } else {
                        // console.log('rejecting message with bad envelope ', envelope.id, this.id);
                    }
                }
                if (shouldProcess) {
                    func(envelope.data);
                }
            } else {
                // console.log('Streaming - no envelope');
            }
        };
        this.socket.on(message, listener);
        return listener;
    };
}

export const service = new StreamingService();
