import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';

export default function CommonMessages() {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <h4>{t('td.fx.support.common.title')}</h4>
            <Table responsive size="sm">
            <thead>
                <tr>
                    <th>{t('td.fx.support.common.title.errorHeader')}</th>
                    <th>{t('td.fx.support.common.explanationHeader')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error01')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation01')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error02')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation02')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error03')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation03')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error04')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation04')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error05')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation05')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error06')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation06')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error07')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation07')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error08')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation08')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error09')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation09')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error10')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation10')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error11')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation11')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error12')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation12')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error13')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation13')}
                    </td>
                </tr>
                <tr>
                    <td>
                        {t('td.fx.support.common.td.error14')}
                    </td>
                    <td>
                        {t('td.fx.support.common.td.explanation14')}
                    </td>
                </tr>
            </tbody>
            </Table>
        </div>
    );  
}