import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './support.scss';

export default function WindowForwardDrawdownTrading() {
    const { t, i18n } = useTranslation();

        return(
            <Trans>
                <div>
                    <h4>{t('td.fx.support.windowForwardDrawdown.title.windowForwardDrawdown')}</h4>
                    <p>{t('td.fx.support.windowForwardDrawdown.explanation.windowForwardDrawdown01')}</p>
                    <h5>{t('td.fx.support.windowForwardDrawdown.title.step01')}</h5>

                    <Trans i18nKey={'td.fx.support.windowForwardDrawdown.explanation.step01.01'}>
                        <p><Link to={'/rfq'} className="link-underline">x</Link>x</p>
                    </Trans>
                    <p>{t('td.fx.support.windowForwardDrawdown.explanation.step01.02')}</p>
                    <figure>
                        <img src={t('td.fx.support.DrawdownFig1')} className={`${styles["support-img-max"]}`}/>

                        <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
                    </figure>
                    <p>{t('td.fx.support.windowForwardDrawdown.explanation.step01.03')}</p>
                    <ul>
                        <li>{t('td.fx.support.windowForwardDrawdown.explanation.step01.03.bullet01')}</li>
                        <li>{t('td.fx.support.windowForwardDrawdown.explanation.step01.03.bullet02')}</li>
                        <li>{t('td.fx.support.windowForwardDrawdown.explanation.step01.03.bullet03')}</li>
                    </ul>
                    <figure>
                        <img src={t('td.fx.support.DrawdownFig2')} className={`${styles["support-img"]}`}/>
                        <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
                    </figure>
                    <h5>{t('td.fx.support.windowForwardDrawdown.title.step02')}</h5>
                    <p>{t('td.fx.support.windowForwardDrawdown.explanation.step02.01')}</p>
                    <p>{t('td.fx.support.windowForwardDrawdown.explanation.step02.02')}</p>
                    <figure>
                        <img src={t('td.fx.support.DrawdownFig3')}/>
                        <figcaption>{t('td.fx.support.figureCaption03')}</figcaption>
                    </figure>
                    <h5>{t('td.fx.support.windowForwardDrawdown.title.step03')}</h5>
                    <p>{t('td.fx.support.windowForwardDrawdown.explanation.step03.01')}</p>
                    <figure>
                        <img src={t('td.fx.support.DrawdownFig4')} className={`${styles["support-img"]}`}/>
                        <figcaption>{t('td.fx.support.figureCaption04')}</figcaption>
                    </figure>
                </div>
            </Trans>
        );
}