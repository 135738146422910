// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RIRpkEptzp1ICLRQ2wkD .rt-table{max-height:650px}.RIRpkEptzp1ICLRQ2wkD .QtdAJyL_BLBMDKY7wfGw{background-color:#90ee90 !important}.dark .PXRYMDaovxZESAIFhzDF .X3GFtO55_rybcisA0hAY,.dark .PXRYMDaovxZESAIFhzDF .X1l87bsUTk_3Y_1cmWIg{background-color:#6f4242 !important}.dark .PXRYMDaovxZESAIFhzDF .QtdAJyL_BLBMDKY7wfGw{background-color:#2f4f2f !important}.dark .PXRYMDaovxZESAIFhzDF .KzFWOV4Ts1P8g53UVT7u{background-color:#506987 !important}.SigM4_lJMZpqxuagG57p{width:100%;margin:auto;text-align:center}.XfY5x3vNnRQ6jE2Vu2gz{margin-top:16px;margin-bottom:5px}.blotter-header .nav-item .nav-link{padding:7px !important}`, "",{"version":3,"sources":["webpack://./src/components/common/Blotter/TradeBlotter.scss"],"names":[],"mappings":"AACI,gCACI,gBAAA,CAEJ,4CACI,mCAAA,CAKJ,oGACI,mCAAA,CAEJ,kDACI,mCAAA,CAEJ,kDACI,mCAAA,CAGR,sBACI,UAAA,CACA,WAAA,CACA,iBAAA,CAGJ,sBACI,eAAA,CACA,iBAAA,CAKJ,oCACI,sBAAA","sourcesContent":[".TradeBlotter {\n    :global(.rt-table) {\n        max-height: 650px;\n    }\n    .active {\n        background-color: #90EE90 !important;\n    }\n\n}\n:global .dark :local .OrderGrid {\n    .rejected, .cancel {\n        background-color: #6F4242 !important;\n    }\n    .active {\n        background-color:  #2F4F2F !important;\n    }\n    .filled {\n        background-color: #506987 !important;\n    }\n}\n.centeredColumn {\n    width: 100%;\n    margin: auto;\n    text-align: center;\n}\n\n.BlotterButtons {\n    margin-top: 16px;\n    margin-bottom: 5px;\n}\n\n\n/* fix tab compactness */\n:global .blotter-header .nav-item .nav-link {\n    padding: 7px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TradeBlotter": `RIRpkEptzp1ICLRQ2wkD`,
	"active": `QtdAJyL_BLBMDKY7wfGw`,
	"OrderGrid": `PXRYMDaovxZESAIFhzDF`,
	"rejected": `X3GFtO55_rybcisA0hAY`,
	"cancel": `X1l87bsUTk_3Y_1cmWIg`,
	"filled": `KzFWOV4Ts1P8g53UVT7u`,
	"centeredColumn": `SigM4_lJMZpqxuagG57p`,
	"BlotterButtons": `XfY5x3vNnRQ6jE2Vu2gz`
};
export default ___CSS_LOADER_EXPORT___;
