import React from 'react';
import { GridHeader } from '..';

import utilities from '../../../utils/';
const { numericSort } = utilities.sort;

function formatAmount({value}) {
    return utilities.format.formatAmount(value);
}


import { filters }  from '../../../utils';
import FunctionalButtons from '../../RequestForQuote/FunctionalButtons';

const COL_WIDTH = {
    XS: 30,
    SM: 75,
    MD: 150,
    LG: 200,
    XL: 350
};

const defaultDef = {
    width: COL_WIDTH.XS,
    resizable: true,
    sortable: true,
    floatingFilterComponentParams: {suppressFilterButton: true}, 
    floatingFilter: false, 
    suppressMovable: true, 
    suppressMenu: true,
    wrapHeaderText: true,
    autoHeaderHeight: true
};

function getColumns(t) {
    const columns = [        
    {
        width: COL_WIDTH.SM,
        resizable: false,
        cellRenderer: (props) => <FunctionalButtons drawdown={true} {...props} />
    },{
        headerName: 'fx.blotter.header.deal.id',
        field: 'dealId',
        width: 160,
        cellStyle: {display: 'flex', justifyContent: 'center'},
    }, {
        field: 'creationTime',
        width: 0,
        hide: true,
        resizable: false,
    }, {
        headerName: 'fx.blotter.header.value.date',
        field: 'valueDate',
        resizable: false,
        width: 105,
        cellStyle: {display: 'flex', justifyContent: 'center'},
    }, {
        headerName: 'fx.blotter.header.buy.currency',
        field: 'buyCurrency',
        resizable: false,
        width: 95,
        cellStyle: {display: 'flex', justifyContent: 'center'},
    }, {
        headerName: 'fx.blotter.header.amount',
        field: 'buyAmount',
        width: 150,
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // sortMethod: numericSort,
        valueFormatter: formatAmount
    }, {
        headerName: 'fx.blotter.header.sell.currency',
        field: 'sellCurrency',
        resizable: false,
        width: 105,
        cellStyle: {display: 'flex', justifyContent: 'center'},
    }, {
        headerName: 'fx.blotter.header.sell.amount',
        field: 'sellAmount',
        width: 150,
        cellStyle: {display: 'flex', justifyContent: 'right'},
        // sortMethod: numericSort,
        valueFormatter: formatAmount
    }, {
        headerName: 'fx.blotter.header.buy.account',
        field: 'buyAccount',
        width: 120,
    },  {
        headerName: 'fx.blotter.header.sell.account',
        field: 'sellAccount',
        width: 120,
    }, {
        headerName: 'fx.blotter.header.settled.by',
        field: 'settledByCode',
        width: 120,
    }, {
        headerName: 'fx.blotter.header.trade.date',
        field: 'tradeDate',
        resizable: false,
        width: 105,
        cellStyle: {display: 'flex', justifyContent: 'center'},
    }, {
        headerName: 'fx.blotter.header.wire.memo',
        field: 'wireMemo',
        width: 120,
    }, {
        headerName: 'fx.blotter.header.status',
        field: 'status',
        resizable: false,
        width: 80,
        cellStyle: {display: 'flex', justifyContent: 'center'},
    }];

    for (let index = 0; index < columns.length; index++) {
        columns[index].headerName = t(columns[index].headerName);
        columns[index].tooltipField = columns[index].field;


        // columns[index].id = index;
        for (const key in defaultDef) {
            // because the defaults can be false...
            if (columns[index][key] === undefined) {
                columns[index][key] = defaultDef[key];
            }
        }
    }

    return columns;
};

export default getColumns;