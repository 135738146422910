import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function LogoutError({ sessionTimeout, closeTimeoutError, logoutError, noRolesError }) {
    const { t } = useTranslation();

    return (
        <Modal show={!!sessionTimeout} centered className="td-modal" onClose={closeTimeoutError} >
            <Modal.Header >
                <Modal.Title>
                    {t('tdfx.user.error.timeout.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="td-modal-body-shown">
            <p> {t(logoutError || 'tdfx.user.error.timeout.message')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn td-btn-primary-light" onClick={closeTimeoutError}>
                        {t('tdfx.user.error.disconnected.login')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { LogoutError };
