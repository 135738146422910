import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DOMPurify from "dompurify";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Footer from '../Footer';
import { User } from '../../services/userService';

import styles from './LoginForm.scss';
import TDLogo from '../common/TDLogo';

import { useForm } from '../../utils/useForm';
import FilteringInput from '../input/FilteringInput';

function MOTD() {
    const { i18n } = useTranslation();
    const [motd, setMotd] = useState('');
    // FIXME - this makes things easier, but feels wrong...
    let location = useLocation();
    const preview = location?.hash?.indexOf('preview') >= 0;
    

    // depends on language/locale too, but those can't change without a reload
    useEffect(() => {
        // lookup the message of the day
        // FIXME - does language give us the right values?
        fetch(`/tdfx/motd?locale=${i18n.language}`, { method: (preview ? 'POST': 'GET') })
            .then((res) => {
                if (res && res.ok) {
                    res.text().then((val) => {
                        setMotd(val);
                    });
                } else {
                    // that's a shame
                }
            })
            .catch((error) => {
                // too bad
                console.log(error);
            });
    }, [preview]);

    // FIXME - styling is ugly
    return (
        motd ? (
            <div className={'py-3 text-center vertical-center'}>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(motd)}}></div>
            </div>
        ) : null
    );
};


function LoginForm({done}) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    function setLanguage() {
        localStorage.setItem('locale', i18n.language);
    }

    useEffect(() => {
        const errorStart = location.search?.indexOf('errorCode=');
        if (errorStart >= 0) {
            const error = location.search.substring(errorStart + 10);
            navigate('/login/error', { state: { errorCode: error }, replace: true });
        }
    }, [location.hash]);


    return (
        <Row className={styles.fullHeight + ' g-0'}>
            <Col xs={12} sm={6} md={5} lg={4} className={`g-0 ${styles.loginWrapper}`}>
                <Container className={`${styles.loginContainer}`}>
                    <TDLogo/>
                    <p className="display-6" style={{ marginTop: '2em'}}>{t('td.fx.title')}</p>
                    <div className="h1">{t('td.fx.client.login')}</div>
                    <TheForm done={done}/>
                    <MOTD/>
                    <p className="display-6 center" style={{ marginTop: '2em' }}>{t('td.fx.login.op')}</p>
                    <div className="d-grid gap-2">
                        <Button as="a" href={`/tdfx/onePortal?locale=${i18n.language}`} onClick={setLanguage()} className="btn td-btn-primary-light">{t('td.fx.login.signin.op')}</Button>
                    </div>
                </Container>
                <Footer login={true}/>
            </Col>
            <Col xs={12} sm={6} md={7} lg={8} className={`g-0 ${styles.loginBackground}`}>
            </Col>
        </Row>
    );
}

function TheForm({ done }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // FIXME - does it really make sense to hoist the values all the way up?
    // better to keep them here, and only push them up when the form is valid, and submit happens
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(!!localStorage.getItem('username'));
    // intended to capture the difference between I've never pressed the button, and I've pressed the button
    // not sure it really makes sense on this screen
    const [hasSubmit, setHasSubmit] = useState(false);
    const [working, setWorking] = useState(false);

    const [error, setError] = useState(false);
    const {fieldErrors, setFieldError} = useForm(validate);

    useEffect(() => {
        localStorage.removeItem('internal');
    }, []);
 

    function validate(fields) {
        fields = fields || fieldErrors;
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError(true);
        } else {
            setError(false);
        }
    }

    function doSubmit(e) {
        setHasSubmit(true);
        // don't do default stuff
        e.preventDefault();
        e.stopPropagation();

        if (error) {
            // there's an error
            return false;
        }
        setWorking(true);
        localStorage.setItem('locale', i18n.language);
        if (username && remember) {
            localStorage.setItem('username', username);
        } else {
            localStorage.removeItem('username')
        }

        const data = { username: username, password: password };
        doLogin(data);
    }

    const [loginError, setLoginError] = useState();

    function doLogin(data) {
        User.login(data, (err) => {
            switch (err) {
                case 'reset.success':
                case 'nodata':
                case 'invalid':
                case 'email':
                case 'disabled':
                case 'connection':
                case 'locked' :
                case 'justlocked' :
                    setLoginError(err);
                    break;
                case 'reset': 
                case 'notemail':
                case 'notuserid':
                case 'notfirstname':
                case 'notlastname':
                case 'notname':
                case 'reused':
                    navigate('/login/resetPassword', { state: { username: username }, replace: true });
                    break;
                case 'questions':
                    navigate('/login/questions');
                    break;
                default:
                    navigate('/login/error', { state: { errorCode: err }, replace: true });
                    break;
            }
            setWorking(false);
            setPassword('');
            done();
        });
    }

    function onFormChange(evt) {
        // clear the login error
        setLoginError();

        // FIXME - move the field validation here?
        // then we could set error states, and have the error values look at those instead of at the functions?
    }

    // FIXME - screen jumps around when we add/remove error state
    // add some conditional spacing
    return (
        <Form noValidate disabled={working} onSubmit={doSubmit} onChange={onFormChange}>
            <fieldset disabled={working}>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>{t('td.fx.login.username')}</Form.Label>
                    <FilteringInput
                        name="username"
                        value={username}
                        required
                        hideError={!hasSubmit}
                        setFieldError={setFieldError}
                        errorPrefix={'td.validation.'}
                        placeholder={'td.fx.login.username'}
                        update={val => setUsername(val)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>{t('td.fx.login.password')}</Form.Label>
                    <FilteringInput
                        name="password"
                        value={password}
                        required
                        hideError={!hasSubmit}
                        setFieldError={setFieldError}
                        errorPrefix={'td.fx.login.error.password.'}
                        placeholder={'td.fx.login.password'}
                        maxLength={25}
                        update={val => setPassword(val)}
                        regex={/^.{6,25}$/}
                        password
                    />
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="remember">
                    <Col style={{ paddingRight: 0 }}>
                        <Form.Check label={`${t('td.fx.login.remember.me')}`} id="rememberMe" checked={remember} onChange={(e) => setRemember(e.target.checked)}/>
                    </Col>
                    <Col style={{ textAlign: 'right', paddingLeft: 0}}>
                        <h4 className={`${styles[`forgot-password`]}`}>
                            <Link to='/login/password' state={{ username: username }}>{t('td.fx.login.forgot.your.password')}</Link>
                        </h4>
                    </Col>
                </Form.Group>

                <Form.Label className={'td-error'} hidden={!loginError}>{ t(`td.fx.login.error.${loginError}`) }</Form.Label>

                <div className="d-grid gap-2">
                    <Button className="btn td-btn-primary-light" type="submit">{t('td.fx.login.signin')}</Button>
                </div>
            </fieldset>
        </Form>
    );
}

export default LoginForm;