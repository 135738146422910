import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnePortal } from '../../services/onePortalService';
import { Button, Modal, Form, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { User } from '../../services/userService';

export default function SetupCredentials({ open, setOpen, model, working, handleChange, submit, hasSubmit, onFormChange, error }) {
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);
    const [returnError, setReturnError] = useState();
    const [success, setSuccess] = useState(false);

    // listen to the op service
    useEffect(() => {
        let done = false;
        let sub = OnePortal.subscribe((data) => {
            if (data) {
                setCountries(data);
            }
            if (!done) {
                setReturnError(OnePortal.error);
                setSuccess(OnePortal.success);
            }
        });
        return () => {
            done = true;
            OnePortal.unsubscribe(sub);
        }
    }, []);

    useEffect(() => {
        OnePortal.getCountryCodes();
    }, []);

    function logout() {
        User.logout();
    }

    function getModalBody() {
        if (returnError) {
            return (
                <Modal.Body className="td-modal-body-shown">
                    <h3>{t('td.fx.op.error.heading')}</h3>
                    <p>{t('td.fx.op.error.try.again')}</p>
                </Modal.Body>
            );
        } else if (success) {
            return (
                <Modal.Body className="td-modal-body-shown">
                    <h3>{t('td.fx.op.already.registered')}</h3>
                    <p>{t('td.fx.op.check.email')}</p>
                </Modal.Body>
            );
        }

        return (
            <Modal.Body className="td-modal-body-shown">
                <>
                    <p>{t('td.fx.op.setup.email.phone')}</p>
                    <p>{t('td.fx.op.setup.register.info')}</p>

                    <Row className="g-4">
                        <Col md={8} sm={8}>
                            <Form.Group>
                                <Form.Label>{t('td.fx.label.full.name')}</Form.Label>
                                <h4>{model.firstName} {model.lastName}</h4>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="g-4" style={{ paddingTop: '15px' }}>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>{t('td.fx.label.email')}
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Popover id={`popover-positioned-right`}>
                                                <Popover.Body>
                                                    <p><strong>{t(User.deskSpecificText('td.fx.op.setup.email.tooltip'))}</strong></p>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span className={`td-icon td-icon-help tdgreen`} ></span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <h4>{model.email}</h4>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="g-4"  style={{ padding: '15px 0' }}>
                        <Col md={6} sm={6}>
                            <Form.Group>
                                <Form.Label>{t('td.fx.label.phone.number')}</Form.Label>
                                <PhoneInput
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className={"form-control"}
                                    value={model.phoneNumber}
                                    onChange={val => handleChange('phoneNumber', val)}
                                    defaultCountry={model.isUSCustomer ? "US" : "CA"}
                                    international={true}
                                    countryCallingCodeEditable={false}
                                    addInternationalOption={false}
                                    limitMaxLength={true}
                                    countryOptionsOrder={["CA", "US", "GB", "IE", "SG"]}
                                    focusInputOnCountrySelection={true}
                                    countries={countries}
                                    onCountryChange={val => handleChange('countryCode', val)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={6}>
                            <Form.Group>
                                <Form.Label>{t('td.fx.label.confirm.phone.number')}</Form.Label>
                                <PhoneInput
                                    name="confirmPhoneNumber"
                                    id="confirmPhoneNumber"
                                    className={"form-control"}
                                    value={model.confirmPhoneNumber}
                                    onChange={val => handleChange('confirmPhoneNumber', val)}
                                    defaultCountry={model.isUSCustomer ? "US" : "CA"}
                                    international={true}
                                    countryCallingCodeEditable={false}
                                    addInternationalOption={false}
                                    limitMaxLength={true}
                                    countryOptionsOrder={["CA", "US", "GB", "IE", "SG"]}
                                    focusInputOnCountrySelection={true}
                                    countries={countries}
                                    onCountryChange={val => handleChange('countryCode', val)}
                                />
                            </Form.Group>
                        </Col>
                        <Form.Label className={'td-error'} hidden={!hasSubmit || !error}>{ t(`td.fx.op.error.${error}`) }</Form.Label>
                    </Row>
                </>
            </Modal.Body>
        );
    }

    return (
        <Modal show={open} centered className="td-modal modal-xl">
            <Form noValidate onChange={onFormChange}>
                <Modal.Header><h2>{t('td.fx.op.setup')}</h2></Modal.Header>
                {getModalBody()}
                <Modal.Footer>
                    { success ? <>
                            <Button disabled={working} onClick={() => setOpen(false)} className="btn td-btn-secondary-clear">{t('td.fx.not.now')}</Button>
                            <Button disabled={working} onClick={() => logout()} className="btn td-btn-primary-light">{t('td.fx.relogin.oneportal.success')}</Button>
                        </> 
                        :
                        <>
                            <Button disabled={working} onClick={() => setOpen(false)} className="btn td-btn-secondary-clear">{t('td.fx.signing.cancel')}</Button>
                            { !returnError && <Button disabled={working} onClick={(e) => submit(e)}  className="btn td-btn-primary-light">{t('td.fx.setup')}</Button>}
                        </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}