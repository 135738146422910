// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(min-width: 768px){.Wl6aQv96bfTjcFduRdco{position:absolute !important;right:101px;cursor:pointer}}@media(max-width: 767px){.Wl6aQv96bfTjcFduRdco{position:absolute !important;right:94px;cursor:pointer}}.uCKBiFX7aUImwVHTloGQ{padding:1em 0}.uCKBiFX7aUImwVHTloGQ:nth-child(even){background:#f9f9f9;border-top:solid 1px #ccc;border-bottom:solid 1px #ccc}.pkBMiBUYcrPNNClgCPeY{background:#f9f9f9}.Zetv_FaKZwof1Q66i0Gg{text-align:right}`, "",{"version":3,"sources":["webpack://./src/components/common/Mobile/TradeDetails.scss"],"names":[],"mappings":"AACA,yBACI,sBACI,4BAAA,CACA,WAAA,CACA,cAAA,CAAA,CAKR,yBACI,sBACI,4BAAA,CACA,UAAA,CACA,cAAA,CAAA,CAIR,sBACI,aAAA,CAEJ,sCACI,kBAAA,CACA,yBAAA,CACA,4BAAA,CAGJ,sBACI,kBAAA,CAGJ,sBACI,gBAAA","sourcesContent":["// Tablet \n@media (min-width: 768px) {\n    .drawdown-icon {\n        position: absolute !important;\n        right: 101px;\n        cursor: pointer;\n    }\n}\n\n// Mobile\n@media (max-width: 767px) {\n    .drawdown-icon {\n        position: absolute !important;\n        right: 94px;\n        cursor: pointer;\n    }\n}\n\n.drawdowns {\n    padding: 1em 0;\n}\n.drawdowns:nth-child(even) { \n    background: rgb(249, 249, 249);\n    border-top: solid 1px #ccc;\n    border-bottom: solid 1px #ccc;\n} \n\n.trade-details-container {\n    background: #f9f9f9;\n}\n\n.align-right {\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawdown-icon": `Wl6aQv96bfTjcFduRdco`,
	"drawdowns": `uCKBiFX7aUImwVHTloGQ`,
	"trade-details-container": `pkBMiBUYcrPNNClgCPeY`,
	"align-right": `Zetv_FaKZwof1Q66i0Gg`
};
export default ___CSS_LOADER_EXPORT___;
