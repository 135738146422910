import { service as io } from './streamingService';
import { User } from './userService';
import i18next from 'i18next';

import { Observer } from '../utils';
import { Service as DealService } from './dealService';

import utils from '../utils';

// realistically users only call .load and .getTrades
// Search hooks into DealService - so it knows when to search for more stuff
function SearchService() {
    // used to map the value we get back from the server to language specific values we show in the blotter
    const products = {
        'SP B': 'td.fx.blotter.label.SP',
        'SP S': 'td.fx.blotter.label.SP',
        'OU B': 'td.fx.blotter.label.OU',
        'OU S': 'td.fx.blotter.label.OU',
        'FO B': 'td.fx.blotter.label.FO',
        'FO S': 'td.fx.blotter.label.FO',
        'SN B': 'td.fx.blotter.label.SN',
        'SN S': 'td.fx.blotter.label.SN',
        'SF B': 'td.fx.blotter.label.SF',
        'SF S': 'td.fx.blotter.label.SF',
        'UF B': 'td.fx.blotter.label.UF',
        'UN S': 'td.fx.blotter.label.UN',
        'UN B': 'td.fx.blotter.label.UN',
        'UF S': 'td.fx.blotter.label.UF'
    };

    function mapProduct(input) {
        return input ? (products[input] || '') : '';
    }

    // if the deal was done by the desk, don't actually show who it was
    function maskUser(data) {
        if (User.isInternalUser()) {
            return data;
        }
        var MASKED_USER_POSTFIX = "@TDSECURITIES.COM";
        var MASKED_USER_NAME = i18next.t("fx.blotter.phoneDealMask");
        var BOOKING_STREAMER = "booking-streamer";
        var MUREX = "MUREX";

        // internal user ids or Murex will be masked
        if (data.userId && (data.userId.indexOf(MASKED_USER_POSTFIX) >= 0 || data.userId === MUREX)) {
            data.userId = data.dealtByCode = data.dealtByName = MASKED_USER_NAME;
        }

        // deals settled by internal users or Murex (booking streamer or Murex) will be masked
        if ((data.settledByCode && data.settledByCode.indexOf(MASKED_USER_POSTFIX) >= 0)
             || (data.settledByCode && data.settledByCode === BOOKING_STREAMER)
             || (data.settledByCode && data.settledByCode === MUREX)
             || ((data.amended || data.executionStatus === 'AMENDED') && data["settledByCode"] === undefined)) {
            data.settledByCode = data.settledByName = MASKED_USER_NAME;
        }

        //masks the "settled by" column for drawdowns
        if (data.drawDowns) {
            data.drawDowns.forEach(function (o) { return maskUser(o); });
        }
        return data;
    }

    function search(searchData, callback) {
        // if we disconnect before we get all the data, the handler sticks around
        // console.error('search - ', searchData);
        io.emit('search', searchData, (data) => {
            // console.log('got search results');
            processEvents(data, callback);
        }, 'search_' + searchData.type);
    };

    // FIXME - do we need to do this?
    // can we just look through our current trades?
    this.dealSearch = function (searchData, callback) {
        searchData.type =  'SEARCH_' + new Date().getTime();
        io.emit('search', searchData, (data) => {
            processEvents(data, callback);
        }, 'search_' + searchData.type);
    };

    function processEvents(res, callback) {
        const data = res.data.data;
        const more = res.data.more;
        if (data.length > 0) {
            data.forEach(function(item, index){
                if (item.dealId)  {
                    item = maskUser(item);
                    item.productDesc = i18next.t(mapProduct(item.product));
                }
            });
        }
        callback(data, more);
    }

    // load all the trades
    this.trades = [];
    this.getTrades = function() {
        return this.trades;
    };

    // trade[0] should be the newest
    function sortTrades(theTrades) {
        theTrades?.sort((a, b) => b.creationTime - a.creationTime);
    }

    function getLatestTradeModification(theTrades) {
        // we don't want to change the order of the trades
        // so walk through them and get the most recently modified
        let newestTrade = theTrades?.reduce((result, current) => (result.modificationTime > current.modificationTime ? result : current), theTrades?.[0]);
        if (!newestTrade) {
            // we know we loaded tdfx today and did a search already
            // so search from start of day
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            return today.getTime();
        } else {
            return newestTrade.modificationTime;
        }
    }

    // we don't want to call this twice, or call it when we're still working
    this.loaded = this.loading = false;
    // we're not really using this yet - but idea was that we'd short-circuit other searches until we
    // were done with the first one
    this.initialLoad = false;
    this.load = function() {
        // console.warn('search.load()');
        // in test it looks like
        if (!this.loaded) {
            // console.error('search.load() - actual');
            this.loaded = true;
            this.loading = true;
            // FIXME - use this date in the search...
            // is - 2 years enough?
            let now = new Date();
            now.setFullYear(now.getFullYear() - 2);
            let pubCount = 0;
            let pubInterval = 2;
            search({type:'everything'}, (data, more) => {
                // initial load we don't have to merge?
                this.trades.push(...data);
                // console.log('got trades');
                // this.trades = data;
                if (!more) {
                    // sort by creation date - not clear that the search returns them this way
                    sortTrades(this.trades);
                    this.initialLoad = true;
                    this.loading = false;
                    // console.error('trades loaded');
                }
                // don't always publish when we have more
                // as we have more trades, the cost of an update get's bigger
                // so we don't want straight linear updates
                if (!more ||  !(pubCount % pubInterval)) {
                    pubInterval = pubInterval * 3;
                    pubCount = 1;
                    this.publish();
                    // console.log('publishing trades...');
                } else {
                    pubCount = pubCount + 1;
                }
            });
        }
    }

    function mergeData(data, target) {
        // merge the data into target
        // remember that swaps are two trades with the same deal id, so we need to match on deal id and product code.
        for (let i = 0; i < data.length; i++) {
            // console.log('Merging deal ', i, ' of ', data.length)
            let dealIndex = target.findIndex(t => (t.dealId == data[i].dealId && t.product == data[i].product));
            if (dealIndex >= 0) {
                Object.assign(target[dealIndex], data[i]);
                // this updates the current object
                // but in order for the row to update, we need to replace it
                // we really tried hard to avoid this, but new blotter doesn't really work without it
                // ideally we wouldn't do this if the object hasn't changed
                // but that's a risky test
                target[dealIndex] = {...target[dealIndex]};
            } else {
                // new deal, add it to the list
                // but at the beginning
                target.unshift(data[i]);
            };
        }
    }

    this.searchForNewTrades = function(cb) {
        // search for all of today's trades and merge those
        // we can pass a trade start date that includes time - if we pass the creation date of the most recent deal that will get us only the 
        // newer deals - we may want to include the latest as it moves from PO => settled.  But start is inclusive, so that should be ok?
        // note that settling a deal doesn't seem to udpate last-modifed.
        // so we might need to search for all of today's deals - that's the only way we'll pick up deals that were settled.
        // trades are sorted - newest first, and the test is inclusive - ideally we'd add a ms, but json format is seconds precisions, so it's easier just to 
        // get one trade back
        if (!this.loading) {
            this.loading = true;
            // console.error('searchForNewTrades - actual');
            let tradeDate =  new Date(getLatestTradeModification(this.trades));
            search({type: 'LATEST', query: {tradeDate: tradeDate}}, (data, more) => {
                mergeData(data, this.trades);
                // we don't want to call this unless we're the last one
                if (!more) {
                    // callback could be another search, so clear the flag first
                    this.loading = false;
                    cb?.();
                    console.log('publishing search results');
                    this.publish();
                }
            });
        }
    }

    this.searchForOldTrades = function(cb) {
        // search for all trades older than our oldest in the last 2 years
        // but only if we missed them when we initially loaded
        // FIXME - search is ordered by dealId, not created or modified date - so not sure how we can avoid searching for everything...
        if (!this.initialLoad && !this.loading) {
            // this could take a while, set the loading flag
            this.loading = true;
            // console.error('searchForOldTrades - actual', this.trades.length);
            // FIXME - duplication from load
            let tradeFrom = new Date();
            // 2 years ago - but server uses -731 so do that too
            tradeFrom.setDate(tradeFrom.getDate() - 730);
            let pubCount = 0;
            let pubInterval = 2;
            let tradeTo = new Date().toJSON();
            if (this.trades.length) {
                // trade dates are strings - but yyyy-mm-dd, so string comparison works
                tradeTo = this.trades.reduce((prev, curr) =>  (curr.tradeDate < prev) ? curr.tradeDate : prev);
            }
            // we want trades from that day as well
            tradeTo = new Date(tradeTo);
            tradeTo.setDate(tradeTo.getDate() + 1);
            search({type:'everything', query: {tradeDate: tradeFrom, tradeDateTo: tradeTo}}, (data, more) => {
                mergeData(data, this.trades);
                if (!more) {
                    sortTrades(this.trades);
                    this.initialLoad = true;
                    this.loading = false;
                    // console.error('trades loaded - old');
                }
                // don't always publish when we have more
                // as we have more trades, the cost of an update get's bigger
                // so we don't want straight linear updates
                if (!more ||  !(pubCount % pubInterval)) {
                    pubInterval = pubInterval * 3;
                    pubCount = 1;
                    this.publish();
                } else {
                    pubCount = pubCount + 1;
                }
                if (!more) {
                    cb?.();
                }
            });
        } else {
            // already got them
            cb?.();
        }
    }

    this.update = function(dealId, cb) {
        // don't do an update while we're in the middle of loading
        // console.warn('search.update()');

        // if we are still loading, or haven't loaded yet don't bother
        if (this.loaded && !this.loading) {
            // console.error('search.update() - actual');

            // FIXME - do we care about this? A search on last updated should pick up our deal anyway?
            // not clear we really need to be doing 2 searches anymore?
            // if the dealId that updated is in our list already, we need to look for it - as it' won't be newer than our other deals
            if (dealId && this.trades.find((t) => t.dealId == dealId)) {
                // search for this deal
                // FIXME - we're not checking for more deals here - though it seems really unlikely
                search({type: dealId, query: {dealId: dealId }}, (data) => {
                    mergeData(data, this.trades);
                    // we want to publish in a way that let's the blotter decide not to update the whole thing
                    this.publish(dealId);
                });
            }

            // now check if other people have done deals
            this.searchForNewTrades(() => {
                // now that we've got the new trades, check if we're missing any old trades
                this.searchForOldTrades(cb);
            });
        }
    };
    
    this.updateOnSubscribe = true;
    // publish and subscribe functions    
    Observer.mixin(this);

    // object so our closure can update
    let updating = {value: false};

    DealService.subscribe(() => {
        // if we just booked or settled a deal, then search for it, and all of today's trades
        // FIXME - we don't want to search for today's trades twice if we know we're going to get a settlement
        // we will get two updates - executed, then settled 
        // this test will pass both times, but that's ok because we want to reload for both?
        // better to debounce this
        // set a timeout and if we get the settled state before it \expires, then only do one update
        // check with algom for some code maybe
        if (DealService.isTradeExecuted()) {
            // but update the flag now
            // we need to extract the values now, as the deal service will get reset
            const dealId = DealService.response?.dealId || DealService.response?.quote?.dealId;
            setTimeout(() => {
                if (!updating.value) {
                    updating.value = true;
                    // publish will be called when we're done
                    // dealID will be either new deal, FO if we're a drawdown, or deal we're settling - so basically always the 
                    // deal we want to update
                    this.update(dealId, () => updating.value = false);
                } else {
                    // console.log('skipping search.update');
                }
            }, 500);
        }
    });

    // watch the user too
    User.subscribe(() => {
        if (!User.user) {
            // we better clear the trades
            // console.log('clearing trades');
            this.trades = [];
            this.loaded = false;
            this.loading = false;
            this.initialLoad = false;
        } else {
            if (!User.connected) {
                // load will end before finishing in this situation
                this.loading = false;
            }
        }
    });

}
export const Search = new SearchService();
