import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Service as DrawdownService } from '../../../services/drawdownService';
import { Modal } from 'react-bootstrap';
import DrawDown from './DrawDown';

export default function DrawDownModal(props) {
    const { t } = useTranslation();
    const [deal, setDeal] = useState(null);

    useEffect(() => {
        let done = false;
        let sub = DrawdownService.subscribe(() => {
            if (!done) {
                setDeal(DrawdownService.current);
            }
        });
        return (() => {
            done = true;
            DrawdownService.unsubscribe(sub);
        });
    }, []);

    function close () {
        DrawdownService.setCurrent(false);
    }

    // we may need to change row height based on button?
    return (
        <Modal show={deal} centered className="td-modal td-modal-wide">
            <Modal.Header >
                <Modal.Title>
                    <h3>{t('td.fx.ticket.drawdown.title')} {deal?.clientName}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="td-modal-body-shown">
                { 
                    <DrawDown deal={deal} close={close} />
                }
            </Modal.Body>
        </Modal>
    );
}