import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Research(props) {
 
  const { t } = useTranslation();
  const {
    iframeSource = '<iframe width="100%" height="700px" scrolling="yes" src=' + t('link.external.research') + '></iframe>'
  } = props;
  return <div className="main-container" dangerouslySetInnerHTML={{__html: iframeSource}}></div>;
}