import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { useTranslation } from 'react-i18next';

import styles from './Spot.scss';

function WireDetails({ wire }) {
    const { t } = useTranslation();

    return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Popover id={`popover-positioned-right`}>
              <Popover.Body>
                    <p><strong><u>{t('td.fx.ticket.buy.account.tooltip.details')}</u></strong></p>
                    <p>
                        {t('td.fx.ticket.buy.account.tooltip.name')} {wire.name}<br/>
                        {t('td.fx.ticket.buy.account.tooltip.address')} {wire.addr1}<br/>
                        {wire.addr2}<br/>
                        {t('td.fx.ticket.buy.account.tooltip.accountno')} {wire.number}<br/>
                    </p>
                    <p><strong><u>{t('td.fx.ticket.buy.account.tooltip.bank.details')}</u></strong></p>
                    <p>
                        {t('td.fx.ticket.buy.account.tooltip.name')} {wire.bankName}<br/>
                        {t('td.fx.ticket.buy.account.tooltip.address')} {wire.bankAddr1}<br/>
                        {wire.bankAddr2}<br/>
                        {wire.bankAddr3}<br/>
                    </p>
              </Popover.Body>
            </Popover>
          }
        >
            <span className={`td-icon td-icon-help ${styles.tdgreen}`}></span>
        </OverlayTrigger>
    );
}

export default WireDetails;