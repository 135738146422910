import React, { useState, useEffect } from 'react';
import { Button, Modal, Image, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { Notification } from '../common';
import styles from './RFQModal.scss';

import { Service as DealService } from '../../services/dealService';
import { Service as DrawdownService } from '../../services/drawdownService';
import { Print } from '../../services/printService';

import spinner from '../../assets/td-emerald-loading-spinner-small.png';
import utilities from '../../utils/';

const formatAmount = utilities.format.formatAmount;

// we always render, and decide when to show/hide ourselves
// parent is listening to deal events, so we can just call the service to cancel/accept
// we may have two of these - one for drawdowns and one for everything else
function RFQModal({props}) {
    const {t} = useTranslation();
    // we keep the state because we want to render when it changes, but generally it's easier to call the DealService methods
    // to check state e.g. DealService.isWorking() etc.
    const [dealState, setDealState] = useState(DealService.state);
    const [dealCountDown, setDealCountDown] = useState(0);
    const [quote, setQuote] = useState();

    useEffect(() => {
        let done = false;
        let sub = DealService.subscribe(() => {
            if (!done) {
                // console.log('DealService update', DealService.state, DealService.response?.quoteCountdown, DealService.response?.quote);
                // update our state 
                let theQuote = DealService.response?.quote;
                if (DealService.doingDrawdown) {
                    // drawdown data is not where we expect - update quote so we can keep the display code below simple
                    // FIXME - this is sketchy
                    theQuote = {...DealService.response, ...theQuote};
                    const deal = DrawdownService.current;
                    theQuote.rate = deal?.dealtRate;
                    // FIXME - confirm this is correct?
                    theQuote.mainCurrency = deal?.isBuy ? deal?.buyCurrency : deal?.sellCurrency;
                    theQuote.secondCurrency = deal?.isBuy ? deal?.sellCurrency : deal?.buyCurrency;
                }
                // order shouldn't really matter here because all the updates should be done before the render?
                setDealState(DealService.state);
                setDealCountDown(DealService.response?.quoteCountdown);
                setQuote(theQuote);
            }
        });
        return (() => {
            done = true;
            DealService.unsubscribe(sub);
        });
    }, []);

    function defaultPopulateForPrint(data) {
        data.valueDate = data.valueDateDate;
        data.dealtRate = data.rate;

        if (data.productType == 'FWDOPT') {
            data.remainingBuyAmount = data.remainingBuyAmount ? data.remainingBuyAmount : data.buyAmount;
            data.remainingSellAmount = data.remainingSellAmount ? data.remainingSellAmount : data.sellAmount;
            data.optionStartDate = data.optionStartDateDate;
            data.endDate = data.optionEndDateDate;
        }
    }

    // FIXME - move the event handling into the button onclick definition maybe?
    function cancel(e) {
        e.preventDefault();
        DealService.rejectQuote();
    }

    function clear(e) {
        e.preventDefault();
        DealService.newTrade();
    }

    function accept(e) {
        e.preventDefault();
        DealService.bookAndSettle();
    }

    function print(e) {
        e.preventDefault();
        defaultPopulateForPrint(quote);
        Print.generateDealDetailsPDF(quote);
    }

    function cancelLabel() {
        if (DealService.canCancel()) {
            return 'td.fx.ticket.CANCLEQUOTE'
        } else {
            return 'td.fx.ticket.REJECTQUOTE';
        }
    }

    // FIXME - translation
    function getHeader() {
        let retVal = null;
        if (DealService.waitingForQuote() ) {
            retVal =  <h2>{t('td.fx.ticket.quote.status.Opened')} <Image src={spinner} style={{ width: '40px', paddingLeft: '10px' }}/></h2>;
        } else if (DealService.canBook()) {
            retVal =  <h2>{t('td.fx.ticket.modal.expires') + ' ' + dealCountDown} <Image src={spinner} style={{ width: '40px', paddingLeft: '10px' }}/></h2>;
        }
        return retVal ? (
            <Modal.Header className={styles.noflex}>
                <Modal.Title>
                    {retVal}
                </Modal.Title>
            </Modal.Header>
        ) : null;
    }

    function getNotification() {
        if (DealService.isTradeExecuted()) {
            return <Notification content={t('td.fx.ticket.modal.tradecomplete')} style="success" icon="approved" padding="0" modal={true}/>;
        } else if (!DealService.waitingForQuote() && !DealService.isTradeExecuted() && !DealService.isWorking()) {
            return <Notification content={'Trade Failed'} style="danger" icon="error" padding="0" modal={true}/>;
        }
    }

    const BuySellDetails = () => {
        if (quote?.rate && quote?.farRate) {
            return (
                <Row>
                    <Col >
                        <p className="display-5">
                        {t('td.fx.ticket.modal.buying')} {formatAmount(quote.nearBuyAmount)} {quote.buyCurrency} <br/>
                        {t('td.fx.ticket.modal.selling')} {formatAmount(quote.nearSellAmount)} {quote.sellCurrency}
                        </p>
                        <p>1 {quote.mainCurrency} = <span className="display-5">{utilities.format.formatRate(quote.rate)}</span> {quote.secondCurrency}</p>
                    </Col>
                    <Col >
                        <p className="display-5">
                        {t('td.fx.ticket.modal.selling')} {formatAmount(quote.farSellAmount)} {quote.buyCurrency} <br/>
                        {t('td.fx.ticket.modal.buying')} {formatAmount(quote.farBuyAmount)} {quote.sellCurrency} 
                        </p>
                        <p>1 {quote.mainCurrency} = <span className="display-5">{utilities.format.formatRate(quote.farRate)}</span> {quote.secondCurrency}</p>
                    </Col>
                </Row>
            )
        } else {
            return (
                <>
                    <Row className="display-6 mb-2">
                        <Col xs={12} sm={3} md={3}>
                            {t('td.fx.ticket.modal.buying')} 
                        </Col>
                        <Col xs={12} sm={6} md={4}  style={{textAlign:'center'}}>
                            {formatAmount(quote.buyAmount)} {quote.buyCurrency} 
                        </Col>
                    </Row>
                    <Row className="display-6 mb-2">
                        <Col xs={12} sm={3} md={3}>
                            {t('td.fx.ticket.modal.selling')} 
                        </Col>
                        <Col xs={12} sm={6} md={4}  style={{textAlign:'center'}}>
                            {formatAmount(quote.sellAmount)} {quote.sellCurrency}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            1 {quote.mainCurrency} = <span className="display-6">{utilities.format.formatRate(quote.rate)}</span> {quote.secondCurrency}
                        </Col>
                    </Row>
                </>);
        }
    };

    const DateDetails = () => {
        if (quote.productType == 'SWAP' || (quote?.nearValueDateDate && quote?.farValueDateDate)) {
            return (
                <Row>
                    <Col><h3 className="text-uppercase">{t('td.fx.swap.tab.near')}</h3>{t('td.fx.ticket.title.near.date')} {quote.nearValueDateDate}</Col>
                    <Col><h3 className="text-uppercase">{t('td.fx.swap.tab.far')}</h3>{t('td.fx.ticket.title.far.date')} {quote.farValueDateDate}</Col>
                </Row>
            )
        } else if (quote.productType == 'FWDOPT' || (quote?.optionStartDateDate && quote?.optionEndDateDate)) {
            return <p>{t('td.fx.ticket.title.option.start.date')} {quote.optionStartDateDate}<br/>{t('td.fx.ticket.title.option.end.date')} {quote.optionEndDateDate}</p>;
        } else {
            return <p>{t('td.fx.ticket.label.ValueDate')} {quote.valueDateDate}</p>;
        }
    }

    // FIXME - make second line a row so we can center it like we do with buy/sell ammounts
    const DealIdWithDates = () => {
        return (
            <Row className={`${styles.details} mb-2`}>
                <Col xs={12} sm={3} md={3}>
                    {t('td.fx.ticket.modal.dealId')}<br/>
                    <strong className="no-wrap">{quote.dealId}</strong>
                </Col>
                <Col xs={12} sm={3} md={3}>
                    {t('td.fx.ticket.drawdown.tradeDate')}<br/>
                    <strong>{quote.dealDate}</strong>
                </Col>

                {/* Do not show for WINDOW FWD or SWAP */}
                { quote.productType != 'SWAP' && quote.productType != 'FWDOPT' && 
                    <Col xs={12} sm={3} md={3}>
                        {t('td.fx.ticket.lblvaluedate')}<br/>
                        <strong>{quote.valueDateDate}</strong>
                    </Col>
                }
            </Row>
        );
    }

    const MidMarket = () => {
        if (quote?.farMidMarket) {
            return (<>
                <Row>
                    <Col>{t('td.fx.ticket.near.mid.market', { mainCurrency: quote.mainCurrency, midMarket: quote.midMarket, secondCurrency: quote.secondCurrency })}</Col>
                    <Col>{t('td.fx.ticket.far.mid.market', { mainCurrency: quote.mainCurrency, farMidMarket: quote.farMidMarket, secondCurrency: quote.secondCurrency })}</Col>
                </Row>
                <Row style={{ marginTop: '1.5em' }}>
                    <Col xs={12} className="text-center">
                        <a href={t('link.external.doddfrank')} target="_blank">{t('label.external.doddfrank')}</a>
                    </Col>
                </Row>
            </>);
        } else {
            return (<>
                <p>{t('td.fx.ticket.mid.market', { mainCurrency: quote.mainCurrency, midMarket: quote.midMarket, secondCurrency: quote.secondCurrency })}</p>
                <Row>
                    <Col xs={12}>
                        <a href={t('link.external.doddfrank')} target="_blank">{t('label.external.doddfrank')}</a>
                    </Col>
                </Row>
            </>);
        }
    }

    // important states that affect buttons
    // waiting - cancel 
    // quote - accept/reject 
    // done - ok (clear) and a link to receipt
    // timeout/failure/withdrawn by desk - ok (clear)
    // we don't want to show this if we're doing a drawdown unless we've created this for drawdowns
    //console.log('RFQ modal', drawdown, !DealService.isInitialState() && ((drawdown && DealService.settling) || (!drawdown && !DealService.settling)));

    // we don't want to show the modal if its a settlement
    if ((DealService.settling && !DealService.doingDrawdown) && (DealService.isTradeSettled() || DealService.isInitialState())) {
        return null;
    }

    return (
        <Modal show={!DealService.isInitialState() && ((DealService.doingDrawdown && DealService.settling) || (!DealService.doingDrawdown && !DealService.settling))} centered className="td-modal modal-lg">
            {getNotification()}
            { getHeader() }
            <Modal.Body className="td-modal-body-shown">
                {/* ----- Quote ----- */}
                { !DealService.isTradeExecuted() && quote?.rate && <>
                    <DateDetails/>
                    <BuySellDetails/>
                </>}
                {/* Do not show fwd points for SWAPs - mid market is the reg requirement, and no one has complained about forward points for swaps */}
                { !DealService.isTradeExecuted() && quote?.fwdPoints && !quote?.farRate &&
                    <p>{t('td.fx.ticket.forward.points', { fwdPoints: quote.fwdPoints})}</p>
                }
                { !DealService.isTradeExecuted() && quote?.midMarket && <>
                    <MidMarket/>
                </>}

                {/* ----- Accepted ----- */}
                { quote?.dealId && <>
                    <DateDetails/>
                    <BuySellDetails/>
                    <DealIdWithDates/>
                </>}

                {/* ----- Rejected ----- */}
                { quote?.errorCode && 
                <>
                    <p style={{marginRight:'10px'}}>{t(`td.fx.ticket.status.${dealState}.${quote?.errorCode}`)}</p>
                </>}
            </Modal.Body>
            <Modal.Footer>
                { (DealService.canCancel() || DealService.canReject()) ? 
                    <Button className="btn td-btn-secondary-clear" onClick={ cancel } >
                        { t(cancelLabel()) }
                    </Button>
                    : 
                    <>
                        { (quote?.dealId && !DealService.doingDrawdown) && 
                            <Button className="btn td-btn-secondary-clear" onClick={print}>
                                { t('td.fx.ticket.modal.viewdetails') }
                            </Button>
                        }
                            <Button className="btn td-btn-primary-light" onClick={ clear } >
                                { t('td.fx.ticket.modal.ok') }
                            </Button>
                    </>
                    
                }
                { DealService.canBook() ?
                    <Button className="btn td-btn-primary-light" onClick={ accept } >
                        { t('td.fx.ticket.BOOKDEAL') }
                    </Button>
                    : null
                }
            </Modal.Footer>
        </Modal>
    );
}

export default RFQModal;
