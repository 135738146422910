import i18next from 'i18next';
import { Format } from './formatService';
import utilities from '../utils/';
import * as pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs; //fix for pdfmake virtual fonts

/**
 * The print service is used to create a PDF of the trade details
 */
function PrintService() {

    const AUTHOR = 'TDFX';

    const executionStatusEnum = {
        ACCEPTED: 'ACCEPTED',
        AMENDED: 'AMENDED',
        CANCELED: 'CANCELED',
        TERMINATE: 'TERMINATE'
    };
    
    const statusSymbolEnum = {
        AMENDED: 'AM',
        CANCELED: 'CXL',
        OK: 'OK',
        PAYMENT_OUSTANDING: 'PO',
        TERMINATE: 'TRM'
    };
    
    function formatRate(value) {
        return utilities.format.formatRate(value);
    }
    
    // Test if we can open a new tab and put a PDF in it
    const iframeDataURITest = function () {
        let support;
        let iframe = document.createElement('iframe');

        iframe.style.display = 'none';
        iframe.setAttribute('src', 'data:text/html;base64,Kg==');
        document.body.appendChild(iframe);

        try {
            support = !!iframe.contentDocument;
        } catch (e) {
            support = false;
        }

        document.body.removeChild(iframe);

        return support;
    }

    // format and return the deal time
    const dealTime = function(data) {
        let retVal = '';

        if (data && data.dealDate && data.dealTime) {
            // deal time formatting: build from date and time provided
            const year  = data.dealDate.slice(0, 4);
            const month = data.dealDate.slice(5, 7) - 1;
            const day   = data.dealDate.slice(8, 10);
            const hr    = data.dealTime.slice(0, 2);
            const mn    = data.dealTime.slice(3, 5);
            const sec   = data.dealTime.slice(6, 8);

            let dl_t = new Date(year, month, day, hr, mn, sec);

            // FIXME
            if (true) {
            // if ($translate.use() != 'fr') {
                    //format example "10:23:16 PM ET"
                retVal = new Intl.DateTimeFormat('default', { hour: 'numeric', 
                                                              minute: 'numeric',
                                                              second: 'numeric',
                                                              hour12: true }
                                                ).format(dl_t) + ' ET';
            } else {
                //format example "22:23:16 HE"
                retVal = new Intl.DateTimeFormat('default', { hour: 'numeric', 
                                                              minute: 'numeric',
                                                              second: 'numeric',
                                                              hour12: false }
                                                ).format(dl_t) + ' HE';
            }
        }

        return retVal;
    }

    const getStatusColor = function (row) {
        let ret = 'black';

        if (row.status == statusSymbolEnum.OK) {
            ret = 'white';
        }

        return ret;
    };

    const getStatusBgColor = function (row) {
        let ret = 'white';

        if (row.status == statusSymbolEnum.OK) {
            ret = '#00b000'; //green
        } else if (row.status == statusSymbolEnum.PAYMENT_OUSTANDING) {
            ret = 'yellow';
        }

        return ret;
    };

    const getStatusLabel = function(data) {
        var executionStatus = "";
        if (data && data.executionStatus) {
            executionStatus = data.executionStatus;
        }


        if (!data.amended && (executionStatus === undefined || executionStatus === null || executionStatus === "")) {
            return '';
        } else if (executionStatus === executionStatusEnum.CANCELED) {
            return 'td.fx.ticket.label.Canceled';
        } else if (executionStatus === executionStatusEnum.TERMINATE) {
            return 'td.fx.ticket.label.Terminate';
        } else if (executionStatus === executionStatusEnum.AMENDED || data.amended) {
            return 'td.fx.ticket.label.Amended';
        } else {
            return '';
        }
    }

    // Chrome no longer supports loading a data uri
    // so we need to load a static page, and pass the data as a get parameter
    // FIXME - there may be issues here if the data becomes too large...
    const openOrDownloadDocument = function(doc, numberOfRows) {
        let canOpenDataURI = iframeDataURITest();
        const isUnderSizeLimit = numberOfRows ? numberOfRows <=500 : true; // download the PDF if the data is too large
        if (canOpenDataURI) {
            // now we have the docment, get the data uri and open a new window
            let windowName = 'TDFX_Details_' + new Date().getTime();
            let windowLocation = 'deal/details';
            let detailsWindow = window.open(windowLocation, windowName);

            try {
                doc.getDataUrl(function (t) {
                    const updateDetailsData = function () {
                        // the window needs to be loaded with the detail page (not about:blank)
                        if (detailsWindow.document.readyState === 'complete' && detailsWindow.document.baseURI && (detailsWindow.document.baseURI.indexOf(windowLocation) !== -1)) {
                            detailsWindow.document.title = doc.docDefinition.info.title;
                            detailsWindow.document.body.childNodes[0].src = t;
                        } else {
                            setTimeout(updateDetailsData, 100);
                        }
                    };
                    updateDetailsData();
                });
            } catch (n) {
                // FIXME - do something clever here
                log.error("failed to getDataUrl");
            }
        } else {
            doc.download();
        }
    };

    const getValue = function (val) {
        return val ? val : '';
    };

    function generateGenDealDetailsPDF(data) {
        var orientation = 'portrait';

        var tradeType = '';
        if (data.product) {
            if ((data.product.indexOf('SF') != -1) || (data.product.indexOf('UF') != -1)) {
                tradeType = i18next.t('td.fx.ticket.label.Far');
            }
            if ((data.product.indexOf('SN') != -1) || (data.product.indexOf('UN') != -1)) {
                tradeType = i18next.t('td.fx.ticket.label.Near');
            }
        }

        var dealTable = [
            [{
                text: i18next.t('td.fx.ticket.label.ClientCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.clientId),
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.ClientName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.clientName),
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.DealtByCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.dealtByCode),
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.DealtByName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.dealtByName),
                style: ['tableCell', 'center']
            }],
            [{
                text: tradeType + i18next.t('td.fx.ticket.label.ClientBuys'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.buyAmount) ? getValue(data.buyCurrency) + ' ' + Format.formatAmount(data.buyAmount) : getValue(data.buyCurrency) + ' 0.00',
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.SettledByCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.settledByCode),
                style: ['tableCell', 'center']
            }],  
            [{
                text: tradeType + i18next.t('td.fx.ticket.label.ClientSells'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.sellAmount) ? getValue(data.sellCurrency) + ' ' + Format.formatAmount(data.sellAmount) : getValue(data.sellCurrency) + ' 0.00' ,
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.SettledByName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.settledByName),
                style: ['tableCell', 'center']
            }], 
            [{
                text: tradeType + i18next.t('td.fx.ticket.label.Rate'),
                style: ['strong', 'tableCell']
            }, {
                text:  (data.dealtRate) ? formatRate(data.dealtRate) : '',
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [{
                text: tradeType + i18next.t('td.fx.ticket.label.ValueDate'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.valueDate)) || '',
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.DealDate'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.dealDate)) || '',
                style: ['tableCell','center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.DealTime'),
                style: ['strong', 'tableCell']
            }, {
                text: dealTime(data),
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }]
        ];

        var content = [{
            text: i18next.t('td.fx.ticket.label.ReferenceNumber') + data.dealId + " " + i18next.t(getStatusLabel(data)),
            style: ['important', 'paragraph']
        }, {
            table: {
                headerRows: 0,
                body: dealTable
            },
            style: ['paragraph']
        }];

        // if there are memos add them to separate table 
        if ((data.tradeMemo) || (data.wireMemo)) {
            var memos = [];
            var memo;
            if (data.wireMemo) {
                memo = [{
                    text: i18next.t('fx.blotter.header.wire.memo'),
                    style: ['strong', 'tableCell']
                }, {
                    text: data.wireMemo,
                    style: ['tableCell', 'center']
                }]; 
                memos.push(memo);
            }
            if (data.tradeMemo) {
                memo = [{
                        text: tradeType + i18next.t('fx.blotter.header.trade.memo'),
                        style: ['strong', 'tableCell']
                    }, {
                        text: data.tradeMemo,
                        style: ['tableCell', 'center']
                    }]; 
                memos.push(memo);
            }            
            var memoTable = [{
                table: {
                    headerRows: 1,
                    body: memos
                },
                style: ['paragraph']
            }];
            content.push(memoTable);
        }

        // accounts details
        if ((data.buyAccount) && (data.sellAccount)) {
            var accounts = [{
                text: i18next.t('td.fx.ticket.label.CreditAck'),
                style: ['paragraph']
            }, {
                text: i18next.t('td.fx.ticket.label.AccountCredit'),
                style: ['reducedSpacingParagraph']
            }, {
                table: {
                    headerRows: 0,
                    body: [
                        [{
                            text: i18next.t('td.fx.ticket.label.AccountName'),
                            style: ['strong', 'tableCell']
                        }, {
                            text: data.buyAccount,
                            style: ['tableCell', 'center']
                        }]
                    ]
                },
                style: ['reducedSpacingParagraph']
            }, {
                text: i18next.t('td.fx.ticket.label.AccountDebit'),
                style: ['reducedSpacingParagraph']
            }, {
                table: {
                    headerRows: 0,
                    body: [
                        [{
                            text: i18next.t('td.fx.ticket.label.AccountName'),
                            style: ['strong', 'tableCell']
                        }, {
                            text: data.sellAccount,
                            style: ['tableCell', 'center']
                        }]
                    ]
                },
                style: ['reducedSpacingParagraph']
            }];
            content.push(accounts);
        }

        const numberOfRows = data.length;
        openOrDownloadDocument(window.pdfMake.createPdf(getDocDefinition(i18next.t('td.fx.ticket.label.TradeDetails'), content, orientation)), numberOfRows);
    }



    this.generateDealDetailsPDF = function (data) {
        if (data.productType == 'FWD' || (data.product !== undefined && !data.product.startsWith('FO'))) {
            generateGenDealDetailsPDF(data);
        }
        if (data.productType == 'FWDOPT' || (data.product !== undefined && data.product.startsWith('FO'))) {
            generateFwdOptDealDetailsPDF(data);
        }
        if (data.productType == 'SWAP') {
            generateSwapDealDetailsPDF(data);
        }
    };

    const getDocDefinition = function (title, content, orientation) {
        let ret = {
            info: {
                // be careful, some characters in this field can mess up Chrome - '(' seems to be a problem
                title: title,
                author: AUTHOR
            },
            pageSize: 'LEGAL',
            pageOrientation: orientation,
            header: function (currentPage, pageCount) { 
                if (pageCount > 1) {
                    return {text: 'Page ' + currentPage + ' / ' + pageCount, style: ['pageNum']};
                }
            },
            footer: function (currentPage, pageCount) { 
                if (currentPage == pageCount) {
                    return {text:  i18next.t('td.fx.ticket.label.print.footer'), style: ['footer']};
                }
            },
            content: [{
                    image: 'logo',
                    fit: [40, 40],
                    style: ['paragraph']
                }, {
                    text: title,
                    style: ['paragraph', 'title']
                },
                content
            ],
            images: {
                logo: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALAAAAChCAMAAABQ48VSAAAAkFBMVEX///8AtiTp+u3A7ckAtyEguzQQvy36//sAuB1KxFo+wE4AtB/N79MAsxjr+e7K7tAswEUQuCut5bYAsgb0/fZBw1VSyWRzz36N2prc9eH1/fe26L8AtA1byWlhznQpuTif4aqG15JuzXfV8tpZzW3h9eV504VBxFEAwiiU3qGr5LSB149OxmCt5LUavTErwERq3QsVAAADNElEQVR4nO2bXXeiMBCGg+JSDQFtwA9AxVq12lX//7/berFnm0mo9Sgydt+ndxidp5xkEpJBCAAAAAAAAAAAAFxG1GqY6DLfYHjoNMqh27pMuPvkN8pT72Jhr1EgDGEIQxjCEIYwhCEMYQhDmK2wvCX3EP54KLzV31NRaK2VqlNYbeLwdrTz/XaUHk7WtQn/Di768reYlLO+UpX94zrhbnJ74Q9W26lfdZdZCgvR2m/0QwkLkczcXZmtsBC7jsuYsbDIXcachUWu7WRxZVqryfQv7/bIu0pY9nfPNrvczM5Rvne0MojDSZS5os2sTnHd1Cy1i465SR77zlYGhX8c7Sd2uHBOjWtYrakjEe6cX9ZIqbRKt3a4xT2E+w5hckddCevDeWQN4nBO/tv7CMvBtv2ZPHXPvHpqHbmMyLi7l3BsXMpeK5YKekyXUzEL4WRYIay8kEY8mE2ZCXt6SCOuzVvMTVhqmtzCwhh23IQ9vSARo6PRlp2w9RSTTHkLyzkZdsHY6MTshD25IyFz4wGPn7CiE3Q8YC68Jp04PDIXnpHpeWLM4wyFuyQTt6bMhXtLM2TywltYHkley9bMhTtmWxG8MxcmbYVYPJrwmLmw34YwhA1h79GEH27QUWH2efjhJg76yMF9an64xc/jLS+nxGjS5S1c0C3MZZ+3sKI7Keb+MkPhkoTMfd7Cg5yELIvPH7MTlr2VGTFb8975Ua8kYmQkCX7CuiQRVx7r7VZZ0FOOZ94b2pr2CDFjLSwL64yDlHowE7ZPkXLWp0iariOsHsFLWKfWcXNjJ6HnhaXSqf3qWUNnzVQ46mpFmQ/pnCxcB+sNHd2OX0YG60UZOmrgEtqDmxIWQWZSUbC3LazKhYaEv0do+7IWbg9uXVVVr3Ded+U+vsJbZ50dW+HltKKSmKVwEr/qilpRfsLBJC6nhaMksE7hVZb8I2ufhNtB8jVZ1FrGeTkepYOiuty5nrcMBqnB5nStn56j1593Bqc5+usqt1peiyCrBMclF1L653/6v3uPA8IQhjCEIQxhCEMYwhCGMIQhDOE6hd9+Ncrb5jJhEbYbJq7h9XoAAAAAAAAAAOBn8wefnqXZBf/e7gAAAABJRU5ErkJggg=='
            },
            styles: {
                header: {
                    fontSize: 10,
                    color: '#777',
                    margin: [48, 24]
                },
                footer: {
                    fontSize: 7,
                    color: '#777',
                    margin: [48, 0]
                },
                title: {
                    fontSize: 20,
                    bold: true,
                    color: '#00b000'
                },
                paragraph: {
                    margin: [0, 12]
                },
                reducedSpacingParagraph: {
                    margin: [0, 4]
                },
                tableHeader: {
                    fontSize: 10,
                    bold: true,
                    color: 'white',
                    fillColor: '#00b000',
                    alignment: 'center'
                },
                tableCell: {
                    fontSize: 10,
                    color: '#333'
                },
                tableCellDD: {
                    fontSize: 9,
                    color: '#333'
                },
                amount: {
                    bold: true
                },
                right: {
                    alignment: 'right'
                },
                center: {
                    alignment: 'center'
                },
                muted: {
                    color: '#777'
                },
                important: {
                    fontSize: 16,
                    bold: true
                },
                strong: {
                    bold: true
                },
                pageNum: {
                    fontSize: 9, 
                    color: '#777', 
                    alignment: 'center'
                }
            }
        };
        return ret;
    };

    function generateFwdOptDealDetailsPDF(data) {
        const orientation = 'portrait';
        let dealTable = [
            [{
                text:  i18next.t('td.fx.ticket.label.ClientCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.clientId),
                style: ['tableCell', 'center']
            }, {
                text:  i18next.t('td.fx.ticket.label.ClientName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.clientName),
                style: ['tableCell', 'center']
            }],
            [{
                text:  i18next.t('td.fx.ticket.label.DealtByCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.dealtByCode),
                style: ['tableCell', 'center']
            }, {
                text:  i18next.t('td.fx.ticket.label.DealtByName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.dealtByName),
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.SettledByCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.settledByCode),
                style: ['tableCell', 'center']
            }, {
                text:  i18next.t('td.fx.ticket.label.SettledByName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.settledByName),
                style: ['tableCell', 'center']
            }],
            [{
                text:  i18next.t('td.fx.ticket.label.ClientBuys'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.buyAmount) ? getValue(data.buyCurrency) + ' ' + Format.formatAmount(data.buyAmount) : '',
                style: ['tableCell', 'center']
            }, {
                text:  i18next.t('td.fx.ticket.title.option.start.date'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.optionStartDate)),
                style: ['tableCell', 'center']
            }],
            [{
                text:  i18next.t('td.fx.ticket.label.ClientSells'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.sellAmount) ? getValue(data.sellCurrency) + ' ' + Format.formatAmount(data.sellAmount) : '',
                style: ['tableCell', 'center']
            }, {
                text:  i18next.t('td.fx.ticket.title.option.end.date'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.endDate)),
                style: ['tableCell', 'center']
            }],
            [{
                text:  i18next.t('td.fx.ticket.label.Rate'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.dealtRate) ? formatRate(data.dealtRate) : '',
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [{
                text:  i18next.t('td.fx.ticket.label.DealDate'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.dealDate)),
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [{
                text:  i18next.t('td.fx.ticket.label.DealTime'),
                style: ['strong', 'tableCell']
            }, {
                text: dealTime(data),
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [
                { text:  i18next.t('td.fx.print.ticket.remainingBalance'), style: 'strong', colSpan: 4, alignment: 'center' },{text: ''},{text: ''},{text: ''}
            ],   
            [{
                text:  i18next.t('td.fx.ticket.label.ClientBuys'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.remainingBuyAmount && data.remainingBuyAmount > 0 && !isCanceledOrTerminatedDeal(data)) ? getValue(data.buyCurrency) + ' ' + Format.formatAmount(data.remainingBuyAmount) : getValue(data.buyCurrency) + ' 0.00',
                style: ['tableCell', 'center']
            }, {
                text:  i18next.t('td.fx.ticket.label.ClientSells'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.remainingSellAmount && data.remainingSellAmount > 0 && !isCanceledOrTerminatedDeal(data)) ? getValue(data.sellCurrency) + ' ' + Format.formatAmount(data.remainingSellAmount) : getValue(data.sellCurrency) + ' 0.00',
                style: ['tableCell', 'center']
            }]
        ];

        let content = [{
            text:  i18next.t('td.fx.ticket.label.ReferenceNumber') + data.dealId + " " + i18next.t(getStatusLabel(data)),
            style: ['important', 'paragraph']
        }, {
            table: {
                headerRows: 0,
                body: dealTable
            },
            style: ['reducedSpacingParagraph']
        }];

        // if there are memos add them to separate table
        if (data.tradeMemo) {
            let memos = [];
            let memo = [{
                    text:  i18next.t('fx.blotter.header.trade.memo'),
                    style: ['strong', 'tableCell']
                }, {
                    text: data.tradeMemo,
                    style: ['tableCell', 'center']
                }];
            memos.push(memo);
            let memoTable = [{
                table: {
                    headerRows: 1,
                    body: memos
                },
                style: ['paragraph']
            }];
            content.push(memoTable);
        }
        let doc = window.pdfMake.createPdf(getDocDefinition(i18next.t('td.fx.ticket.label.TradeDetails'), content, orientation));

        // add drawdown data if any
        if (data.drawDowns && data.drawDowns.length > 0) {
            content.push(genDrawDownContents(data.drawDowns));
            openOrDownloadDocument(doc);
        } else {
            const numberOfRows = data.length;
            openOrDownloadDocument(doc, numberOfRows);
        }
    }

    function generateSwapDealDetailsPDF(data) {
        var orientation = 'portrait';
        var dealTable = [
            [{
                text: i18next.t('td.fx.ticket.label.ClientCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.clientId),
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.ClientName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.clientName),
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.DealtByCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.dealtByCode),
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.DealtByName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.dealtByName),
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.SettledByCode'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.settledByCode),
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.SettledByName'),
                style: ['strong', 'tableCell']
            }, {
                text: getValue(data.settledByName),
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.Near') + i18next.t('td.fx.ticket.label.ClientBuys'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.nearBuyAmount) ? getValue(data.buyCurrency) + ' ' + Format.formatAmount(data.nearBuyAmount) : '',
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.Near') + i18next.t('td.fx.ticket.label.ValueDate'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.nearValueDateDate)) || '',
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.Near') + i18next.t('td.fx.ticket.label.ClientSells'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.nearSellAmount) ? getValue(data.sellCurrency)  + ' ' + Format.formatAmount(data.nearSellAmount) : '',
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.Near') + i18next.t('td.fx.ticket.label.Rate'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.rate) ? formatRate(data.rate) : '',
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.Far') + i18next.t('td.fx.ticket.label.ClientBuys'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.farBuyAmount) ? getValue(data.sellCurrency) + ' ' + Format.formatAmount(data.farBuyAmount) : '',
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.Far') + i18next.t('td.fx.ticket.label.ValueDate'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.farValueDateDate)) || '',
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.Far') + i18next.t('td.fx.ticket.label.ClientSells'),
                style: ['strong', 'tableCell']
            }, {
                text: (data.farSellAmount) ? getValue(data.buyCurrency) + ' ' + Format.formatAmount(data.farSellAmount) : '',
                style: ['tableCell', 'center']
            }, {
                text: i18next.t('td.fx.ticket.label.Far') + i18next.t('td.fx.ticket.label.Rate'),
                style: ['strong', 'tableCell']
            }, {
                text:  getValue(data.farRate),
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.DealDate'),
                style: ['strong', 'tableCell']
            }, {
                text: Format.displayDateFormat(getValue(data.dealDate)),
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }],
            [{
                text: i18next.t('td.fx.ticket.label.DealTime'),
                style: ['strong', 'tableCell']
            }, {
                text: dealTime(data),
                style: ['tableCell', 'center']
            }, {
                text: '',
                style: ['strong', 'tableCell']
            }, {
                text: '',
                style: ['tableCell', 'center']
            }]

        ];

        var content = [{
                text: i18next.t('td.fx.ticket.label.ReferenceNumber') + data.dealId + " " + i18next.t(getStatusLabel(data)),
                style: ['important', 'paragraph']
            }, {
                table: {
                    headerRows: 0,
                    body: dealTable
                },
                style: ['paragraph']
            }];

        // if there are memos add them to separate table
        if (data.tradeMemo || data.wireMemo || data.farTradeMemo) {
            var memos = [];
            var memo;
            if (data.wireMemo) {
                memo = [{
                    text: i18next.t('fx.blotter.header.wire.memo'),
                    style: ['strong', 'tableCell']
                }, {
                    text: data.wireMemo,
                    style: ['tableCell', 'center']
                }]; 
                memos.push(memo);
            }
            if (data.tradeMemo) {
                memo = [{
                        text: i18next.t('td.fx.ticket.label.Near') + i18next.t('fx.blotter.header.trade.memo'),
                        style: ['strong', 'tableCell']
                    }, {
                        text: data.tradeMemo,
                        style: ['tableCell', 'center']
                    }]; 
                memos.push(memo);
            }
            if (data.farTradeMemo) {
                memo = [{
                        text: i18next.t('td.fx.ticket.label.Far') + i18next.t('fx.blotter.header.trade.memo'),
                        style: ['strong', 'tableCell']
                    }, {
                        text: data.farTradeMemo,
                        style: ['tableCell', 'center']
                    }]; 
                memos.push(memo);
            }            
            var memoTable = [{
                table: {
                    headerRows: 1,
                    body: memos
                },
                style: ['paragraph']
            }];
            content.push(memoTable);
        }

        if ((data.nearBuyAccount) || (data.nearSellAccount) || (data.farBuyAccount) || (data.farSellAccount)) { 
            // account details
            var accounts = [{
                    text: i18next.t('td.fx.ticket.label.CreditAck'),
                    style: ['paragraph']
                }, {
                    text: i18next.t('td.fx.print.ticket.NearCreditAckSwap'),
                    style: ['reducedSpacingParagraph']
                }, {
                    table: {
                        headerRows: 0,
                        body: [
                            [{
                                text: i18next.t('td.fx.ticket.label.AccountName'),
                                style: ['strong', 'tableCell']
                            }, {
                                text: data.nearBuyAccount || '',
                                style: ['tableCell', 'center']
                            }]
                        ]
                    },
                    style: ['reducedSpacingParagraph']
                }, {
                    text: i18next.t('td.fx.print.ticket.NearDebitAckSwap'),
                    style: ['reducedSpacingParagraph']
                }, {
                    table: {
                        headerRows: 0,
                        body: [
                            [{
                                text: i18next.t('td.fx.ticket.label.AccountName'),
                                style: ['strong', 'tableCell']
                            }, {
                                text: getValue(data.nearSellAccount),
                                style: ['tableCell', 'center']
                            }]
                        ]
                    },
                    style: ['reducedSpacingParagraph']
                }, {
                    text: i18next.t('td.fx.print.ticket.FarCreditAckSwap'),
                    style: ['reducedSpacingParagraph']
                }, {
                    table: {
                        headerRows: 0,
                        body: [
                            [{
                                text: i18next.t('td.fx.ticket.label.AccountName'),
                                style: ['strong', 'tableCell']
                            }, {
                                text: getValue(data.farBuyAccount),
                                style: ['tableCell', 'center']
                            }]
                        ]
                    },
                    style: ['reducedSpacingParagraph']
                }, {
                    text: i18next.t('td.fx.print.ticket.FarDebitAckSwap'),
                    style: ['reducedSpacingParagraph']
                }, {
                    table: {
                        headerRows: 0,
                        body: [
                            [{
                                text: i18next.t('td.fx.ticket.label.AccountName'),
                                style: ['strong', 'tableCell']
                            }, {
                                text: getValue(data.farSellAccount),
                                style: ['tableCell', 'center']
                            }]
                        ]
                    },
                    style: ['reducedSpacingParagraph']
                }];
            content.push(accounts);
        }
        
        const numberOfRows = data.length;
        openOrDownloadDocument(window.pdfMake.createPdf(getDocDefinition(i18next.t('td.fx.ticket.label.TradeDetails'), content, orientation)), numberOfRows);
    }

    function genDrawDownContents(data) {
        var content = [];
        //sort by dealId
        data.sort(function (a,b) {
            if (a.dealId > b.dealId) {
                return 1;
            } else if (a.dealId < b.dealId) {
                return -1;
            }
            return 0;
        });
        // add table header 
        var TABLE_HEADERS = [
            i18next.t('td.fx.print.ticket.drawdown.dealId'),
            i18next.t('td.fx.print.ticket.drawdown.valueDate'),
            i18next.t('td.fx.print.ticket.drawdown.buyAmount'),
            i18next.t('td.fx.print.ticket.drawdown.sellAmount'),
            i18next.t('td.fx.print.ticket.drawdown.status'),
            i18next.t('td.fx.print.ticket.drawdown.inputBy'),
            i18next.t('td.fx.print.ticket.drawdown.inputDate'),
            i18next.t('td.fx.print.ticket.drawdown.wireMemo')
        ];

        var headerData = _.map(TABLE_HEADERS, function (it) {
            return {
                text: it,
                style: ['strong', 'tableCellDD']
            };
        });
        content.push(headerData);

        // add returned drawdowns
        data.forEach(function (row) {
            var rowData = [{
                text: getValue(row.dealId),
                style: ['tableCellDD', 'center']
            }, {
                text: Format.displayDateFormat(getValue(row.valueDate)) || '',
                style: ['tableCellDD', 'center']
            }, {
                text: (row.buyAmount) ? Format.formatAmount(row.buyAmount) : '',
                style: ['tableCellDD', 'right']
            }, {
                text: (row.sellAmount) ? Format.formatAmount(row.sellAmount) : '',
                style: ['tableCellDD', 'right']
            }, {
                text: getValue(row.status),
                style: ['tableCellDD', 'center']
            }, {
                text: getValue(row.settledByCode),
                style: ['tableCellDD', 'center']
            }, {
                text: Format.displayDateFormat(getValue(row.tradeDate)) || '',
                style: ['tableCellDD', 'center']
            },{
                text: getValue(row.wireMemo),
                style: ['tableCellDD', 'center']
            }];
            content.push(rowData);
        });

        return {
            table: {
                headerRows: 1,
                body: content
            },
            style: ['paragraph']
        };
    }

    this.generateDealListPDF_All = function (data) {
        const orientation = 'landscape';

        const getTableBody = function () {
            // header
            var TABLE_HEADERS = [
                 i18next.t('fx.blotter.header.deal.id'),
                 i18next.t('fx.blotter.header.status'),
                 i18next.t('fx.blotter.header.trade.date'),
                 i18next.t('fx.blotter.header.value.date'),
                 i18next.t('fx.blotter.header.product'),
                 i18next.t('fx.blotter.header.buy.currency'),
                 i18next.t('fx.blotter.header.amount'),
                 i18next.t('fx.blotter.header.sell.currency'),
                 i18next.t('fx.blotter.header.sell.amount'),
                 i18next.t('fx.blotter.header.dealt.rate'),
                 i18next.t('fx.blotter.header.client.name'),
                 i18next.t('fx.blotter.header.user.name'),
                 i18next.t('fx.blotter.header.buy.account'),
                 i18next.t('fx.blotter.header.sell.account')
            ];
            let ret = [];
            let headerData = TABLE_HEADERS.map(th => ({
                text: th,
                style: ['tableHeader']
            }));
            ret.push(headerData);

            // rows
            data.forEach(function (row) {
                var rowData = [{
                    text: getValue(row.dealId),
                    style: ['tableCell', 'muted']
                }, {
                    text: getValue(row.status),
                    style: ['tableCell', 'center'],
                    bold: true,
                    fillColor: getStatusBgColor(row),
                    color: getStatusColor(row)
                }, {
                    text: Format.displayDateFormat(getValue(row.tradeDate)),
                    style: ['tableCell', 'center']
                }, {
                    text: Format.displayDateFormat(getValue(row.valueDate)),
                    style: ['tableCell', 'center']
                }, {
                    text:  i18next.t(row.productDesc),
                    style: ['tableCell', 'center']
                }, {
                    text: getValue(row.buyCurrency),
                    style: ['tableCell', 'center']
                }, {
                    text: (row.buyAmount) ? Format.formatAmount(row.buyAmount) : '',
                    style: ['tableCell', 'amount', 'center']
                }, {
                    text: row.sellCurrency,
                    style: ['tableCell', 'center']
                }, {
                    text: (row.sellAmount) ? Format.formatAmount(row.sellAmount) : '',
                    style: ['tableCell', 'amount', 'center']
                }, {
                    text: (row.dealtRate) ? formatRate(row.dealtRate) : '',
                    style: ['tableCell', 'center']
                }, {
                    text: getValue(row.clientId),
                    style: ['tableCell', 'center', 'muted']
                }, {
                    text: getValue(row.userId),
                    style: ['tableCell', 'center', 'muted']
                }, {
                    text: getValue(row.buyAccount),
                    style: ['tableCell', 'center']
                }, {
                    text: getValue(row.sellAccount),
                    style: ['tableCell', 'center']
                }];
                ret.push(rowData);
            });
            return ret;
        };
        var content = [{
            table: {
                headerRows: 1,
                margin: [20, 5, 0, 10],
                body: getTableBody()
            },
            style: ['reducedSpacingParagraph']
        }];
        const numberOfRows = data.length;
        openOrDownloadDocument(window.pdfMake.createPdf(getDocDefinition(i18next.t('td.fx.ticket.label.TradeDetailsList'), content, orientation)), numberOfRows);
    }

    this.generateDealListPDF = function (data, blotterType) {
        if (blotterType === 'foBlotter') {
            console.log("A");
            this.generateDealListPDF_FW(data);
        } else {
            console.log("or B");
            this.generateDealListPDF_All(data);
        }
    };

    function isCanceledOrTerminatedDeal(data) {
        if (data.executionStatus) {
            return ['CANCELED', 'TERMINATE'].includes(data.executionStatus);
        } else {
            return false;
        }
    }
}

const Print = new PrintService();
export { Print };