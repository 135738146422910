import React, { useState, Fragment, useEffect, useCallback, useMemo } from 'react';
// import DatePicker from "react-datepicker";
import { DatePicker } from '@com-td-aacs/aacs-ui-lib';

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { isWeekday } from '../RequestForQuote/valueDateHelper';

import dayjs from 'dayjs';

function noOp(x) {
    return x;
}

// date picker language is set externally to this

// FIXME - feels like a lot of duplication with ValidatingInput consider extracting common functions to a hook?
// always restrict weekend selection
// min max should be date values
export default function DateInput({name, value, update, validate, setFieldError, errorPrefix = 'td.fx.ticket.error.', placeholder, min, max, className, clearOnError, disabled}) {
    // date comparisons are tricky because of hours/minutes
    // better to deal with that here than in the callers
    const minDate = useMemo(() => {
        let m = new Date(min);
        m.setHours(0, 0, 0, 0);
        return m;
    }, [min]);

    const maxDate = useMemo(() => {
        let m = new Date(max);
        m.setHours(23, 59, 59, 999);
        return m;
    }, [max]);

    
    const doValidate = useCallback((val) => {
        // FIXME - do we need to check if the date string is parsable?  DatePicker does that, but we may want
        // to be careful about exceptions?
        // we should never hit this
        let retVal = false;
        if (val) {
            if (val < minDate) {
                retVal = 'date.too.early';
            } else if (val > maxDate) {
                retVal = 'date.too.late';
            }
        }
        return retVal || (validate && validate(val));
    }, [minDate, maxDate, validate]);


    const { t } = useTranslation();
    const [error, setError] = useState(false);

    // controls how we format the incoming value
    // const [focus, setFocus] = useState(false);

    // validate everytime the value changes
    // this catches the case where the value is set outside the object
    useEffect(() => {
        let wasInvalid = error == 'invalid.date';
        let err = doValidate(value);
        if (error != err) {
            if (wasInvalid && !err) {
                // if we're in invalid.date state - that almost certainly 
                // means we have a valid (empty of good) value, but bad display value
                // we either need to preserve that error, or wipe out the display value
                // so do nothing
            } else {
                setError(err);
                // FIXME - does this cause a render loop?
                setFieldError(name, err);
            }
        }

    }, [value, validate, minDate, maxDate]);

    // on change we check validity
    // but we can't update our parent onChange, because we lose focus

    
    function onChange(val, context) {
        if (!context?.validationError || context?.validationError != 'invalidDate') {
            // let parseable, but invalid dates through - 
            let err = doValidate(val);
            setError(err);
            setFieldError(name, err);
            // update whether there was an error or not
            update(val);
        } else {
            // we got an invalid date
            let err = 'invalid.date';
            setError(err);
            setFieldError(name, err);
            // we can't update or the field will re-draw
            // so now we have a bad display value, but a (probably) valid
            // value in the model

            // if there's an external trigger for a re-render, validate will re-run
            // and wipe out the error - but will also not modify the display value
        }
    }


    function translateError() {
        // error is either a string, or an object
        if (error?.key) {
            return t(errorPrefix + error.key, error);
        } else {
            return error? t(errorPrefix + error) : '';
        }
    }

    /*
    const [raw, setRaw] = useState();
    function handleChangeRaw(value) {
        // save the value here, but clear it out in onChange
        // if there's no matching onChange, we know the value is bad 
        setRaw(value);
    }
    */

    /*
    // this only works because the calendar is forced open when click the field
    // otherwise we should probably use onBlur?
    function onClose() {
        // can we tell if we've got a bad input here?
        // e.g. we've had a raw change, but no actual change
        
        if (raw) {
            // if we have a value, then that's bad
            // default is to set the value back to whatever the last good value was
            // that could be confusing, but let the caller decide
            if (clearOnError) {
                onChange();
            }
        }
    }
    */


    function onError(err) {
        
        // setError('invalid');
    }

    // the span is a hack
    // override destkopModeMediaQuery because we never want the mobile date picker - it seems to be broken
    return (
        <Fragment>
            <Form.Group>
                <div className={className + ' tdfx_date_input'}>
                <DatePicker
                    a11yDesc={{calendarBtn: 'Calendar'}}
                    onChange={(date, context) => onChange(date?.$d, context)}
                    onError ={onError}

                    value={value ? dayjs(value): null}

                    id={name}
                    minDate={dayjs(minDate)}
                    maxDate={dayjs(maxDate)}
                    shouldDisableDate={(d) => (!isWeekday(d.$d))}
                    error={error? error == 'invalidDate' ? {invalidDate: true} : {text: translateError()} : false}
                    reduceAnimations
                    desktopModeMediaQuery=''
                    disabled={disabled}
                />
                </div>
            </Form.Group>
        </Fragment>
    );
}
