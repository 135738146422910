import { useState } from "react";

// hook to track field level errors for the form
// pass validate because an error in a field generally changes the error message
// form validate should be checking fieldErrors, but note that value passed to validate
// should take precidence

export function useForm(validate) {
    const [fieldErrors, setFieldErrors] = useState({});
    // passed to fields that can error
    function setFieldError(name, error) {
        fieldErrors[name] = error;
        setFieldErrors({...fieldErrors});
        // pass this because we won't have updated state yet
        validate(fieldErrors);
    }

    return {fieldErrors, setFieldError};
}