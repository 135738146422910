// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NsyzlD0Tc_RFRPPTn6xE{display:block !important}@media(min-width: 768px){.UsYMCLoR5HW_h8bgYjX5 div:not(:first-child){border-left:solid 1px #616161}}`, "",{"version":3,"sources":["webpack://./src/components/RequestForQuote/RFQModal.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CAIA,yBACI,4CACI,6BAAA,CAAA","sourcesContent":[".noflex {\n    display: block !important;\n}\n\n.details {\n    @media (min-width: 768px) {\n        div:not(:first-child){\n            border-left: solid 1px #616161;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noflex": `NsyzlD0Tc_RFRPPTn6xE`,
	"details": `UsYMCLoR5HW_h8bgYjX5`
};
export default ___CSS_LOADER_EXPORT___;
