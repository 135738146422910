import React, { Fragment, useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation  }  from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Hours as HoursService } from '../../services/hoursService';
import { Notification } from '../common';
import Header from '../Header';
import Research from '../Research';
import Welcome from '../Welcome';
import TradeSettlement from '../TradeSettlement';
import RequestForQuote from '../RequestForQuote';
import Support from '../Support';
import LegalAgreement from '../LegalAgreement';
import Reports from '../Reports';
import Footer from '../Footer';
import OnePortalModal from '../OnePortal';

import './Content.scss';

// FIXME - maybe move these to other files?
function Banners() {
    const { i18n } = useTranslation();
    const { hash } = useLocation();
    const preview = hash.includes('preview');
    const [className, setClassName] = useState(null);
    const [banner, setBanner] = useState(null);


    // depends on language/locale too, but those can't change without a reload
    useEffect(() => {
        fetch(`/tdfx/banners?locale=${i18n.language}`, { method: (preview ? 'POST': 'GET') })
            .then((res) => {
                if (res && res.ok) {
                    res.text().then((val) => {
                        var arr = val.split('-className:');
                        setBanner(arr[0]);
                        setClassName(arr[1]);
                    });
                }
            }).catch((error) => {
                // too bad
                console.log(error);
            });
    }, []);

    if (banner) {
        return <Notification banner={banner} style={className} dismiss={true} />
    }
}

function ClosureNotifications() {
    const { t, i18n } = useTranslation();
    // we don't really want to keep state, just trigger a re-render, so just use an empty object
    const [changed, setChanged] = useState({});
    useEffect(() => {
        let done = false;
        const sub = HoursService.subscribe(() => {
            if (!done) {
                setChanged({});
            }
        });
        return (() => {
            done = true;
            HoursService.unsubscribe(sub);
        });
    }, []);
    
    if (HoursService.isBranchHoliday) {
        return <Notification content={t('alerts.td.fx.branch.holiday')} style="danger" dismiss={true}/>;
    } else if (HoursService.isReducedHours) {
        return <Notification content={t('td.panel.welcome.holiday.trading.hours', { start: HoursService.branchStart, stop: HoursService.branchStop })} style="danger" dismiss={true}/>;
    } else if (!HoursService.isBranchOpen()) {
        return <Notification content={t('alerts.td.fx.trading.after.hours')} style="danger" dismiss={true}/>
    }
    return null;
}

// keep this outside of the function so it doesn't get recreated every time
const list = [
    { key: 'welcome', name: 'td.panel.label.welcome', showOnMobile: false, component: <Welcome title={'td.fx.title.welcome'}/> },
    { key: 'rfq', name: 'td.panel.label.rfq', showOnMobile: true, access: ['SETTLE', 'TRADE'], component: <RequestForQuote title={'td.fx.title.rfq'}/> },
    { key: 'reports', name: 'td.panel.label.search', showOnMobile: true, component: <Reports title={'td.fx.title.search'}/> },
    { key: 'research', name: 'td.panel.label.research', showOnMobile: false, component: <Research title={'td.fx.title.research'}/> },
    { key: 'tradeSettlement', name: 'td.panel.label.tradeSettlement', showOnMobile: true, access: ['ADMIN'] ,component: <TradeSettlement title={'td.fx.title.tradeSettlement'}/> },
    { key: 'support', name: 'td.panel.label.support', showOnMobile: true, component: <Support title={'td.fx.title.support'}/>}        
];

function getRoutes(user) {
    return (list.map(l => {
        if (!l.access) {
            return <Route key={l.key} exact path={'/' + l.key + '/*'} element={l.component}/>;
        } else {
            let match = false;
            const permissions = Object.keys(user?.permissions);
            for (let p of l.access) {
                if (permissions.includes(p)) {
                    match = l;
                    break;
                }
            }

            if (match) {
                return <Route key={match.key} exact path={'/' + match.key} element={match.component}/>;
            }
        }
    }));
}

function TheHeader() {
    return (<Header list={list}/>);
}

// ideally we'd want the padding of the banners to be conditional on how many of them there are
// e.g. we probably need a bigger gap on top of the first and after the last tha we do between multiples
export default function Content(props){
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 64em)' });

    return (
        <Fragment>
            <TheHeader/>
            <div className="main-content">
                <Banners/>
                <ClosureNotifications/>
                <LegalAgreement model={props.user}/>
                <Routes>
                    { getRoutes(props.user) }  
                    <Route key={'default'} path="/*" element={<Navigate to={isTabletOrMobile ? "/rfq" : "/welcome"} replace />} />
                </Routes>
            </div>
            <Footer isMobile={isTabletOrMobile}/>
            { props.user?.needsLegalAgreement ? 
                null :
                <OnePortalModal/>
            }
        </Fragment>
    );
}

