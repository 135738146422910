import React from 'react';
import {  useTranslation } from 'react-i18next';
import { Button, Container } from 'react-bootstrap';
import styles from './OnePortalRequired.scss';
import { OnePortal } from '../../../services/onePortalService';


export default function OnePortalRequired() {
    const { t, i18n } = useTranslation();

    const toggleShow = () => {
        OnePortal.reopenModal();
    }

    return (
        <div>
            <Container className={styles.container}>
                
                <div>
                    <h1 className={`${styles["wire-payment-title"]}`}>{t('td.fx.tradesettlement.op.unavailable')}</h1> 
                    <p>{t('td.fx.tradesettlement.op.body')}</p>
                    
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button onClick={() => toggleShow()} className="btn td-btn-primary-light float-right">{t('td.fx.tradesettlement.op.button')}</Button>
                        </div>
                    
                </div>
              
            </Container>
        </div>
    );
}