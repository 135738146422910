import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';

import styles from './Footer.scss';

export default function Footer({ login, isMobile }) {
    const { t } = useTranslation();

    function getHref(label) {
        // Get latest version of the pdf
        return t(label) + '?' + (new Date()).getTime();
    }

    return (
        <footer className="td-footer">
            <Container className={`${styles.footerContainer}`}>
                <h3 style={{ textAlign: (login || isMobile) ? null : 'center' }}><a className="td-link-action" href={t('link.external.contact')}>{t('label.footer.contact.us')}</a></h3>
                <ul className={`${styles.footerList} ${(login || isMobile ) ? '' : styles.horizontal}`}>
                    <li><a href={t('link.external.td')} target="_blank">{t('label.footer.td')}</a></li>
                    <li><a href={getHref('link.external.terms.of.use')} target="_blank">{t('label.footer.terms.of.use')}</a></li>
                    { !login && 
                        <li>{t('label.footer.phone')}</li>
                    }
                    <li>{TDFXVERSION}</li>
                </ul>
            </Container>
        </footer>
    )
}