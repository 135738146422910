import React from 'react';

import styles from './support.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export default function Reports() {
    const { t, i18n } = useTranslation();

        return (
            <Trans i18nKey="userMessagesUnread">
            <div>
                <h4>{t('td.fx.support.reports.title.reports')}</h4>
                <p>{t('td.fx.support.reports.explanation.reports01')}</p>
                
                <h5>{t('td.fx.support.reports.title.step01')}</h5>

                <Trans i18nKey={'td.fx.support.reports.explanation.step01.01'}>
                    <p><Link to={'/reports'} className="link-underline">x</Link>x</p>
                </Trans>
                
                <figure>
                <img src={t('td.fx.support.ReportsFig1')} width="90%"/>
                    <figcaption>{t('td.fx.support.figureCaption01')}</figcaption>
                </figure>
                
                <h5>{t('td.fx.support.reports.title.step02')}</h5>
                <p>{t('td.fx.support.reports.explanation.step02.01')}</p>
                
                <figure>
                    <img src={t('td.fx.support.ReportsFig2')} className={`${styles["support-img-max"]}`}/>
                    <figcaption>{t('td.fx.support.figureCaption02')}</figcaption>
                </figure>
                
                <h5>{t('td.fx.support.reports.title.step03')}</h5>
                <p>{t('td.fx.support.reports.explanation.step03.01')}</p>
                
                <figure>
                    <img src={t('td.fx.support.ReportsFig3')}/>
                    <figcaption>{t('td.fx.support.figureCaption03')}</figcaption>
                </figure>
            </div>
            </Trans>
        );
}