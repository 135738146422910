function Observer() {
    // we store liseners as object
    this.listeners = [];

    // using the function passed in when they subscribed, 
    // determine if the listener cares about the current update.
    // FIXME - not entirely clear if we're ever going to use this or ever have data
    this.publish = function (data) {
        // console.log('publishing ', this.publisherName);
        this.listeners.map(({ listener, shouldUpdate }) => {
            if (!shouldUpdate || shouldUpdate(data)) {
                return listener(data);
            } else {
                return null;
            }
        });
    };

    // shouldUpdate can be null
    this.subscribe = function (listener, shouldUpdate, updateOnSubscribe) {
        this.listeners.push({ listener, shouldUpdate });
        if (updateOnSubscribe) {
            listener(null);
        }
        return listener;
    };

    this.unsubscribe = function (listener) {
        this.listeners = this.listeners.filter((l) => {
            return l.listener !== listener;
        });
    };
}

// create the instance and functions in the target so we don't have to repeat it everywhere
Observer.mixin = function (target) {
    const observer = new Observer();
    observer.publisherName = target.constructor?.name || 'unknown';
    target.subscribe = function (listener, shouldUpdate) {
        return observer.subscribe(listener, shouldUpdate, target.updateOnSubscribe);
    };

    target.unsubscribe = function (listener) {
        return observer.unsubscribe(listener);
    };

    target.publish = function (data) {
        return observer.publish(data);
    };
};

export { Observer };     
