import React, { useState, Fragment, useEffect } from 'react';

import FilteringInput from './FilteringInput';

const MAX_LENGTH = 60;
export default function TradeMemoInput({name, value, update, setFieldError, errorPrefix, className}) {

    return (
        <FilteringInput 
            name={name} 
            value={value} 
            update={update} 
            setFieldError={setFieldError} 
            errorPrefix={errorPrefix} 
            filterAccents={true}
            className={className}
            maxLength={MAX_LENGTH}
            placeholder={'td.fx.ticket.memo.prompt'}
        />
    );
}

