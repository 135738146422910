// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JVv4siO4HSjbATQeq5mc{padding-left:110px}.JVv4siO4HSjbATQeq5mc .rt-table{max-height:1000px}.PKPkpPL1nWPQQTtONmZr{margin-top:-32px;padding-left:110px}.PKPkpPL1nWPQQTtONmZr .rt-table{max-height:1000px}`, "",{"version":3,"sources":["webpack://./src/components/common/Blotter/FullDrawDown.scss"],"names":[],"mappings":"AAAA,sBACI,kBAAA,CACA,gCACI,iBAAA,CAKR,sBACI,gBAAA,CACA,kBAAA,CACA,gCACI,iBAAA","sourcesContent":[".DrawdownBlotter {\n    padding-left: 110px;\n    :global(.rt-table) {\n        max-height: 1000px;\n    }\n\n}\n\n.DrawdownBlotterButton {\n    margin-top: -32px;\n    padding-left: 110px;\n    :global(.rt-table) {\n        max-height: 1000px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DrawdownBlotter": `JVv4siO4HSjbATQeq5mc`,
	"DrawdownBlotterButton": `PKPkpPL1nWPQQTtONmZr`
};
export default ___CSS_LOADER_EXPORT___;
