import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GridHeader.scss';


const GridHeader = ({ header, hidden }) => {
    const { t } = useTranslation();
    if (!hidden) {
        return (
            <div>
                <div className={styles.header}>
                    <span>{t(header)}</span>
                    <i className="bi bi-caret-up-fill"></i>
                    <i className="bi bi-caret-down-fill"></i>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
export default GridHeader;