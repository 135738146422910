
export function useModel(model, updateModel) {

    function handleChange(e, name) {
        // update the model
        // note that this is a pointer to the model currently in state - so the components will see the update now
        if (name) {
            model[name] = e;
        } else {
            // looks like we got an event
            model[e.target.name] = e.target.value;
        }
        // this is going to pass a new object - the components won't see that value until the next render, but it will b e
        updateModel({...model});
    }

    function handleConnectedChange(name, value, otherName, getOtherValue = () => '') {
        model[name] = value;
        // getOtherValue can deal with the '' case
        model[otherName] = getOtherValue(value, name);
        updateModel({...model});
    }

    function handleArrayChange(e, index, name) {
        //for array changes, we need to create a new array to update state instead of mutate existing array
        if (model && model.length) {
           //update or add object
            const isAnUpdate = model[index];

            if (isAnUpdate) {
                if (name) {
                    model = model.map((o, i) => i === index ? {...o, [name]: e} : o);
                } else {
                    // looks like we got an event
                    model = model.map((o, i) => i === index ? {...o, [e.target.name]: e.target.value} : o);
                }
            } else {
                model = [...model, getNewElement()];
            }

        } else {
            //add first object
            model = [getNewElement()];
        }

        function getNewElement() {
            if (name) {
                return { [name]: e };
            } else {
                // looks like we got an event
                return { [e.target.name]: e.target.value };
            }
        }

        updateModel(model);
    }

    return {handleChange, handleConnectedChange, handleArrayChange};
}

