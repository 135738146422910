import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styles from './support.scss';

// make a trans component with standard order of components?

export default function SpotTrade() {
    const { t } = useTranslation();
    return (
        <div>
            <Trans i18nKey='td.fx.support.cashManagement.title' components={
                    [<h4 />, <p /> ]
                }
            />
            {/* getting an anchor link is ridiculously complicated  a,b,c, etc. are placeholders that need to match the translation value
                and because we need to interpolate to make <a> work the <p>'s become different indexes, unlike above where they can all be <1>
            */}
            <Trans i18nKey="td.fx.support.cashManagement.step1" 
            >
                <h5>a</h5><p>b</p><p>c</p><p><a href="mailto:tdfx@tdsecurities.com">e</a> f</p>
            </Trans>

            <Trans i18nKey="td.fx.support.cashManagement.step2" components={
                    [<h5 />, <p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig1')} className={`${styles["support-img-max"]}`}/>
                <figcaption>Figure 1</figcaption>
            </figure>
           
            <Trans i18nKey="td.fx.support.cashManagement.step3" components={
                    [<h5 />, <p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig2')} />
                <figcaption>Figure 2</figcaption>
            </figure>


            <Trans i18nKey="td.fx.support.cashManagement.step4" components={
                    [<h5 />, <p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig3')} className={`${styles["support-img-max"]}`}/>
                <figcaption>Figure 3</figcaption>
            </figure>

            <Trans i18nKey="td.fx.support.cashManagement.step4a" components={
                    [<p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig4')} />
                <figcaption>Figure 4</figcaption>
            </figure>
            
            <Trans i18nKey="td.fx.support.cashManagement.step5" components={
                    [<h5 />, <p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig5')} />
                <figcaption>Figure 5</figcaption>
            </figure>
            
            <Trans i18nKey="td.fx.support.cashManagement.step6" components={
                    [<h5 />, <p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig6')} />
                <figcaption>Figure 6</figcaption>
            </figure>
            
            <Trans i18nKey="td.fx.support.cashManagement.step6a" components={
                    [<p /> ]
                }
            />
            <Trans i18nKey="td.fx.support.cashManagement.step7" components={
                    [<h5 />, <p /> ]
                }
            />
            <figure>
                <img src={t('td.fx.support.CashManagementFig7')} />
                <figcaption>Figure 7</figcaption>
            </figure>
            
            <Trans i18nKey="td.fx.support.cashManagement.step7a">
                {/* FIXME - if the number is language dependant, make it a key */}
                <p>a0</p><p><a class="link-underline" href="tel:1 877 944 5441"></a>.</p><p>e2</p>"
            </Trans>
        </div>
    )
}