import React, { useState, Fragment, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useTranslation } from 'react-i18next';
import { getMaxEndDate, getValueDateTypes, getMinDate } from './valueDateHelper';
import { addBusinessDays } from 'date-fns';
import WireDetails from './WireDetails';

import { AmountInput } from '../input';
import { ValueDateInput } from '../input';

import styles from './Spot.scss'
import { useForm } from '../../utils/useForm';
import { getCurrencies, useAccounts, hasWire } from './useAccounts';
import { useModel } from './useModel';
import TradeMemoInput from '../input/TradeMemoInput';
import WireMemoInput from '../input/WireMemoInput';
import { Hours } from '../../services/hoursService';


function Spot({ model, setModel, permissions, company, setError }) {
    const { t } = useTranslation();
    const VALUE_DATE_MAX = permissions.FWD ? false : 'SPOT+1';

    const MIN_DATE = useMemo(() => getMinDate(3));

    // this changes everytime our currencies or buy account changes
    const data = getValueDateTypes(false, VALUE_DATE_MAX, model.buyCurrency, model.sellCurrency, model.buyAccount?.wire, company);
    // not great that this is recreated on every update
    // but it doesn't seem to cause problems so...
    const tenors = data.types.map(tenor => {
        return { text: t(tenor), value: tenor };
    });

    
    // FIXME - do we actually need this for the model values?
    // FIXME - this is common across all of our tickets
    // but it doesn't feel like requestforquote is the right place for it?
    // data.selection has a value for us
    // but we only care if we've just changed a currency or a buy account, otherwise we want to leave it alone
    useEffect(() => {
        // we need to do this every time because we can't use the closure data object
        const data = getValueDateTypes(false, VALUE_DATE_MAX, model.buyCurrency, model.sellCurrency, model.buyAccount?.wire, company);
        // if we don't have a date
        if (!model.valueDateDate) {
            // our current selection (could be null) isn't in the list of available tenors
            if (!data.types.includes(model.valueDateType)) {
                model.valueDateType = data.selection;
                updateModel({ ...model });
            }

        }
    }, [company, model.buyCurrency, model.sellCurrency, model.buyAccount?.wire]);

    // deal with company/currency changes
    const [handleCurrencyChange, handleAccountChange, inverseQuoteNotAvailable] = useAccounts({ company, model, updateModel });

    const { handleChange, handleConnectedChange } = useModel(model, updateModel);
    const { fieldErrors: errors, setFieldError } = useForm(validate);

    // FIXME - consider extracting common errors?
    // validation
    function validate(fields) {
        fields = fields || errors;
        // FIXME - this test should be extracted
        if (Object.values(fields).reduce((prev, curr) => prev || !!curr, false)) {
            // there was a field error
            setError('error.fields');
        } else {
            // check for specific spot issues
            if (model.buyCurrency == model.sellCurrency) {
                setError('error.cur.buySell');
            } else if (!(model.buyAmount || model.sellAmount)) {
                setError('error.no.amount');
            } else if (permissions.SETTLE && (!model.buyAccount || !model.sellAccount)) {
                setError('lbl.selectOption');
            } else if (!model.valueDateDate && !model.valueDateType) {
                setError('error.invalid.date');
            } else {
                setError(false);
            }
        }
    }

    function updateModel(m) {
        setModel(m);
        validate();
    }

    const maxDate = useMemo(() => getMaxEndDate(), []);

    if (model.buyCurrency && model.sellCurrency) {
        return (
            <Fragment>
                <div className={'rfq-container'}>
                    <div>
                        <Row className="g-2">
                            <Col xs={4} sm={4} md={5}>
                                <Form.Group className={`${styles["dropdown-min"]}`}>
                                    <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.buys')}</Form.Label>
                                    <Form.Select
                                        className={'form-control'}
                                        id="buyCurrency" name="buyCurrency"
                                        value={model.buyCurrency}
                                        onChange={handleCurrencyChange}
                                    >
                                        {getCurrencies(company.buyAccounts).map(ccy =>
                                            <option key={ccy.value} value={ccy.value}>{ccy.text}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={8} sm={8} md={7}>
                                <Form.Group>
                                    <AmountInput
                                        minValue={1}
                                        name={'buyAmount'}
                                        value={model.buyAmount}
                                        update={(val) => handleConnectedChange('buyAmount', val, 'sellAmount')}
                                        setFieldError={setFieldError}
                                        errorPrefix={'td.validation.'}
                                        className={styles.nolabel}
                                        placeholder={''/*model.sellAmount? '' : 'td.fx.placeholder.amount.buy'*/}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row className="g-2">
                            <Col xs={4} sm={4} md={5}>
                                <Form.Group className={`${styles["dropdown-min"]}`}>
                                    <Form.Label className="no-wrap">{t('td.fx.ticket.lbl.client.sells')}</Form.Label>
                                    <Form.Select
                                        className={'form-control'}
                                        id="sellCurrency" name="sellCurrency"
                                        value={model.sellCurrency}
                                        onChange={handleCurrencyChange}
                                    >
                                        {getCurrencies(company.sellAccounts).map(ccy =>
                                            <option key={ccy.value} value={ccy.value}>{ccy.text}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={8} sm={8} md={7}>
                                <Form.Group>
                                    <AmountInput
                                        minValue={1}
                                        name={'sellAmount'}
                                        value={model.sellAmount}
                                        update={(val) => handleConnectedChange('sellAmount', val, 'buyAmount')}
                                        setFieldError={setFieldError}
                                        errorPrefix={'td.validation.'}
                                        className={styles.nolabel}
                                        placeholder={''/*model.buyAmount ? '' : 'td.fx.placeholder.amount.sell'*/}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row className="g-2">
                            <Col xs={4} sm={4} md={5}>
                                <Form.Group className={`${styles["dropdown-min"]}`}>
                                    <Form.Label className="no-wrap">{t('td.fx.ticket.label.ValueDate')}</Form.Label>
                                    <Form.Select
                                        className={'form-control'}
                                        id="valueDateType"
                                        name="valueDateType"
                                        value={model.valueDateType || ''}
                                        placeholder={''}
                                        onChange={(e) => handleConnectedChange('valueDateType', e.target.value, 'valueDateDate')}
                                    >
                                        <option></option>
                                        {tenors.map(tenor =>
                                            <option key={tenor.value} value={tenor.value}>{tenor.text}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={8} sm={8} md={7}>
                                <Form.Group>
                                    <ValueDateInput
                                        className={styles.nolabel}
                                        min={MIN_DATE}
                                        max={maxDate}
                                        disabled={!permissions.FWD}
                                        name={'valueDateDate'}
                                        cur1={model.buyCurrency}
                                        cur2={model.sellCurrency}
                                        value={model.valueDateDate || ''}
                                        update={(date) => handleConnectedChange('valueDateDate', date, 'valueDateType')}
                                        setFieldError={setFieldError}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    {permissions.SETTLE && <>
                    <div>
                        <Form.Group>
                            <Form.Label>
                                {t('td.fx.ticket.lbl.to')}
                                {model.buyAccount?.wire &&
                                    <WireDetails wire={model.buyAccount.wire} />
                                }
                            </Form.Label>

                            <Form.Select
                                className={'form-control'}
                                id="buyAccount"
                                name="buyAccount"
                                value={model.buyAccount?.ssid || ''}
                                placeholder={t('td.fx.ticket.lbl.selectOption')}
                                onChange={(e) => handleAccountChange('buyAccount', e.target.value, company.buyAccounts)}
                            >
                                {model.buyAccount ? null : <option></option>}
                                {company.buyAccounts.filter(a => a.ccy == model.buyCurrency).map(a =>
                                    <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>{t('td.fx.ticket.lbl.from')}</Form.Label>
                            <Form.Select
                                className={'form-control'}
                                id="sellAccount"
                                name="sellAccount"
                                value={model.sellAccount?.ssid || ''}
                                placeholder={t('td.fx.ticket.lbl.selectOption')}
                                onChange={(e) => handleAccountChange('sellAccount', e.target.value, company.sellAccounts)}
                            >
                                {model.sellAccount ? null : <option></option>}
                                {company.sellAccounts.filter(a => a.ccy == model.sellCurrency).map(a =>
                                    <option key={a.ssid} value={a.ssid}>{a.displayName}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>{t('td.fx.ticket.lblMemo')}</Form.Label>
                            <TradeMemoInput
                                name={'tradeMemo'}
                                value={model.tradeMemo || ''}
                                placeholder={t('td.fx.ticket.memo.prompt')}
                                update={(val) => handleChange(val, 'tradeMemo')}
                                setFieldError={setFieldError}
                                errorPrefix={'td.validation.'}
                            />
                        </Form.Group>
                    </div>
                    </>
                    }
                    {(model.buyAccount?.wire || (!permissions.SETTLE && hasWire(company.buyAccounts, model.buyCurrency))) && <div>
                        <Form.Group>
                            <Form.Label>{t('td.fx.ticket.lblWirePaymentDetails')}</Form.Label>
                            <WireMemoInput
                                name={'wireMemo'}
                                value={model.wireMemo || ''}
                                update={(val) => handleChange(val, 'wireMemo')}
                                setFieldError={setFieldError}
                                errorPrefix={'td.validation.'}
                            />
                        </Form.Group>
                    </div>}
                </div>
                <Row>
                    <Col>
                        <Form.Group className="col col-sm-12" style={{ margin: '1em 0' }}>
                            <Form.Check
                                id="inverseQuote"
                                name="inverseQuote"
                                checked={!!model.inverseQuote}
                                onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                label={t('td.fx.ticket.chbxInverseQuote')}
                                disabled={inverseQuoteNotAvailable}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Fragment>
        );
    } else {
        return null;
    }
}

export default Spot;