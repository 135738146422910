import React from 'react';
import { useTranslation } from 'react-i18next';


import Modal from 'react-bootstrap/Modal';


function ConnectionWarning({connected}) {
    const { t } = useTranslation();

    return (
        <Modal show={!(connected)} centered className="td-modal" >
            <Modal.Header >
                <Modal.Title>
                    {t('td.fx.error.connection.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="td-modal-body-shown">
                <p> {t('td.fx.error.connection')}</p>
            </Modal.Body>
        </Modal>
    );
}

export { ConnectionWarning };
