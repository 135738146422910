// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rt-thead.-header{box-shadow:none !important;font-weight:bold}.sLXELfPHtD1jtmrZHSfC{position:relative}.bi-caret-up-fill,.bi-caret-down-fill{position:absolute;font-size:8px;right:0}.bi-caret-down-fill{top:6px}.-sort-asc .bi-caret-down-fill{display:none}.-sort-desc .bi-caret-up-fill{display:none}.sort-none * .bi{display:none}.-cursor-pointer{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/common/Blotter/GridHeader.scss"],"names":[],"mappings":"AAAA,kBACI,0BAAA,CACA,gBAAA,CAGJ,sBACI,iBAAA,CAGJ,sCACI,iBAAA,CACA,aAAA,CACA,OAAA,CAGJ,oBACI,OAAA,CAIA,+BACI,YAAA,CAKJ,8BACI,YAAA,CAIR,iBACI,YAAA,CAGJ,iBACI,cAAA","sourcesContent":[":global .rt-thead.-header {\n    box-shadow: none !important;\n    font-weight: bold;\n}\n\n:local .header {\n    position: relative;\n}\n\n:global .bi-caret-up-fill, :global .bi-caret-down-fill{\n    position: absolute;\n    font-size: 8px;\n    right: 0;\n}\n\n:global .bi-caret-down-fill {\n    top: 6px;\n}\n\n:global .-sort-asc {\n    .bi-caret-down-fill {\n        display: none;\n    }\n}\n\n:global .-sort-desc  {\n    .bi-caret-up-fill {\n        display: none;\n    }\n}\n\n:global .sort-none * .bi {\n    display: none;\n}\n\n:global .-cursor-pointer {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `sLXELfPHtD1jtmrZHSfC`
};
export default ___CSS_LOADER_EXPORT___;
