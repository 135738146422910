import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

import styles from './LoginForm.scss';
import TDLogo from '../common/TDLogo';
import { User } from '../../services/userService';

export default function LoginError() {
    const location = useLocation();
    const { t } = useTranslation();
    const [errorCode, setErrorCode] = useState();

    let internal = localStorage.getItem('internal');
    console.log('internal - ', internal);

    useEffect(() => {
        setErrorCode(location.state?.errorCode);
    }, []);

    useEffect(() => {
        // if we got here, we should logout and force our socket to disconnect
        // op logout should have already happened
        User.logout();
    });

    return (
        <Row className={`${styles.fullHeight} + g-0`}>
            <Col className={`${styles.loginBackground}`}>
                <Container className={`${styles["identity-verification-content"]} ${styles["identity-verification-container"]}`}>
                    <TDLogo/>
                    {/* Title heading */}
                    <Row className={`${styles["identity-verification-center"]}`} style={{ marginTop: '5em' }}>
                        <h1>{t(`tdfx.user.error.${errorCode}.title`)}</h1>
                        <p className="display-6">{t(`tdfx.user.error.${errorCode}.message`)}</p>
                    </Row>
                    
                    <Row className={`${styles["identity-verification-center"]}`}>
                        <Col>
                            <Button as="a" href={internal? '/tdfx/login/internal' : '/tdfx/login'} className={`btn td-btn-primary-light`}>{t('td.fx.signing.continue')}</Button>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}