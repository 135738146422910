import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import styles from './Welcome.scss';
import CardLink from './CardLink';

import WelcomeImg from '../../assets/welcome.jpg';

export default function Welcome(props) {
    const { t, i18n } = useTranslation();

    function getHref(label) {
        // Get latest version of the pdf
        return t(label) + '?' + (new Date()).getTime();
    }

    const CA_PRODUCTS_AND_SERVICES = [{
        heading: t('td.fx.welcome.lbl.spot.and.fwd.contracts'), blurb: t('td.fx.welcome.lbl.spot.and.fwd.contracts.blurb'), href: getHref('td.fx.spot.and.fwd.contracts.link')}, {
        heading: t('td.fx.welcome.lbl.fx.swap'), blurb: t('td.fx.welcome.lbl.fx.swap.blurb'), href: getHref('td.fx.swap.link')}, {
        heading: t('td.fx.welcome.lbl.creating.forex.policy'), blurb: t('td.fx.welcome.lbl.creating.forex.policy.blurb'), href: getHref('td.fx.creating.forex.policy.link')}, {
        heading: t('td.fx.welcome.lbl.sample.forex.policy'), blurb: t('td.fx.welcome.lbl.sample.forex.policy.blurb'), href: getHref('td.fx.sample.forex.policy.link')}, {
        heading: t('td.fx.welcome.lbl.direct.trade.forex.services'), blurb: t('td.fx.welcome.lbl.direct.trade.forex.services.blurb'), href: getHref('td.fx.direct.trade.forex.services.link')}, {
        heading: t('td.fx.welcome.lbl.dual.ccy.deposit'), blurb: t('td.fx.welcome.lbl.dual.ccy.deposit.blurb'), href: getHref('td.fx.dual.ccy.deposit.link')
    }];

    const FR_PRODUCTS_AND_SERVICES = [{
        heading: t('td.fx.welcome.lbl.spot.and.fwd.contracts'), blurb: t('td.fx.welcome.lbl.spot.and.fwd.contracts.blurb'), href: getHref('td.fx.spot.and.fwd.contracts.link')}, {
        heading: t('td.fx.welcome.lbl.fx.swap'), blurb: t('td.fx.welcome.lbl.fx.swap.blurb'), href: getHref('td.fx.swap.link')}, {
        heading: t('td.fx.welcome.lbl.direct.trade.forex.services'), blurb: t('td.fx.welcome.lbl.direct.trade.forex.services.blurb'), href: getHref('td.fx.direct.trade.forex.services.link')}, {
        heading: t('td.fx.welcome.lbl.dual.ccy.deposit'), blurb: t('td.fx.welcome.lbl.dual.ccy.deposit.blurb'), href: getHref('td.fx.dual.ccy.deposit.link')
    }];

    const US_PRODUCTS_AND_SERVICES = [{
        heading: t('td.fx.welcome.lbl.forward.contracts'), blurb: t('td.fx.welcome.lbl.forward.contracts.blurb'), href: getHref('td.fx.welcome.lbl.forward.contracts.link')}, {
        heading: t('td.fx.welcome.lbl.eight.steps.strategy'), blurb: t('td.fx.welcome.lbl.eight.steps.strategy.blurb'), href: getHref('td.fx.welcome.lbl.eight.steps.strategy.link')
    }];

    const WIRE_PAYMENTS = [{
        heading: t('td.fx.welcome.lbl.wire.fees'), href: getHref('td.fx.incoming.wire.fees.link'), cardImg: "WirePaymentChange"}, {
        heading: t('td.fx.welcome.lbl.incoming.ccy'), href: getHref('td.fx.incoming.ccy.link'), US: true, cardImg: "Vanderblit" }, {
        heading: t('td.fx.welcome.lbl.incoming.foreign.ccy'), href: getHref('td.fx.incoming.foreign.ccy.link'), US: true, cardImg: "ForeignCurrencyPaymentsImg" }, {
        heading: t('td.fx.welcome.lbl.incoming.wires'), href: getHref('td.fx.incoming.wire.link'), cardImg: "WiresImg"}, {
        heading: t('td.fx.welcome.lbl.incoming.foreign.ccy'), href: getHref('td.fx.incoming.foreign.ccy.link'), cardImg: "ForeignCurrencyPaymentsImg"
    }];

    const GENERAL_LINKS = [{
        label: t('td.fx.welcome.lbl.common.messages'), link: 'support/errors' }, {
        label: t('td.fx.welcome.lbl.list.tradable.currencies'), link: 'support/currencies' }, {
        label: t('td.fx.welcome.lbl.chinese.renminbi'), link: getHref('td.fx.chinese.renminbi.link'), newTab: true }, {
        label: t('td.fx.welcome.lbl.inverse.quotes'), link: 'support/inverse' 
    }];

    const products = i18n.language == 'fr' ? FR_PRODUCTS_AND_SERVICES : (i18n.language == 'en_US'? US_PRODUCTS_AND_SERVICES : CA_PRODUCTS_AND_SERVICES);
    function getCard(item, i) {
        return item && (
            <Col md={4} key={i}>
                <CardLink heading={item.heading} href={item.href} blurb={item.blurb} cardImg={item.cardImg}/>
            </Col>
        );
    }

    return (
        <div>
            {/* Welcome Video section*/}
            <Row className="align-items-center">
                <Col>
                    <div className="promo-video">
                        <a target="_blank" href={getHref('td.fx.welcome.video.link')}>
                            <img src={WelcomeImg}/>
                        </a>
                    </div>
                </Col>
                <Col>
                    <h1>{t('td.fx.welcome.tradingPlatform')}</h1>
                </Col>
            </Row>
            <hr className="td-thin-divider-line-1"/>
            {/* Contact Us */}
            <Row className="align-items-center general-info" style={{ padding: '2em' }}>
                <div className="text-center">
                    <h3>{t('td.fx.welcome.lbl.contactus')}</h3>
                    <p>{t('td.fx.welcome.lbl.tdfx.client.services')} <a href={'tel:' + t('td.fx.welcome.lbl.tdfx.client.services.phone')}>{t('td.fx.welcome.lbl.tdfx.client.services.phone')}</a></p>
                </div>
                <div className="text-center">
                    <h3>{t('td.fx.welcome.lbl.partners')}</h3>
                    <p>{t('td.fx.welcome.lbl.web.business.banking')} <a href={'tel:' + t('td.fx.welcome.lbl.web.business.banking.phone')}>{t('td.fx.welcome.lbl.web.business.banking.phone')}</a> | {t('td.fx.welcome.lbl.easyline')} <a href={'tel:' + t('td.fx.welcome.lbl.easyline.phone')}>{t('td.fx.welcome.lbl.easyline.phone')}</a> | {t('td.fx.welcome.lbl.td.direct.investing')} <a href={'tel:' + t('td.fx.welcome.lbl.td.direct.investing.phone')}>{t('td.fx.welcome.lbl.td.direct.investing.phone')}</a></p>
                </div>
            </Row>
            {/* Products and services section */}
            <div className="main-container">
                <h2 className="text-center" style={{ paddingTop: '2em' }}>{t('td.fx.welcome.lbl.products.and.services')}</h2>
                <Row className={`${styles["inner-container"]}`}>
                {
                    products.map((item, i) => {
                        return getCard(item, i);
                    })
                }
                </Row>
                <hr className="td-thin-divider-line-1"/>
                {/* Wire Payments */}
                <h2 className="text-center" style={{ paddingTop: '2em' }}>{t('td.fx.welcome.lbl.wire.payments')}</h2>
                <Row className={`${styles["inner-container"]}`}>
                { i18n?.language == 'en_US' && 
                    WIRE_PAYMENTS.filter(item => item.US).map((item, i) => {
                        return getCard(item, i);
                    })
                }
                { i18n?.language != 'en_US' && 
                    WIRE_PAYMENTS.filter(item => !item.US).map((item, i) => {
                        return getCard(item, i);
                    })
                }
                </Row>
            </div>
            <Row className="align-items-center general-info">
                <Col className={`${styles.generalBackground}`}>
                </Col>
                <Col className="p-5"> {/* General Information section*/}
                    <h2>{t('td.fx.welcome.lbl.help.and.Information')}</h2>
                    <div>
                    { GENERAL_LINKS.map((item, i) => 
                        item.newTab ? <p key={i}><a href={item.link} target="_blank">{item.label}</a></p> : <p key={i}><a href={item.link}>{item.label}</a></p>
                    )}
                    </div>
                </Col>
            </Row>
        </div>
    )

}
