import React, { Fragment, useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate }  from 'react-router-dom';
import { useTranslation, getI18n } from 'react-i18next';

import { User } from '../services/userService';
import { KeepAlive } from '../services/keepAliveService';
import { Search } from '../services/searchService';
import { TimeoutWarning, LogoutError, EvictionWarning } from './Timeout';
import Spinner from 'react-bootstrap/Spinner';

import Content from "./Content";
import Login from "./Login";
import ForgotPassword from "./Login/ForgotPassword";
import LoginError from "./Login/LoginError";
import ResetPassword from './Login/ResetPassword';

// FIXME - this has got to go if we're picking up the emeralds widgets
// and for the date picker too
// import { registerLocale, setDefaultLocale } from "react-datepicker";
import { frCA, enCA } from 'date-fns/locale';
import { ConnectionWarning } from './Timeout/ConnectionWarning';

// registerLocale('en', enCA);
// registerLocale('fr', frCA);
// FIXME
// setDefaultLocale('en');

// FIXME - maybe move this into i18n.js?
// return true if we found a locale value
function testAndSetLanguage(val) {
    let value = val?.toLowerCase();
    let retVal = value?.indexOf('locale') >= 0;
    if (value?.indexOf('locale=en_us') >= 0) {
        setLanguage('en_US');
    } else if (value?.indexOf('locale=fr') >= 0) {
        setLanguage('fr');
    } else if (value?.indexOf('locale=') >= 0) {
        // note difference between passing en in url vs en as default - we look in local storage
        setLanguage('en_CA');
    } else {
        setLanguage();
    }
    return retVal;
}

function setLanguage(lang) {
    /*
    if (lang == 'fr') {
        setDefaultLocale('fr');
    } else {
        setDefaultLocale('en_CA');
    }
    */
    getI18n().changeLanguage(lang || localStorage.getItem('locale') || 'en_CA');
}

export default function App() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // our local copy of the user object
    // but we want to capture 3 states here
    // - not logged in
    // - logged in
    // - we don't know - e.g. when we first hit the page, potentially becasue of a refresh
    const [user, setUser] = useState(User.user);
    const [connected, setConnected] = useState(User.connected);
    const [evicted, setEvicted] = useState(User.evict);
    const [loading, setLoading] = useState(true);
    const [logoutError, setLogoutError] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
          setReady(true);
        };
        // Check if the page has already loaded
        if (window.document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    useEffect(() => {
        User.subscribe(() => {
            // do we really want every publish to cause everything to update?
            setUser(User.user? {...User.user}: User.user);
            if (!User.user) {
                setLoading(false);
            }
            setConnected(User.connected);
            setEvicted(User.evict);
            // don't care about connection status here, just whether the user exists
            // we're at the top level, so this component never goes away
        });
    }, []);

    useEffect(() => {
        // assumption here is that user reference doesn't change and we don't get called a lot...
        if (user) {
            KeepAlive.touch((err) => {
                if (err) {
                    // this should never happen - we just checked we were ok...
                    User.logout(done);
                } else {
                    // trigger our initial search
                    Search.load();
                    // start our timer
                    KeepAlive.watchTimeout(timeoutWarning, timeoutExpired);
                    // and subscribe to keep alive events - in case we're no longer alive  (e.g. we cleared the session on the server)
                    KeepAlive.subscribe(sessionExpired);
                }
            });
        } else {
            // stop waiting
            KeepAlive.stop();
        }
    }, [user]);
    // callback for user and keep alive
    // called when keep alive discovers that the user session is dead
    // or when we get a message to end because of a second login
    function sessionExpired(data) {
        if (user && data.logout) {
            // how many different errors can their be? - DUPLICATE and EXPIRED
            // FIXME - create some constants
            // FIXME - this may not be the best error message to use
            setSessionTimeout(true);
            setLogoutError((data.reason == 'DUPLICATE') ? 'tdfx.user.error.another.signon.message' : 'tdfx.user.error.not.authorized.message');
            // can't just set it locally, need to set it in User and publish
            User.cleanUpUser();
        }
    }

    // are we at risk of timing out?
    // tied to keepAlive
    const [sessionTimeoutWarning, setSessionTimeoutWarning] = useState(false);
    function timeoutWarning() {
        setSessionTimeoutWarning(true);
    }

    // did the session timeout
    const [sessionTimeout, setSessionTimeout] = useState(false);


    // FIXME - user.logout should publish the change
    // in user state - we shouln't have to set it ourselves here
    function timeoutExpired() {
        // kick them out
        User.logout(false, true);
        setSessionTimeoutWarning(false);
        setSessionTimeout(true);
        // clear the user
        setUser(false);
    }

    function closeTimeoutWarning() {
        setSessionTimeoutWarning(false);
        KeepAlive.touch();
    }

    function closeTimeoutError() {
        setSessionTimeoutWarning(false);
        setSessionTimeout(false);
        setLogoutError(false);
        // replace doesn't really do anything - thought was it would force a reload of the page, but it doesn't
        navigate('/login', {replace:true});
        navigate(0);
    }

    function closeEvictionWarning() {
        User.evict = false;
        setEvicted(false);
    }

    // called from login, success or failure
    // problem is we stoped calling this in a few situations because it was making things break
    const done = (errorCode) => {
        setLoading(false);
        // if we are internal, then we might have got an error code after the redirect if we aren't setup
        // is doing something here going to break everything?
        if (errorCode) {
            navigate('/login/error', { state: { errorCode: errorCode } });
        }
    }; 

    // see if we're logged in, and if we are setup keep alive timer
    // only call once
    // this get's called at page load/refresh
    // we don't want to kick users out just because they reload the page,
    // but we need to double check that they're not logged/expired
    useEffect(() => {
        document.title = 'TDFX';
        KeepAlive.touch((err) => {
            if (err) {
                done();
            } else {
                // no error, we must have a user
                User.getUser(done);
            }
        });
    }, []);

    // trigger keep alive as we navigate around
    useEffect(() => {
        // if the new location is login, then don't do this
        // sometime we get /tdfx/login, sometimes we get /login, and sometimes we get */login/other stuff
        if (location.pathname.indexOf('/login') == -1) {
            KeepAlive.touch();
            // we also want to trigger a search update
            // FIXME - is this too often?
            Search.update();
            // update the title
            // originally we were looking for last index of /, but makes more sense to look for tdfx/
            // that way this will work for support/login etc.
            // but sometimes pathname isn't the full path?
            let start = location.pathname.indexOf('/') + 1;
            let suffix = location.pathname.slice(start)?.replace('\/', '.');
            let key = 'td.fx.title.' + suffix;
            document.title = t(key);
        } else {
            document.title = 'TDFX';
            // if we're on the login page, then make sure our language is ok
            // could be in the hash - because angular/history - or just a regular param
            // FIXME - do we want to do this every time?
            // params take priority
            testAndSetLanguage(location.search) || testAndSetLanguage(location.hash);
        }
    }, [location]);

    // short circuit if the page isn't loaded - styles might not be there
    if (!ready) {
        return null;
    }

    if (user) {
        // we are logged in, so care about our connection and timeout warnings
        return (
            <Fragment>
                <Content user={user}/>
                <TimeoutWarning sessionTimeoutWarning={sessionTimeoutWarning} closeTimeoutWarning={closeTimeoutWarning} />
                <ConnectionWarning connected={connected} />
            </Fragment>
        );
    } else {
        // we might still be loading
        // fixme - find bootstrap equivlant of dimmer/spinner
        if (loading) {
            return ( 
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{ t('td.fx.public.loading') }</span>
                </Spinner>
                );
        } else {
            // we are not logged in 
            // we care only about why not. e.g.:
            // - timed out
            // - evicted for duplicate login
            // - session died because keep alive failed
            // note that for the last one we need to distinguish between this and a user using a bookmarked protected page

            // FIXME - logout error might need some more thought
            // might be some duplication with eviction warning
            // should probalby make this a little more nested?  
            // e.g. we can't refresh the page on most of these urls and have them work

            return (
                <Fragment>
                    <Routes>
                        <Route path="/login/internal" element={<Login done={done} internal={true}/>}/>
                        <Route path="/login/password" element={<ForgotPassword/>}/>
                        <Route path="/login/error" element={<LoginError/>}/>
                        <Route path="/login/resetPassword" element={<ResetPassword done={done}/>}/>
                        <Route path="/login/*" element={<Login done={done} internal={false}/>}/>
                        <Route path="/*" element={<Navigate to="/login" replace />} />
                    </Routes>
                    <LogoutError sessionTimeout={sessionTimeout} logoutError={logoutError} closeTimeoutError={closeTimeoutError} noRolesError={false}/>
                    <EvictionWarning evicted={evicted} closeWarning={closeEvictionWarning} />
                </Fragment>
            );
        }
    }

}
